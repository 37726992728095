import React from "react";
import styled, { css } from "styled-components";
import { typography, colors } from "../../../const";
import Image from "../../../components/Image";
import { Grid, Row, Column } from "../../../components/Grid";
import { EntryTitle } from "../../../elements";
import { Link as A } from "react-router-dom";
import moment from "moment";
import { checkIfIE } from "../../../const/helpers";

const findPostsToOutput = (metaNodes) => {
  let postsArray = [];
  metaNodes.forEach(({ node }) => {
    const { name, articles, newsArticles } = node;
    if (articles?.nodes.length > 0) postsArray.push(...articles?.nodes);
    if (newsArticles?.nodes.length > 0) postsArray.push(...newsArticles?.nodes);

    if (process.env.NODE_ENV === "development") {
      console.log(
        `posts related to tag: '${name}'`,
        articles?.nodes,
        newsArticles?.nodes
      );
    }
  });
  return postsArray;
};

const ReadMore = React.memo(({ data, currentTitle }) => {
  if (!data || data?.length === 0) return null;
  const postsToRender = findPostsToOutput(data);
  const isIE = checkIfIE();
  return (
    <Grid
      tabletSpaceBetween
      colSpan={4}
      setGutterWidth={50}
      tabletColSpan={4}
      setTabletGutterWidth={36}
    >
      <SectionTitleRow>
        <Column>
          <p>Les mer</p>
        </Column>
      </SectionTitleRow>
      <Items isIE={isIE}>
        <Row>
          {postsToRender
            ?.filter((post) => post.title !== currentTitle)
            .slice(0, 3)
            .map((data, i) => {
              const { title, link, __typename } = data;
              const { image, metaInfo } = data?.fields;
              const type =
                __typename === "NewsArticle" ? "Nyheter" : "Artikkel";
              return (
                <Column key={i} className="item">
                  <Block
                    className={image ? "" : "blue-background"}
                    to={link}
                    isIE={isIE}
                  >
                    {isIE && image?.mediaItemUrl && (
                      <div className="image-wrap">
                        <Image alt={image?.altText} src={image?.mediaItemUrl} />
                      </div>
                    )}
                    {!isIE && image?.mediaItemUrl && (
                      <Image alt={image?.altText} src={image?.mediaItemUrl} />
                    )}
                    <div className="text-block">
                      <p className="type">{type}</p>
                      <EntryTitle className={`archive-title ${__typename}`}>
                        {title}
                      </EntryTitle>
                      {metaInfo?.publicationDate && (
                        <p className="publication-date">
                          Publisert
                          <span className="bold">
                            {moment(
                              metaInfo.publicationDate,
                              "D/M/YYYY hh:mm a"
                            ).format("D/M/YYYY")}
                          </span>
                        </p>
                      )}
                    </div>
                  </Block>
                </Column>
              );
            })}
        </Row>
      </Items>
    </Grid>
  );
});

export default ReadMore;

const SectionTitleRow = styled(Row)`
  margin-top: 161px;
  ${Column} {
    width: 100%;
    margin-bottom: 38px;
    p {
      width: 100%;
      padding-bottom: 14px;
      border-bottom: solid 1px ${colors.grey};
      font-family: ${typography.mono};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.024em;
    }
  }
  @media (max-width: 768px) {
    margin-top: 35px;
    ${Column} {
      p {
        padding-bottom: 7px;
      }
    }
  }
`;
const Items = styled.div`
  ${(p) =>
    p.isIE &&
    css`
      .item {
        display: block;
        height: 100%;
      }
    `}
`;
const Block = styled(A)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 78px;
  text-decoration: none;
  flex: 1;
  &.blue-background {
    background-color: ${colors.secondary};
    width: unset;
    padding: 55px 36px 61px 36px;
    @media (max-width: 768px) {
      padding-top: 33px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  img {
    width: 100%;
    height: 254px;
    object-fit: cover;
  }
  .text-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 22px;
    flex: 1;
    .type {
      font-family: ${typography.mono};
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    p.publication-date {
      display: flex;
      flex: 1;
      align-items: flex-end;
      font-family: ${typography.mono};
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      margin-top: 29px;
      span.bold {
        margin-left: 0.5em;
        font-family: ${typography.monoBold};
      }
    }
  }

  ${(p) =>
    p.isIE &&
    css`
      display: block;
      > * {
        display: block;
      }
      .image-wrap {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 254px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: auto;
        }
      }
      .text-block {
        > * {
          margin: 0;
        }
      }
    `}
`;
