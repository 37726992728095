import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { typography, colors } from "../const";
import Image from "./Image";
import Arrow from "../assets/icons/Arrow";
import Cross from "../assets/icons/Cross";
import { dispatch } from "../redux";
import { parseTitle, checkIfIE } from "../const/helpers";
import { useSelector } from "react-redux";
import useKey from "../hooks/useKey";
import useOnClickOutside from "../hooks/useOnClickOutside";

const overlayClose = () => {
  dispatch("fullscreenImageOverlay/close");
};

const ImageFullscreenComponent = () => {
  const imageArray = useSelector(
    (state) => state.fullscreenImageOverlay.imageArray
  );
  const initialSlide = useSelector(
    (state) => state.fullscreenImageOverlay.initialSlide
  );
  const [activeSlide, setActiveSlide] = useState(0);
  const esc = useKey("escape");

  useEffect(() => {
    if (initialSlide) setActiveSlide(initialSlide);
  }, [initialSlide]);

  const handleSwipe = (direction) => {
    if (direction === "next") {
      if (activeSlide === imageArray.length - 1) setActiveSlide(0);
      else setActiveSlide(activeSlide + 1);
    } else if (direction === "prev") {
      if (activeSlide === 0) setActiveSlide(imageArray.length - 1);
      else setActiveSlide(activeSlide - 1);
    }
  };

  if (esc) overlayClose();
  return (
    <FullScreenBlock isIE={checkIfIE()}>
      <CloseButton onClick={() => dispatch("fullscreenImageOverlay/close")}>
        LUKK
        <Cross color={colors.white} />
      </CloseButton>
      <div className="slideshow">
        {imageArray?.map(({ image, title, imageText }, i) => {
          const titleToRender = title || imageText;
          return (
            <div
              key={i}
              className={
                "slide " + (i === activeSlide ? " active " : " inactive ")
              }
            >
              <Image
                lazy="eager"
                alt={image.altText}
                src={image.mediaItemUrl}
              />
              {titleToRender && (
                <div className="text-block">
                  <Arrow color={colors.white} className="arrow" />
                  <p
                    className="image-title"
                    dangerouslySetInnerHTML={{
                      __html: parseTitle(
                        titleToRender,
                        activeSlide,
                        imageArray?.length
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
        {imageArray?.length > 1 && (
          <>
            <div
              className="swiper-button-next"
              onClick={() => handleSwipe("next")}
            >
              <Arrow />
            </div>
            <div
              className="swiper-button-prev"
              onClick={() => handleSwipe("prev")}
            >
              <Arrow />
            </div>
          </>
        )}
      </div>
    </FullScreenBlock>
  );
};

export default ImageFullscreenComponent;

const CloseButton = styled.button`
  display: inline-flex;
  font-family: ${typography.compressedBold};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.015em;
  align-self: flex-end;
  margin-top: 27px;
  margin-right: 22px;
  cursor: pointer;
  color: ${colors.white};
  svg {
    margin-left: 13px;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 100px;
    right: 22px;
  }
`;

const FullScreenBlock = styled.div`
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  z-index: 30;
  .slideshow {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide {
      &.inactive {
        display: none;
      }
      width: 80%;
      max-height: 80%;
      ${(p) =>
        p.isIE &&
        css`
          width: auto;
          height: auto;
          max-width: 80%;
          max-height: 80%;
        `}
      display: flex;
      flex-direction: column;
      justify-content: center;
      .text-block {
        display: inline-flex;
        margin-top: 37px;
        align-items: flex-start;
        padding-top: 14px;
        padding-right: 20px;
        border-top: solid 1px ${colors.black};
        width: fit-content;
        .arrow {
          padding-top: 5px;
          margin-right: 9px;
        }
        p.image-title {
          color: ${colors.white};
          font-family: ${typography.mono};
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.015em;
          span.bold-text {
            font-family: ${typography.monoBold};
          }
        }
      }
      img {
        height: 70vh;
        object-fit: contain;
        width: 100%;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      cursor: pointer;
      margin: 0 28px;
      z-index: 3;
      position: absolute;
      width: 60px;
      height: 60px;
      background-color: ${colors.white};
      transition: opacity 0.15s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 22px;
        height: 12px;
        path {
          fill: ${colors.black};
        }
      }
      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        margin: 0;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;
