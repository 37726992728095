import { useEffect } from "react";
const useFocusTrap = (ref) => {
  useEffect(() => {
    ref.current.setAttribute("tabIndex", 0);

    const listener = (event) => {
      if (!ref.current) return;
      if (!ref.current.contains(event.relatedTarget)) ref.current.focus();
    };

    window.addEventListener("focusout", listener);

    return () => {
      window.removeEventListener("focusout", listener);
    };
  }, [ref]);
};

export default useFocusTrap;
