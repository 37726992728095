import React from "react";
import styled from "styled-components";
import { typography } from "../../../const";

const Timeline = ({ timeline }) => (
  <TimeList>
    {timeline?.map((shard, i) => (
      <li className="time-shard" key={i}>
        <p className="bold-text">{shard.year}</p>
        <p>{shard.text}</p>
      </li>
    ))}
  </TimeList>
);

export default Timeline;

const TimeList = styled.ul`
  display: flex;
  flex-direction: column;
  li.time-shard {
    display: flex;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.005em;
    font-family: ${typography.regular};
    margin-bottom: 20px;
    .bold-text {
      font-family: ${typography.bold};
      margin-right: 8px;
    }
  }
`;
