import React from "react";
import styled, { css } from "styled-components";
import { Link as A } from "react-router-dom";

import { checkIfIE } from "../../../const/helpers";
import { typography, colors } from "../../../const";

import Image from "../../../components/Image";

const parseStatus = (status) => {
  if (!status) return;
  let slicingPoint = status.indexOf("-");
  let returnWithoutChanging = status.slice(0, slicingPoint + 1);
  let setAsBold = status.slice(slicingPoint + 1);
  return `${returnWithoutChanging}<span class='bold-text'>${setAsBold}</span>`;
};

const ArchiveBlock = ({ data }) => {
  const { title, link } = data;
  const { featureImage, type, status } = data.fields;
  const replacedType = type === "project" ? "BYGGEPROSJEKT" : "EIENDOM";
  const parsedStatus = parseStatus(status);
  return (
    <StyledA to={link} isIE={checkIfIE()}>
      <Image
        alt={featureImage?.altText}
        src={featureImage?.mediaItemUrl}
        sizeOverride={800}
      />
      <div className="text-block">
        <p className="type">{replacedType}</p>
        <h3>{title}</h3>
        <p
          className="status"
          dangerouslySetInnerHTML={{ __html: parsedStatus }}
        />
      </div>
    </StyledA>
  );
};

export default ArchiveBlock;

const StyledA = styled(A)`
  display: block;
  margin-bottom: 78px;
  text-decoration: none;
  width: 100%;
  &:hover {
    color: ${colors.primary};
    transition: 0.2s color linear;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 0.78;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  .text-block {
    margin-top: 22px;
    p,
    h3 {
      text-align: center;
      display: block;
    }
    p {
      &.type {
        font-family: ${typography.mono};
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      &.status {
        font-family: ${typography.mono};
        font-size: 13px;
        line-height: 24px;
        span.bold-text {
          font-family: ${typography.monoBold};
        }
      }
    }

    h3 {
      font-family: ${typography.compressedBold};
      font-size: 30px;
      line-height: 38px;
      text-transform: uppercase;
    }

    ${(p) =>
      p.isIE &&
      css`
        display: inline-table;
        text-align: center;
        > * {
          margin: 0;
        }
      `}
  }
  @media (min-width: 1024px) {
    width: calc(33% - 31px);
  }
`;
