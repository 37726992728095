import React from "react";
import styled from "styled-components";
import { dispatch } from "../../redux";

const OpenFullscreen = ({ showcaseItems, initialSlide, ...rest }) => {
  //if data from props is not an array, convert it to one before we send it
  let dispatchingData = showcaseItems;
  if (!Array.isArray(dispatchingData)) dispatchingData = [dispatchingData];

  return (
    <FullscreenButton
      aria-label="open image in fullscreen view"
      onClick={() =>
        dispatch("fullscreenImageOverlay/open", {
          imageArray: dispatchingData,
          initialSlide: initialSlide,
        })
      }
      {...rest}
    />
  );
};

export default OpenFullscreen;

const FullscreenButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  &:hover {
    cursor: zoom-in;
    svg {
      transform: scale(1.25);
    }
  }
  svg {
    position: absolute;
    right: 28px;
    bottom: 18px;
    transition: transform 0.2s ease-in-out;
  }
`;
