const defaultState = { isOpen: false };

export default function searchOverlayReducer(state = defaultState, action) {
  const { type } = action;

  switch (type) {
    case "searchOverlay/toggle":
      return { ...state, isOpen: !state.isOpen };
    case "searchOverlay/close":
      return { ...state, isOpen: false };
    case "searchOverlay/open":
      return { ...state, isOpen: true };
    case "searchOverlay/clear":
    case "REDUX/CLEAR":
      return { ...defaultState };
    default:
      return state;
  }
}
