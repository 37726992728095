import React from "react";

const Mail = ({ height, width, color, ariaHidden }) => {
  return (
    <svg
      width={width || "26.515"}
      height={height || "26.5"}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 94.12 74.81"
      fill={color || "#fff"}
      aria-hidden={ariaHidden}
    >
      <path
        className="cls-1"
        d="M0,2.9V72.14H94.12V2.9Zm74,9-1.85,2.41a8.8,8.8,0,0,1-.64.73L51.35,35.16,51.22,35a7.51,7.51,0,0,1-8.31,0l-.1.11-1.14-1.1-19-19a7.16,7.16,0,0,1-.63-.73L20.16,11.9ZM85.12,63.14H9V13.78l2.81,5a18.4,18.4,0,0,0,3,4L33.9,41.9A18.45,18.45,0,0,0,47,47.34h0A18.45,18.45,0,0,0,60.22,41.9L79.28,22.84a18.4,18.4,0,0,0,3-4l2.81-5Z"
      />
    </svg>
  );
};

export default Mail;
