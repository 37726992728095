import React from "react";

const SmallSearch = ({ ariaHidden }) => {
  return (
    <svg width="10.939" height="9.491" viewBox="0 0 10.939 9.491">
      <g
        data-name="Group 3896"
        transform="translate(10.353 1.763) rotate(101)"
        aria-hidden={ariaHidden}
      >
        <path
          data-name="Path 809"
          d="M6.165,3.082A3.083,3.083,0,1,0,3.083,6.165,3.092,3.092,0,0,0,6.165,3.082Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          data-name="Line 386"
          x2="3.198"
          y2="3.198"
          transform="translate(5.279 5.279)"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default SmallSearch;
