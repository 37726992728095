import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import useRequest from "kb-gql";
import { ContentPadding, Wrapper } from "../Wrapper";
import { ListTitle } from "../../elements";
import { Grid, Row, Column } from "../Grid";
import { colors, typography } from "../../const";
import { xmlToJson } from "../../lib/xml-to-json";

export const vacanciesModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_VacanciesModule {
		fieldGroupName
	}
`;

const excerptQuery = `
	siteSettings {
		fields {
			vacanciesExcerpt
		}
	}
`;

const getActualNumberOfVacancies = (listings = []) => {
  if (!Array.isArray(listings)) {
    return 0;
  }

  if (
    listings.length === 1 &&
    listings[0]?.title.includes("Vi har for tiden ingen utlyste stillinger")
  ) {
    return 0;
  }

  return listings.length;
};

const VacanciesModule = ({ data }) => {
  const [listings, setListings] = useState(null);
  const excerpt = useRequest(
    excerptQuery,
    "_global_vacancies_excerpt",
    "fields.vacanciesExcerpt"
  );

  useEffect(() => {
    if (!listings) {
      fetch(`${process.env.REACT_APP_API_REST_URL}/v2/kb-get-vacancies`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          const jsonObj = xmlToJson(response);
          const listings = (jsonObj.channel.item || [])
            .map((item) => {
              return {
                deadline: item["wc:apply_within_date"]?.text,
                positionTitle: item["wc:PositionTitle"]?.text,
                workarea: item["wc:WorkplacePostaddress"]?.text,
                link: item?.link?.text,
              };
            })
            .sort((a, b) => {
              return a.deadline > b.deadline ? 1 : -1;
            });

          setListings(listings);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [listings]);

  return (
    <ContentPadding>
      <VacanciesModuleWrapper>
        <TitleBlock>
          <ListTitle>
            {`Ledige stillinger (${
              getActualNumberOfVacancies(listings) || ""
            })`}
          </ListTitle>
          <p
            className="ingress"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </TitleBlock>
        <VacanciesList>
          <StyledTable>
            <thead>
              <tr className="border-bottom">
                <th>Stilling</th>
                <th>Sted</th>
                <th>Søknadsfrist</th>
                <th>Lenke</th>
              </tr>
            </thead>
            <tbody>
              {getActualNumberOfVacancies(listings) > 0 ? (
                listings?.map((listing, i) => {
                  const dueDate = moment(listing.deadline).format("DD.MM.YYYY");
                  return (
                    <tr>
                      <td>
                        <span>{listing.positionTitle}</span>
                      </td>
                      <td>
                        <span>{listing.workarea}</span>
                      </td>
                      <td>
                        <span className="deadline">
                          <span className="mobile">Søknadsfrist: </span>
                          {dueDate}
                        </span>
                      </td>
                      <td>
                        <a
                          href={listing.link}
                          rel="nofollow noopener noreferrer"
                          target="_blank"
                        >
                          {"Les mer"}
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th>Det er for tiden ingen ledige stillinger</th>
                </tr>
              )}
            </tbody>
          </StyledTable>
        </VacanciesList>
      </VacanciesModuleWrapper>
    </ContentPadding>
  );
};

export default VacanciesModule;

const VacanciesModuleWrapper = styled(Wrapper)`
  width: 100%;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${ListTitle} {
    padding-bottom: 24px;
  }
  p.ingress {
    font-size: 20px;
    line-height: 28px;
  }
  @media (max-width: 1024px) {
    ${ListTitle} {
      border-bottom: solid 1px ${colors.grey};
    }
  }
`;

const VacanciesList = styled(Grid)`
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 24px;
  padding-top: 68px;

  .jobtitle {
    font-weight: bold;
    &:hover {
      opacity: 0.6;
    }
  }

  .deadline {
    .mobile {
      display: none;
    }
  }

  ${Row} {
    border-bottom: solid 1px ${colors.grey};
    width: 100%;
    left: 0;
    &.empty {
      border-bottom: none;
    }
    ${Column} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-title-row {
    padding-bottom: 13px;
    margin-bottom: 20px;
    .vacancies-section-title {
      font-family: ${typography.mono};
      letter-spacing: 0.024em;
    }
  }

  .vacancies-listing-row {
    padding-top: 22px;
    padding-bottom: 25px;
    a {
      color: inherit;
    }
  }
  @media (max-width: 1024px) {
    padding-top: 0;
    .section-title-row {
      display: none;
    }
    .deadline {
      .mobile {
        display: inline-flex;
        margin-right: 0.5em;
      }
    }
  }
`;

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  thead {
    tr {
      font-family: ${typography.mono};
      letter-spacing: 0.024em;
      text-align: left;
      border-bottom: 1px solid #000000;

      th {
        font-weight: normal;
        padding-bottom: 13px;
        margin-bottom: 20px;
        width: 25%;
        padding-right: 25px;

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          width: 50%;
        }
      }

      @media (max-width: 768px) {
        border-bottom: none;
        display: none;
      }

      @media (max-width: 1024px) {
        border-bottom: none;
        display: none;
      }
    }
  }

  tbody {
    tr {
      font-family: ${typography.regular};
      letter-spacing: 0.024em;
      text-align: left;
      td {
        padding-top: 22px;
        padding-bottom: 25px;
        padding-right: 25px;

        &:last-child {
          padding-right: 0;
        }
      }

      @media (max-width: 768px) {
        letter-spacing: 0;
        td {
          display: block;
          padding: 0;
          &:first-child {
            padding-top: 22px;
          }
        }
      }

      @media (max-width: 1024px) {
        letter-spacing: 0;
        td {
          display: block;
          padding: 0;
          &:first-child {
            padding-top: 22px;
          }
        }
      }
    }
  }
`;
