export const colors = {
  primary: "#186775",
  secondary: "#E7FAFC",
  tertiary: "#FFF7E8",
  grey: "#707070",
  projectsIndexTitle: "#272727",
  loadingGrey: "#F2F2F2",
  white: "#FFFFFF",
  black: "#000000",
  error: "#BA483F",
};

export const typography = {
  regular: "GT-America-Standard-Regular",
  bold: "GT-America-Standard-Bold",
  condensedMedium: "GT-America-Condensed-Medium",
  condensedBold: "GT-America-Condensed-Bold",
  compressedBold: "GT-America-Compressed-Bold",
  mono: "GT-America-Mono-Regular",
  monoBold: "GT-America-Mono-Bold",
  canelaTextRegular: "CanelaText-Regular",
  canelaRegular: "Canela-Regular",
};
