import React, { useState } from "react";
import styled from "styled-components";
import { Row, Column } from "../../../components/Grid";
import { colors, typography } from "../../../const";
import { ContentRow } from "../../../components/modules/accordionPartials/AccordionContent";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "Æ",
  "Ø",
  "Å",
];

function EmployeeRow({
  letter,
  archive,
  letterFilter,
  setFilteredLength,
  onEmailClick,
}) {
  const [openArray, setOpenState] = useState([]);
  const handleOpener = (i) => {
    if (!openArray.includes(i)) setOpenState([...openArray, i]);
    else setOpenState(openArray.filter((a) => a !== i));
  };
  let letterPeople = archive.filter((a) => a.name[0].toUpperCase() === letter);
  if (!letterPeople) return null;
  if (
    letterFilter.length > 0 &&
    letterFilter.toUpperCase() !== letter.toUpperCase()
  )
    return null;
  if (letterFilter.length > 0) setFilteredLength(letterPeople.length);
  return (
    <LetterHeading key={letter}>
      {letterPeople?.length > 0 && <h2>{letter}</h2>}
      {letterPeople.map((employee, i) => {
        const isOpen = openArray.includes(i);
        return (
          <MobileEmployeeRow key={i} aria-expanded={isOpen}>
            <Column
              className={`${isOpen ? "open" : "closed"} ${
                i === 0 ? "first" : ""
              } name-column`}
              onClick={() => handleOpener(i)}
            >
              <p className="name">
                {isOpen ? "-" : "+"} {employee?.name}
              </p>
            </Column>
            <MobileContentRow open={isOpen} className="employees">
              <Column>
                <p className="position">{employee?.jobTitle}</p>
              </Column>
              <Column>
                <a href={`tel:${employee.phoneNumber}`} className="phonenumber">
                  {employee?.phoneNumber?.replace(
                    /(\d{1,3})(\d{1,2})(\d{1,3})/,
                    "$1 $2 $3"
                  )}
                </a>
              </Column>
              <Column>
                <button onClick={(e) => onEmailClick(e, employee)}>
                  Send epost
                </button>
              </Column>
            </MobileContentRow>
          </MobileEmployeeRow>
        );
      })}
    </LetterHeading>
  );
}

const MobileEmployeeRows = ({
  archive,
  letterFilter,
  setFilteredLength,
  searching,
  onEmailClick,
}) => (
  <>
    {alphabet.map((letter) => (
      <EmployeeRow
        key={letter}
        letter={letter}
        archive={archive}
        letterFilter={letterFilter}
        setFilteredLength={setFilteredLength}
        onEmailClick={onEmailClick}
      />
    ))}
  </>
);

export default React.memo(MobileEmployeeRows);

const LetterHeading = styled.div`
  margin-bottom: 20px;
  h2 {
    font-family: ${typography.compressedBold};
    font-size: 60px;
    line-height: 65px;
    letter-spacing: 0.005em;
  }
  @media (max-width: 768px) {
    padding-top: 16px;
    h2 {
      font-size: 30px;
      line-height: 35px;
      padding-bottom: 13px;
    }
  }
`;

const MobileEmployeeRow = styled(Row)`
  border-bottom: solid 1px ${colors.grey};
  ${Column} {
    &.first {
      border-top: solid 1px ${colors.grey};
      margin-top: 13px;
    }
    font-weight: normal;
    width: 100%;
    .position {
      text-transform: capitalize;
    }
    &.name-column {
      display: flex;
      .name {
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        line-height: 60px;
      }
    }
  }
`;

const MobileContentRow = styled(ContentRow)`
  ${({ open }) => (open ? `margin: 10px 0 25px;` : ``)}
`;
