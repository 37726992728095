import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { typography, colors } from "../../../../const";
import { Grid, Row, Column } from "../../../../components/Grid";
import { SearchResultTitle, Type, PublishedDate } from "../../../../elements";
import moment from "moment";
import { Link as A } from "react-router-dom";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../redux";

const createLoadingBlocks = (amountOfLoadingBlocks) => {
  return Array.from(Array(amountOfLoadingBlocks), (_, i) => (
    <LoadingBlock key={i}>
      <div className="loading-title" />
      <div className="loading-content" />
      <div className="loading-dateline" />
    </LoadingBlock>
  ));
};

const typeCheck = (type) => {
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
  const match = type?.match(pathNameRegex);
  if (match) {
    if (match[1]) {
      return `${match[1]}`;
    } else {
      return "";
    }
  }
};

const SearchResults = ({ overlayClose }) => {
  const [displayError, setDisplayError] = useState(false);

  const results = useSelector((state) => state.searchResult.data);
  const searchString = useSelector((state) => state.searchResult.string);
  const isTyping = useSelector((state) => state.searchResult.isTyping);
  const isSearching = useSelector((state) => state.searchResult.isSearching);

  useEffect(() => {
    setDisplayError(false);
    let timer = setTimeout(() => setDisplayError(true), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchString]);

  return (
    <SearchResultsContainer>
      <Grid>
        <Row>
          {isTyping ||
            (isSearching && (
              <LoadingBlockWrapper span={12}>
                <h3>Søker...</h3>
              </LoadingBlockWrapper>
            ))}
          {!isTyping && results && results.length && (
            <LoadingBlockWrapper span={12}>
              <h3>
                Søketreff på "{searchString}" ({results?.length})
              </h3>
            </LoadingBlockWrapper>
          )}
          {!isTyping && results && !results.length && (
            <LoadingBlockWrapper span={12}>
              <h3>{`Fant ingen treff på "${searchString}"`}</h3>
            </LoadingBlockWrapper>
          )}
        </Row>
        <Row className="search-result-list">
          {results?.map((result, i) => {
            const { title, dateGmt, fields, link, downloadableFile } = result;
            const publicationDate = fields?.metaInfo?.publicationDate
              ? moment(
                  fields?.metaInfo.publicationDate,
                  "D/M/YYYY hh:mm a"
                ).format("D.M.YYYY")
              : moment(dateGmt).format("D.M.YYYY");
            if (!result) return null;
            return (
              <SearchResult key={i} span={7}>
                <A
                  to={link || downloadableFile?.mediaItemUrl || ""}
                  onClick={overlayClose}
                  rel="nofollow noreferrer"
                  className="link"
                >
                  <Type>{typeCheck(link)}</Type>
                  <SearchResultTitle>{title}</SearchResultTitle>
                  <PublishedDate>
                    Publisert <span className="bold">{publicationDate}</span>
                  </PublishedDate>
                </A>
              </SearchResult>
            );
          })}
        </Row>
      </Grid>
    </SearchResultsContainer>
  );
};

export default SearchResults;

const SearchResultsContainer = styled.section`
  margin-top: 95px;
  width: 100%;
  display: flex;
  ${Grid} {
    padding-bottom: 160px;
  }
  ${Row} {
    &.search-result-list {
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    margin-top: 25px;
    padding: 0 25px;
    width: unset;
    ${Row} {
      h3 {
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 0.005em;
      }
    }
  }
`;

export const SearchResult = styled(Column)`
  .link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  ${Type} {
    margin-top: 21px;
    margin-bottom: 14px;
  }
  ${SearchResultTitle} {
    margin-bottom: 21px;
  }
  ${PublishedDate} {
    padding-bottom: 21px;
  }
  border-bottom: solid 1px ${colors.grey};
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const LoadingBlockWrapper = styled(Column)`
  h3 {
    color: ${colors.black};
    font-family: ${typography.compressedBold};
    font-size: 60px;
    line-height: 120px;
    letter-spacing: 0.015em;
  }
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const loadingAnimation = keyframes`
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;

const LoadingBlock = styled.div`
  border-bottom: 1px solid ${colors.loadingGrey};
  max-width: 740px;
  width: 100%;
  margin: 20px 0 27px;
  > div {
    background-color: ${colors.loadingGrey};
    position: relative;
    &:before {
      animation: ${loadingAnimation} 2s ease-in-out infinite;
      background-color: ${colors.white};
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
    }
  }
  .loading-title {
    height: 20px;
    width: 40%;
    margin-bottom: 13px;
  }
  .loading-content {
    height: 31px;
    width: 100%;
    margin-bottom: 23px;
  }
  .loading-dateline {
    height: 16px;
    width: 25%;
    margin-bottom: 21px;
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;
