import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../const";
import useRequest from "kb-gql";
import FooterLinks from "./components/FooterLinks";
import SocialMediaLinks from "./components/SocialMediaLinks";
import InfoBlock from "./components/InfoBlock";
import { Grid, Row, Column } from "../../components/Grid";

const query = `
    siteSettings {
		fields {
			footer {
		        footerMenu{
					linkGroup {
		            	link{
							title
							url
							target
						}
		        	}
				}
		        infoBlocks{
					text
          			title
				}
		        socialMediaLinks{
					facebook
					facebookIcon{
						mediaItemUrl
					}
			        linkedin
					linkedinIcon{
						mediaItemUrl
					}
			        twitter
					twitterIcon{
						mediaItemUrl
					}
			        youtube
					youtubeIcon{
						mediaItemUrl
					}
					instagram
					instagramIcon{
						mediaItemUrl
					}
				}
		    }
		}
    }
`;

const Footer = React.forwardRef(
  ({ primaryScheme, laptopOffset, laptopSpan, ...rest }, ref) => {
    const footerItems = useRequest(query, "_global_footer", "fields.footer");
    if (!footerItems) return null;
    return (
      <FooterWrapper
        ref={ref}
        laptopSpan={laptopSpan}
        laptopOffset={laptopOffset}
        {...rest}
      >
        <Grid>
          <Row className="top-row row">
            <Column
              tabletSpan={12}
              span={12}
              className="link-block social-media-block"
            >
              <Row>
                <FooterLinks footerMenu={footerItems?.footerMenu} />
                <SocialMediaLinks links={footerItems.socialMediaLinks} />
              </Row>
            </Column>
          </Row>
          <Row className="row">
            <Column span={12} className="info-block">
              <h5 className="row-title">INFO</h5>
              <Row className="info-block-row">
                <InfoBlock info={footerItems.infoBlocks} />
              </Row>
            </Column>
          </Row>
        </Grid>
      </FooterWrapper>
    );
  }
);

export default Footer;

const FooterWrapper = styled(Column)`
  padding: 54px 0 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.white};
  .top-row {
    margin-bottom: 42px;
  }
  &.row {
    align-items: center;
  }
  .row-title {
    width: 100%;
    color: ${colors.white};
    font-family: ${typography.regular};
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    border-bottom: solid 1px ${colors.white};
    margin-bottom: 23px;
    padding-bottom: 10px;
  }
  @media (max-width: 768px) {
    padding-left: 25px !important;
    padding-right: 25px !important;
    .info-block-row {
      flex-direction: column;
    }
  }
`;
