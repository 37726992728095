import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { dispatch } from "../../redux";
import { Wrapper, ContentPadding } from "../../components/Wrapper";
import NewsAndArticleArchiveBlock from "../partials/components/NewsAndArticleArchiveBlock";
import SearchBlock from "../partials/components/searchPartials/SearchBlock";
import { Grid, Row, Column } from "../../components/Grid";
import moment from "moment";
import {
  IndexTitle,
  SearchResultTitle,
  Type,
  PublishedDate,
} from "../../elements";
import { SearchResult } from "../partials/components/searchPartials/SearchResults";
import { Link as A } from "react-router-dom";
import { SignupButton } from "../../elements";

const newsArchiveQuery = `
	newsArticles(first: 999) {
		pageInfo{
			endCursor
			hasNextPage
		}
		nodes {
		  __typename
		  title
		  link
		  fields {
			metaInfo {
			  publicationDate
			}
			image {
			  altText
			  mediaItemUrl
			}
		  }
		}
	}
`;

const articleArchiveQuery = `
	articles(first: 999) {
		pageInfo{
			endCursor
			hasNextPage
		}
		nodes {
		  __typename
		  title
		  link
		  fields {
			metaInfo {
			  publicationDate
			}
			image {
			  altText
			  mediaItemUrl
			}
		  }
		}
	}
`;

const NewsAndArticleIndex = () => {
  const [searchString, setSearchString] = useState("");
  const [slicePoint, setSlicePoint] = useState(14);
  useEffect(() => {
    dispatch("colorScheme/primary");
  }, []);

  const newsArchive = useRequest(newsArchiveQuery, "newsArchive");

  const articleArchive = useRequest(articleArchiveQuery, "articleArchive");

  const archive = useMemo(
    () =>
      newsArchive?.nodes
        ?.concat(articleArchive?.nodes)
        ?.filter((post) => {
          let formatted = moment(
            post?.fields?.metaInfo?.publicationDate,
            "D/M/YYYY hh:mm a"
          ).format("YYYY-MM-DD");
          //hide the posts with a publicationdate after
          return moment().isAfter(formatted, "minute");
        })
        .sort((a, b) => {
          const formattedA = moment(
            a?.fields?.metaInfo?.publicationDate,
            "D/M/YYYY hh:mm a"
          ).format("YYYY-MM-DD");
          const formattedB = moment(
            b?.fields?.metaInfo?.publicationDate,
            "D/M/YYYY hh:mm a"
          ).format("YYYY-MM-DD");

          if (moment(formattedA).isAfter(formattedB, "day")) return -1;
          if (moment(formattedA).isSame(formattedB, "day")) return 0;
          if (moment(formattedA).isBefore(formattedB, "day")) return 1;
        }),
    [newsArchive, articleArchive]
  );
  if (!archive) return null;
  let searchResults =
    searchString.length > 2
      ? archive.filter((publication) => {
          let searchable = publication.title + " " + publication.fields?.author;
          return (
            searchable.toLowerCase().search(searchString.toLowerCase()) !== -1
          );
        })
      : archive;
  return (
    <NewsAndArticleWrapper>
      <SearchBlock
        placeholder="Søk i arkiv"
        searchHandler={setSearchString}
        slimSearch
      />
      <ContentPadding>
        <TitleBlock>
          <IndexTitle>Alle saker ({searchResults.length})</IndexTitle>
        </TitleBlock>
        <StyledDiv>
          {searchResults.slice(0, 6).map((block, i) => (
            <NewsAndArticleArchiveBlock data={block} key={i} />
          ))}
        </StyledDiv>
        <Grid>
          <Row>
            {searchResults.slice(6, slicePoint).map((block, i) => {
              const { title, link, fields, __typename } = block;
              const publicationDate = moment(
                fields?.metaInfo?.publicationDate,
                "D/M/YYYY hh:mm a"
              ).format("D/M/YYYY");
              const type =
                __typename === "NewsArticle" ? "Nyheter" : "Artikkel";
              return (
                <SearchResult key={i} span={12}>
                  <A to={link} rel="nofollow noreferrer" className="link">
                    <Type>{type}</Type>
                    <SearchResultTitle>{title}</SearchResultTitle>
                    <PublishedDate>
                      Publisert <span className="bold">{publicationDate}</span>
                    </PublishedDate>
                  </A>
                </SearchResult>
              );
            })}
            <Column className="load-more-column" span={12}>
              {slicePoint < searchResults.length && (
                <SignupButton
                  className="load-more-button"
                  onClick={() => setSlicePoint(slicePoint + 20)}
                >
                  Last inn mer
                </SignupButton>
              )}
            </Column>
          </Row>
        </Grid>
      </ContentPadding>
    </NewsAndArticleWrapper>
  );
};

export default NewsAndArticleIndex;

const NewsAndArticleWrapper = styled(Wrapper)`
  ${SearchResult} {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .load-more-column {
    align-items: center;
    .load-more-button {
      width: fit-content;
      margin-left: 0;
      margin-top: 24px;
    }
  }
  @media (max-width: 1024px) {
    padding-top: 98px;
    margin: 0;
  }
  @media (max-width: 768px) {
    padding-top: 0;
    margin: 0 25px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${IndexTitle} {
    margin-top: 26px;
    margin-bottom: 28px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;
