import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { typography, colors } from "../../../const";
import { Grid, Row, Column } from "../../Grid";
import Image from "../../Image";
import Download from "../../../assets/icons/Download";
import { checkIfIE } from "../../../const/helpers";

const fileTypeHandler = (mimeType) => {
  let filetype =
    mimeType === "application/pdf" ? "pdf" : mimeType?.split("/")[0];

  switch (filetype) {
    case "pdf":
      return "pdf";
    case "video":
      return "film";
    case "image":
      return "bilde";
    default:
      return "fil";
  }
};

const checkIfAllFilesPdf = (downloadable) => {
  const filterOnType = downloadable.filter(
    ({ downloadableFile }) => downloadableFile?.mimeType === "application/pdf"
  );
  return filterOnType?.length === downloadable?.length;
};

const Downloadable = ({
  pdfForceThumbnail,
  downloadable,
  archiveStyle,
  pageType = "",
}) => {
  const allFilesPdf = useMemo(() => checkIfAllFilesPdf(downloadable), [
    downloadable,
  ]);
  return (
    <Grid
      colSpan={4}
      setGutterWidth={50}
      tabletColSpan={6}
      setLaptopGutterWidth={40}
      setTabletGutterWidth={36}
    >
      <Row>
        {downloadable?.map((item, i) => {
          const { title, thumbnail, downloadableFile, fields } = item;
          const filetype = fileTypeHandler(
            downloadableFile?.mimeType || fields?.downloadableFile?.mimeType
          );
          return (
            <Column key={i}>
              <DownloadableContent
                className={`${archiveStyle ? "archive-style" : ""} ${pageType}`}
                isIE={checkIfIE()}
              >
                {thumbnail && !allFilesPdf && (
                  <Image
                    onClick={() =>
                      window.open(
                        downloadableFile?.mediaItemUrl ||
                          fields?.downloadableFile?.mediaItemUrl,
                        "_blank"
                      )
                    }
                    src={thumbnail.mediaItemUrl}
                    alt={thumbnail.altText}
                    className="downloadable-thumbnail"
                  />
                )}
                {thumbnail && allFilesPdf && pdfForceThumbnail && (
                  <Image
                    src={thumbnail.mediaItemUrl}
                    alt={thumbnail.altText}
                    className="downloadable-thumbnail"
                  />
                )}
                {fields?.publicationDate && (
                  <p className="publication-date">
                    Publisert 
                    <span>{fields?.publicationDate}</span>
                  </p>
                )}
                <h3 className="content-title">
                  {title || downloadableFile?.title}
                </h3>
                {thumbnail && !allFilesPdf && thumbnail?.caption && (
                  <p className="publication-author">
                    Foto:{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: thumbnail?.caption,
                      }}
                    />
                  </p>
                )}
                {fields?.authorLink && fields?.author && (
                  <p className="publication-author">
                    Kontaktperson:{" "}
                    <a href={`mailto: ${fields?.authorLink}`}>
                      {fields?.author}
                    </a>
                  </p>
                )}
                {!fields?.authorLink && fields?.author && (
                  <p className="publication-author">
                    Kontakt: {fields?.author}
                  </p>
                )}
                <div className="button-container">
                  <a
                    href={
                      downloadableFile?.mediaItemUrl ||
                      fields?.downloadableFile?.mediaItemUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-button"
                    download
                  >
                    Last ned {filetype}
                  </a>
                  <Download className="download-arrow" />
                </div>
              </DownloadableContent>
            </Column>
          );
        })}
      </Row>
    </Grid>
  );
};

export default Downloadable;

const DownloadableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px;
  margin-bottom: 38px;
  height: 100%;
  background-color: ${colors.secondary};

  .downloadable-thumbnail {
    width: 100%;
  }

  > h3 {
    display: inline-table;
  }

  &.archive-style {
    background-color: ${colors.secondary};
  }
  &.ProjectOrProperty {
    background-color: rgba(0, 0, 0, 0.05); //5% opacity black
  }
  p.publication-date {
    margin-bottom: 7px;
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.024em;
    font-family: ${typography.mono};
    text-transform: uppercase;

    ${(p) =>
      p.isIE &&
      css`
        margin-bottom: 21px;
      `}
    span {
      font-family: ${typography.monoBold};
      letter-spacing: 0;
    }
  }
  p.publication-author {
    margin-top: 13px;
    margin-bottom: 19px;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    font-family: ${typography.mono};
  }
  h3.content-title {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.02em;
    font-family: ${typography.condensedBold};
    text-align: center;
    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
      svg {
        g {
          path {
            stroke: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    .download-button {
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      font-family: ${typography.regular};
      letter-spacing: -0.015em;
      text-decoration: underline;
      margin-top: 10px;
    }
    .download-arrow {
      padding-bottom: 3px;
      margin-left: 5px;
    }
  }

  img {
    object-fit: contain;
    padding-bottom: 12px;
    height: 93px;
    width: auto;
  }
`;
