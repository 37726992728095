import React, { useRef, useState } from "react";
import styled from "styled-components";
import { dispatch } from "../../../redux";
import AlgoliaSearchResults from "./searchPartials/AlgoliaSearchResults";
import AlgoliaSearchBlock from "./searchPartials/AlgoliaSearchBlock";
import useFocusTrap from "../../../hooks/useFocusTrap";
import { gutterWidth } from "../../../components/Grid";
import { checkIfSafari } from "../../../const/helpers";
import { useSelector } from "react-redux";
import { InstantSearch, Configure } from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";

function overlayClose() {
  dispatch("searchOverlay/close");
}

const SearchOverlay = () => {
  const modalRef = useRef();

  const isSafari = checkIfSafari() ? "safari" : "";
  const isSearchOpen = useSelector((state) => state.searchOverlay.isOpen);

  // Disabled, because why would you close the modal by pressing tab?
  //useOnSearchClose(modalRef, isSearchOpen, () => overlayClose());
  // Disabled, broken, triggered on every click.
  //useOnClickOutside(modalRef, overlayClose);
  // Trap focus inside of overlay.
  useFocusTrap(modalRef);

  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
  );

  // This is to avoid initial search results, i.e. search for "".
  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  const searchIndexName = process.env?.REACT_APP_ALGOLIA_INDEX_PREFIX
    ? process.env.REACT_APP_ALGOLIA_INDEX_PREFIX + "_all"
    : "production_all";

  return (
    <ModalWrapper ref={modalRef} className={isSafari}>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndexName}
        insights={true}
      >
        <Configure analytics={true} hitsPerPage={50} />
        <AlgoliaSearchBlock
          overlayClose={overlayClose}
          showTitle
          slimSearch
          mainSearch
          isSafari={isSafari}
        />
        <AlgoliaSearchResults overlayClose={overlayClose} />
      </InstantSearch>
    </ModalWrapper>
  );
};

export default SearchOverlay;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${gutterWidth}px);
  z-index: 7;
  max-height: calc(100vh - 82px);
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  @media (max-width: 1024px) {
    &.safari {
      max-height: unset;
    }
  }
  @media (max-width: 768px) {
    background-color: white;
    width: 100%;
    height: 635px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
