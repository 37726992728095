import React, { useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";

import { dispatch } from "../../redux";
import { typography } from "../../const";
import { useProjectsArchiveSeo } from "../../hooks/useSeo";

import { ProjectTitle } from "../../elements";
import ArchiveBlock from "../partials/components/ArchiveBlock";
import Seo from "../partials/Seo";

const projectsOrPropertyArchiveQuery = `
	projectsAndProperties(first: 99) {
		nodes {
			title
			link
			databaseId
			fields {
				type
				status
				featureImage {
					mediaItemUrl
					altText
				}
			}
		}
	}
`;

const indexFieldsQuery = `
	projectsAndPropertiesPage {
		indexFields {
			description
			headerTitle
			contentOrder{
				... on ProjectOrProperty {
		        	databaseId
		        }
			}
		}
	}
`;

const mapOrder = (array, order) => {
  // define arrays for custom order of selected posts
  let sortableArray = [];
  let restOfArray = [];
  //loop through original archive
  array.forEach((post, i) => {
    //if a post is identified as custom ordered, push it to sortableArray
    if (order.includes(post.databaseId)) sortableArray.push(post);
    //else, move it straight towards outputting
    else restOfArray.push(post);
  });

  //sort array based on custom order from CMS
  sortableArray.sort((a, b) => {
    const A = a.databaseId;
    const B = b.databaseId;
    if (order.indexOf(A) === order.indexOf(B)) return 0;
    return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
  });

  //merge arrays before returning
  return sortableArray.concat(restOfArray);
};

const ProjectOrPropertyIndex = () => {
  useEffect(() => {
    dispatch("colorScheme/project");
  }, []);
  const archive = useRequest(
    projectsOrPropertyArchiveQuery,
    "projectsAndPropertiesArchive",
    "nodes"
  );
  const index = useRequest(
    indexFieldsQuery,
    "projectsAndPropertiesPage",
    "indexFields"
  );
  const projectsArchiveSeoData = useProjectsArchiveSeo();
  if (!index || !archive) return null;
  const { description, headerTitle, contentOrder } = index;
  let sortedArray = [];

  if (contentOrder) {
    //get the custom order of posts from the CMS
    const getCustomOrder = contentOrder.map(({ databaseId }) => databaseId);
    //sort array based on custom order
    sortedArray = mapOrder(archive, getCustomOrder);
  }

  const postsToRender = sortedArray?.length > 0 ? sortedArray : archive;

  return (
    <div>
      <Seo pageSeo={projectsArchiveSeoData} />
      <TitleBlock>
        <ProjectTitle>{headerTitle}</ProjectTitle>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </TitleBlock>
      <StyledDiv>
        {postsToRender.map((block, i) => (
          <ArchiveBlock data={block} key={i} />
        ))}
      </StyledDiv>
    </div>
  );
};

export default ProjectOrPropertyIndex;

const TitleBlock = styled.div`
  text-align: center;
  padding-bottom: 60px;
  margin-bottom: 137px;
  h1 {
    text-transform: uppercase;
    margin-top: 26px;
    margin-bottom: 28px;
  }
  h1,
  p {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }
  p.description {
    font-size: 26px;
    line-height: 36px;
    font-family: ${typography.canelaTextRegular};
    max-width: 850px;
  }

  @media (max-width: 1440px) {
    h1 {
      font-size: 60px;
    }
    p.description {
      font-size: 23px;
      line-height: 33px;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 25px;
    margin-bottom: 24px;
    h1 {
      font-size: 35px;
      line-height: 36px;
      letter-spacing: 0;
    }
    p.description {
      font-size: 19px;
      line-height: 30px;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;
