const defaultState = {};

export default function requestReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case "request/update":
      return { ...state, ...payload };
    case "request/clear":
    case "REDUX/CLEAR":
      return defaultState;
    default:
      return state;
  }
}
