import React, { useState, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";
// import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import useKey from "../hooks/useKey";
import useScroll from "../hooks/useScroll";
import { dispatch } from "../redux";
import { colors } from "../const";
import { checkIfSafari } from "../const/helpers";
import useWindowSize from "../hooks/useWindowSize";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Seo from "./partials/Seo";
import SearchOverlay from "./partials/components/SearchOverlay";
import EmployeeForm from "./partials/components/EmployeeForm";
import ImageFullscreenComponent from "../components/ImageFullscreenComponent";
import { Grid, Row, Column } from "../components/Grid";

const getLayoutWidths = (windowSize, fullScreen = false) => {
  const { width } = windowSize;
  let sizes = {
    headerColumnWidth: "0vw",
    mainContentWidth: "100vw",
    initialOffset: "75vw",
  };

  if (width > 1440) {
    sizes = {
      headerColumnWidth: "25vw",
      mainContentWidth: "75vw",
      initialOffset: "75vw",
    };
  }

  if (fullScreen) {
    sizes = {
      headerColumnWidth: "0vw",
      mainContentWidth: "100vw",
      initialOffset: "75vw",
    };
  }

  return sizes;
};

const MasterLayout = ({ children }) => {
  const fullscreenImageOverlay = useSelector(
    (state) => state.fullscreenImageOverlay.isOpen
  );
  const isSearchOpen = useSelector((state) => state.searchOverlay.isOpen);
  const isEmployeeFormOpen = useSelector((state) => state.employeeForm.isOpen);
  const primaryColorScheme = useSelector(
    (state) => state.colorScheme.primaryScheme
  );
  const lockedView =
    isSearchOpen || fullscreenImageOverlay || isEmployeeFormOpen;
  const esc = useKey("escape");
  const footer = useRef(null);
  const windowSize = useWindowSize();
  const layoutWidths = getLayoutWidths(windowSize, fullscreenImageOverlay);

  //scroll
  const [archivedScrollPosition, saveScrollPosition] = useState(0);
  const scrollPos = useScroll().y;
  useEffect(() => {
    //if the fullscreenImageOverlay is set to true and the scrollpos is above 0,
    //save the scrollposition
    if ((fullscreenImageOverlay || isEmployeeFormOpen) && scrollPos > 0)
      saveScrollPosition(scrollPos);
    //whenever the fullscreenImageOverlay closes, scroll to saved position
    if (!fullscreenImageOverlay && !isEmployeeFormOpen)
      window.scrollTo(0, archivedScrollPosition);
    //dependency array warning can be ignored, this effect should only run when
    //fullscreenImageOverlay changes value
  }, [fullscreenImageOverlay, isEmployeeFormOpen]);

  if (esc) {
    if (isSearchOpen) dispatch("searchOverlay/close");
    if (isEmployeeFormOpen) dispatch("employeeForm/close");
  }

  // const location = useLocation();
  // const path = location.pathname;
  const path = window.location.pathname;
  const isEmployeeIndexPage = path.startsWith("/kontakt/ansattsok");

  const main = (
    <ColumnWrapper type="main" layoutWidths={layoutWidths}>
      <Column
        id={"main-content"}
        className={`
                    main-content 
                    ${isSearchOpen && "search-open"}
                    ${isEmployeeFormOpen && "employee-form-open"}
                `}
        offset={0}
        span={10}
        tabletSpan={12}
        laptopSpan={10}
        laptopOffset={1}
        mobileSpan={12}
        templateWrapper
        as="article"
      >
        {isSearchOpen && <SearchOverlay />}
        {isEmployeeFormOpen && <EmployeeForm />}
        {children}
      </Column>
    </ColumnWrapper>
  );

  const { trackPageView /* , trackEvent  */ } = useMatomo();
  useEffect(() => {
    if (process.env.REACT_APP_MATOMO_URL_BASE) {
      trackPageView();
    }
  }, [path]);

  return (
    <Container
      className={`
                ${
                  !primaryColorScheme && !isSearchOpen && "secondary-background"
                } 
                ${lockedView && "locked-view"}
            `}
    >
      <Seo />

      <BackgroundColorFill
        className={`
                    ${!primaryColorScheme && "secondary-background"}
                    ${isSearchOpen && "search-open"}
                `}
      />
      <Grid>
        <MotionDiv
          className={`
                        top-level-motiondiv 
                        ${
                          !primaryColorScheme &&
                          !isSearchOpen &&
                          "secondary-background"
                        }
                    `}
          style={{
            display: "flex",
            margin: "0 auto",
            overflowX: "visible",
          }}
          variants={{
            initial: {
              x: `-${layoutWidths.initialOffset}`,
            },
            animate: {
              x: 0,
            },
            exit: {
              x: `-${layoutWidths.mainContentWidth}`,
            },
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            delay: fullscreenImageOverlay ? 0 : 0.25,
            duration: fullscreenImageOverlay ? 0 : 0.8,
            ease: [0.43, 0.13, 0.23, 0.96],
          }}
        >
          <ColumnWrapper type="header" layoutWidths={layoutWidths}>
            <HeaderColumn
              span={3}
              tabletSpan={12}
              noTabletPadding
              className={`
                                ${
                                  !primaryColorScheme &&
                                  !isSearchOpen &&
                                  "secondary-background"
                                } 
                                ${
                                  isSearchOpen &&
                                  !primaryColorScheme &&
                                  "search-open"
                                }
                                ${isEmployeeFormOpen && "employee-form-open"}
                            `}
            >
              <Header
                isSearchOpen={isSearchOpen}
                primaryColorScheme={primaryColorScheme}
                scrollPos={scrollPos}
                footerRef={footer}
                isSafari={checkIfSafari() && "safari"}
                isEmployeeIndexPage={isEmployeeIndexPage}
                isEmployeeFormOpen={isEmployeeFormOpen}
                employeeFormOpenScrollPosition={archivedScrollPosition}
              />
            </HeaderColumn>
          </ColumnWrapper>
          <RelativeMain>
            {main}
            {isSearchOpen && <WhiteOpaqueOverlay open={isSearchOpen} />}
            {isEmployeeFormOpen && (
              <WhiteOpaqueOverlay open={isEmployeeFormOpen} />
            )}
            {fullscreenImageOverlay && <ImageFullscreenComponent />}
          </RelativeMain>
        </MotionDiv>
      </Grid>
      <Grid as="footer" className="footer-background">
        <Row>
          <Footer
            ref={footer}
            offset={3}
            span={8}
            tabletSpan={12}
            laptopSpan={10}
            laptopOffset={1}
            primaryScheme={primaryColorScheme}
          />
        </Row>
      </Grid>
    </Container>
  );
};

export default MasterLayout;

const MotionDiv = styled(motion.div)`
  position: relative;

  &.top-level-motiondiv {
    z-index: 99;
  }
`;

const BackgroundColorFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: background-color 2s;

  &.secondary-background {
    background-color: ${colors.tertiary};
  }

  &.search-open {
    transition: background-color 0s;
  }
`;

const RelativeMain = styled.main`
  position: relative;
`;

const ColumnWrapper = styled(MotionDiv)`
  ${(props) =>
    props.type === "main" &&
    `
            width: ${props.layoutWidths.mainContentWidth};
            flex: 0 0 ${props.layoutWidths.mainContentWidth};
        `}

  ${(props) =>
    props.type === "header" &&
    `
            width: ${props.layoutWidths.headerColumnWidth};
            flex: 0 0 ${props.layoutWidths.headerColumnWidth};
        `}
`;

const HeaderColumn = styled(Column)`
  order: -1;
  z-index: 10;

  &.secondary-background {
    order: 1;
  }

  @media (min-width: 1441px) {
    height: 100vh;
  }

  @media (max-width: 1440px) {
    z-index: 8;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;

  &.locked-view {
    height: 100vh;
    overflow: hidden;
  }

  .search-open,
  .employee-form-open {
    position: relative;
  }

  .main-row {
    min-height: calc(100vh - 499px);
    width: calc(100% + 16px);
    @media (min-width: 1920px) {
      justify-content: center;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .main-content {
    margin-top: 82px;
    display: flex;
    align-items: center;

    > :first-child {
      max-width: 1200px;
      width: 100%;
    }

    @media (max-width: 1440px) {
      margin-top: 164px;
    }
    @media (max-width: 768px) {
      margin-top: 95px;
    }
  }

  .footer-background {
    background-color: ${colors.primary};
    margin-top: 162px;
    z-index: 11;
    @media (max-width: 768px) {
      margin-top: 70px;
    }
  }
`;

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const fadeInAnimationRule = css`
  ${fadeInAnimation} 0.1s ease-in-out;
`;

const WhiteOpaqueOverlay = styled.div`
  animation: ${fadeInAnimationRule};
  top: 0;
  left: 0;
  background-color: ${colors.white};
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  pointer-events: none;

  ${({ open }) =>
    open
      ? `
		opacity: 1;
		`
      : `
		opacity: 0;
    `}

  .overlay-logo {
    position: absolute;
    cursor: pointer;
    top: 60px;
    left: -180px;

    &.safari {
      top: 35px;
    }

    @media (max-width: 1440px) {
      left: 120px;
    }

    @media (max-width: 1024px) {
      top: 45px;
      left: 76px;
    }

    @media (max-width: 768px) {
      top: 24px;
      left: 25px;
    }
  }
`;
