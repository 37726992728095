import React from "react";
import styled from "styled-components";

import { typography } from "../../const";

import Image from "../Image";
import { ContentPadding } from "../Wrapper";
import { EntryTitle } from "../../elements";

export const contactModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_PressContactModule {
		fieldGroupName
		contacts{
			contact{
				... on Author{
					title
					fields{
						jobtitle
						appendToJobtitle
						email
						phoneNumber
						profilePicture{
							mediaItemUrl
							altText
						}
					}
				}
			}
		}
	}
`;

const ContactContent = ({ contact, small = false }) => {
  return (
    <TextBlock>
      <EntryTitle>{contact?.title}</EntryTitle>
      <p>{`${contact.fields.jobtitle} ${
        contact.fields.appendToJobtitle ? contact.fields.appendToJobtitle : ""
      }`}</p>
      <a className="phone" href={`tel:${contact.fields.phoneNumber}`}>
        {contact.fields.phoneNumber
          .toString()
          .replace(/(\d{1,3})(\d{1,2})(\d{1,3})/, "$1 $2 $3")}
      </a>
      <a className="email" href={`mailto:${contact.fields.email}`}>
        Send e-post
      </a>
    </TextBlock>
  );
};

const SingleContact = ({ contact }) => {
  if (!contact) return null;
  return (
    <SingleContactWrapper>
      <Image
        src={contact.fields.profilePicture?.mediaItemUrl}
        alt={contact.fields.profilePicture?.altText || ""}
      />
      <ContactContent contact={contact} />
    </SingleContactWrapper>
  );
};

const ContactCard = ({ contact, key }) => {
  if (!contact) return null;
  return (
    <ContactCardWrapper key={key}>
      <Image
        alt={contact.fields.profilePicture?.altText}
        src={contact.fields.profilePicture?.mediaItemUrl}
      />
      <ContactContent contact={contact} small />
    </ContactCardWrapper>
  );
};

const ContactModule = ({ data }) => {
  if (!data) return null;
  const { contacts } = data;
  const isSingle = contacts.length === 1;

  return (
    <ContentPadding>
      {isSingle ? (
        <SingleContact contact={contacts[0]?.contact} />
      ) : (
        <StyledDiv>
          {contacts.map(({ contact }, i) => {
            return <ContactCard contact={contact} key={i} />;
          })}
        </StyledDiv>
      )}
    </ContentPadding>
  );
};

export default ContactModule;

const SingleContactWrapper = styled.div`
  display: block;
  img {
    object-fit: cover;
    width: 50%;
    height: auto;
    object-position: center;
    aspect-ratio: 1 / 0.67;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    h3 {
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 12px;
    }
    a.phone {
      margin-bottom: 12px;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 50px;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;

const ContactCardWrapper = styled.div`
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    object-position: center;
    aspect-ratio: 1 / 0.76;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  @media (min-width: 1024px) {
    width: calc(33% - 51px);
    h3 {
      font-size: 40px;
      line-height: 52px;
    }
  }
`;

const TextBlock = styled.div`
  h3,
  p,
  a {
    display: block;
    text-align: center;
  }
  h3 {
    margin-bottom: 24px;
  }
  p {
    font-family: ${typography.bold};
    margin-bottom: 8px;
  }
  a {
    &.phone {
      margin-bottom: 8px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &.email {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
