import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import moment from "moment";
import { useSelector } from "react-redux";

import { dispatch } from "../../redux";

import { ContentPadding } from "../../components/Wrapper";
import Downloadable from "../../components/modules/accordionPartials/Downloadable";
import SearchBlock from "../partials/components/searchPartials/SearchBlock";
import FilteringDropdown from "../partials/components/FilteringDropdown";
import { IndexTitle } from "../../elements";

const publicationsArchiveQuery = `
	publications(first: 999) {
		nodes {
			title
			terms(first: 99){
				nodes{
					... on PublicationType {
						name
					}
				}
			}
			fields {
				author
				authorLink
				publicationDate
				downloadableFile{
					mediaItemUrl
					mimeType
				}
			}
		}
	}
`;

const taxonomyQuery = `
	publicationTypes{
		nodes{
			name
		}
	}
`;

const PublicationsIndex = () => {
  useEffect(() => {
    dispatch("colorScheme/primary");
  }, []);
  const searchString = useSelector((state) => state.searchResult.string);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const filterTerms = useRequest(taxonomyQuery, "publicationsTerms", "nodes");
  const archive = useRequest(
    publicationsArchiveQuery,
    "publicationsArchive",
    "nodes"
  )?.filter((publication) => {
    let formatted = moment(
      publication?.fields?.publicationDate,
      "DD.MM.YYYY"
    ).format("YYYY-MM-DD");
    //hide the posts with a publicationdate after
    return moment().isSameOrAfter(formatted, "day");
  });
  const handleSelectedTerms = (term) => {
    if (selectedTerms.includes(term)) {
      const newArray = selectedTerms.filter((item) => item !== term);
      setSelectedTerms(newArray);
    } else {
      setSelectedTerms([...selectedTerms, term]);
    }
  };
  if (!archive) return null;

  let searchResults =
    searchString.length > 2
      ? archive.filter((publication) => {
          let searchable = `${publication.title} ${
            publication?.fields?.author
          } ${publication?.terms?.nodes?.map(({ name = "" }) => name)}`;
          return (
            searchable.toLowerCase().search(searchString?.toLowerCase()) !== -1
          );
        })
      : archive;

  if (selectedTerms.length > 0) {
    searchResults = searchResults?.filter((publication) => {
      const connectedTerms = `${publication?.terms?.nodes?.map(
        ({ name = "" }) => ` ${name} `
      )}`;
      return selectedTerms?.some((term) => connectedTerms?.includes(term));
    });
  }

  return (
    <PublicationsWrapper>
      <SearchBlock placeholder="Søk i publikasjoner" slimSearch />
      <StyledContentPadding>
        <TitleBlock>
          <IndexTitle>Alle Publikasjoner ({searchResults.length})</IndexTitle>
          <FilteringDropdown
            terms={filterTerms}
            setSelectedTerms={handleSelectedTerms}
          />
        </TitleBlock>
        <Downloadable archiveStyle downloadable={searchResults} />
      </StyledContentPadding>
    </PublicationsWrapper>
  );
};

export default PublicationsIndex;

const PublicationsWrapper = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    padding-top: 98px;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: calc(100% - 50px);
    padding-top: 0;
    margin: 0 25px;
  }
`;

const StyledContentPadding = styled(ContentPadding)`
  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${IndexTitle} {
    margin-top: 26px;
    margin-bottom: 28px;
  }
`;
