import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { Wrapper } from "../../components/Wrapper";
import { typography, colors } from "../../const";
import { dispatch } from "../../redux";
import SearchBlock from "../partials/components/searchPartials/SearchBlock";
import LetterFiltering from "../partials/components/LetterFiltering";
import MobileEmployeeRows from "../partials/components/MobileEmployeeRows";
import { Grid } from "../../components/Grid";
import { IndexTitle } from "../../elements";
import useScroll from "../../hooks/useScroll";
import useWindowSize from "../../hooks/useWindowSize";
import { useSelector } from "react-redux";

const EmployeeIndex = () => {
  const searchString = useSelector((state) => state.searchResult.string);
  const [employees, setEmployees] = useState([]);
  const [fetchCompleted, setFetchCompleted] = useState(false);
  const [totalEmployees, setTotalEmployees] = useState([]);
  const [letterFilter, setLetterFilter] = useState("");
  const [filteredLength, setFilteredLength] = useState(0);
  const searching = searchString.length >= 1;
  const scrollPos = useScroll().y;
  const windowSize = useWindowSize().width;
  const stickyHeader = useRef(null);
  const currentStickyHeader = stickyHeader?.current;
  const savedOffsetTop = useMemo(() => currentStickyHeader?.offsetTop, [
    currentStickyHeader,
  ]);
  const headerSticks = savedOffsetTop <= scrollPos;

  useEffect(() => {
    dispatch("colorScheme/primary");
    async function getData() {
      fetch(`${process.env.REACT_APP_API_REST_URL}/v2/employees`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((employees) => {
          const rows = employees.sort((a, b) => {
            if (a.lastName < b.lastName) return -1;
            if (a.lastName > b.lastName) return 1;
            return 0;
          });
          setTotalEmployees(employees.length);
          setEmployees(rows);
          setFetchCompleted(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getData();
  }, []);

  const archive = useMemo(() => {
    return employees.map((employee) => {
      return {
        letterToFilterBy: employee.lastName[0],
        name: `${employee.lastName}, ${employee.firstName}`,
        phoneNumber: employee.phone,
        jobTitle: employee.jobPosition,
        emailKey: `${(employee.lastName + "_" + employee.firstName)
          .replaceAll(" ", "-")
          .toLowerCase()}`,
        searchable: `${employee.lastName} ${employee.firstName} ${employee.phone} ${employee.jobPosition}`.toLowerCase(),
      };
    });
  }, [employees]);

  if (!archive || archive.length < 1 || !fetchCompleted) return null;

  const searchResults =
    searching &&
    archive.filter(({ searchable }) => {
      let searchStrings = [];
      if (searchString.includes(" ")) searchStrings = searchString.split(" ");

      let hit = true;

      if (searchStrings.length > 1) {
        searchStrings.forEach((string) => {
          if (hit) hit = searchable?.search(string.toLowerCase()) !== -1;
        });
      } else {
        hit = searchable?.search(searchString.toLowerCase()) !== -1;
      }

      return hit;
    });

  const onEmailClick = (event, employee) => {
    event.preventDefault();
    dispatch("employeeForm/open", {
      employee,
    });
  };

  return (
    <EmployeeWrapper>
      <SearchBlock placeholder="Søk blant alle ansatte" slimSearch />
      <StickyWhenScrolled
        ref={stickyHeader}
        className={headerSticks ? "sticky" : ""}
      >
        <TitleBlock>
          {renderTitle(
            searching,
            searchResults,
            searchString,
            totalEmployees,
            letterFilter,
            filteredLength
          )}
        </TitleBlock>
        <LetterFiltering
          letterFilter={letterFilter}
          setLetterFilter={setLetterFilter}
        />
      </StickyWhenScrolled>

      {windowSize > 768 ? (
        <EmployeeTable>
          <thead key={"head"}>
            <tr className="head-row mobile-hide" key={"head-tr-name"}>
              <th id={"th-name"} scope="col" key={"head-th-name"}>
                <p key={"head-name"}>Navn</p>
              </th>
              <th id={"th-position"} scope="col" key={"head-th-position"}>
                <p key={"head-position"}>Stilling</p>
              </th>
              <th id={"th-phone"} scope="col" key={"head-th-phone"}>
                <p key={"head-phone"}>Telefon</p>
              </th>
              <th id={"th-email"} scope="col" key={"head-th-email"}>
                <p key={"head-email"}>Epost</p>
              </th>
            </tr>
          </thead>
          <tbody key={"body"}>
            <EmployeeRows
              archive={searching ? searchResults : archive}
              letterFilter={letterFilter}
              setFilteredLength={setFilteredLength}
              searching={searching}
              onEmailClick={onEmailClick}
            />
          </tbody>
        </EmployeeTable>
      ) : (
        <MobileEmployeeList>
          <MobileEmployeeRows
            archive={searching ? searchResults : archive}
            letterFilter={letterFilter}
            setFilteredLength={setFilteredLength}
            searching={searching}
            onEmailClick={onEmailClick}
          />
        </MobileEmployeeList>
      )}
    </EmployeeWrapper>
  );
};

export default EmployeeIndex;

const renderTitle = (
  searching,
  searchResults,
  searchString,
  totalEmployees,
  letterFilter,
  filteredLength
) => {
  if (searching) {
    return (
      <IndexTitle role="alert" aria-live="polite">
        Søkeresultat for "{searchString}" ({searchResults?.length})
      </IndexTitle>
    );
  } else if (letterFilter) {
    return (
      <IndexTitle role="alert" aria-live="polite">
        Filtrert ({filteredLength})
      </IndexTitle>
    );
  } else {
    return (
      <IndexTitle role="alert" aria-live="polite">
        Alle Ansatte ({totalEmployees})
      </IndexTitle>
    );
  }
};

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "Æ",
  "Ø",
  "Å",
];

const EmployeeRows = React.memo(
  ({ archive, letterFilter, setFilteredLength, searching, onEmailClick }) =>
    alphabet.map((letter) => {
      let letterPeople = archive.filter(
        (a) => a.name[0].toUpperCase() === letter
      );
      if (!letterPeople) return null;
      if (
        letterFilter.length > 0 &&
        letterFilter.toUpperCase() !== letter.toUpperCase()
      )
        return null;
      if (letterFilter.length > 0) setFilteredLength(letterPeople.length);

      return (
        <React.Fragment key={"fragment-" + letter}>
          <tr className="letter-row" key={"tr-" + letter}>
            <LetterHeading key={"th-" + letter} scope="rowgroup">
              {letterPeople?.length > 0 && <h2 key={letter}>{letter}</h2>}
            </LetterHeading>
          </tr>

          {letterPeople.map((employee, i) => (
            <tr className="employee-listing-row" key={"tr-employee-" + i}>
              <td key={"tr-employee-" + i + "-td-name"}>
                <p className="name" key={"tr-employee-" + i + "-name"}>
                  {employee?.name}
                </p>
              </td>
              <td key={"tr-employee-" + i + "-td-position"}>
                <p className="position" key={"tr-employee-" + i + "-position"}>
                  {employee?.jobTitle}
                </p>
              </td>
              <td key={"tr-employee-" + i + "-td-phone"}>
                <a
                  href={`tel:${employee.phoneNumber}`}
                  className="phonenumber"
                  key={"tr-employee-" + i + "-phone"}
                >
                  {employee?.phoneNumber?.replace(
                    /(\d{1,3})(\d{1,2})(\d{1,3})/,
                    "$1 $2 $3"
                  ) || ""}
                </a>
              </td>
              <td key={"tr-employee-" + i + "-td-email"}>
                <button
                  onClick={(e) => onEmailClick(e, employee)}
                  key={"tr-employee-" + i + "-email"}
                >
                  Send epost
                </button>
              </td>
            </tr>
          ))}
        </React.Fragment>
      );
    })
);

const EmployeeWrapper = styled(Wrapper)`
  position: relative;
  @media (max-width: 1024px) {
    margin: 0;
  }
  @media (max-width: 768px) {
    padding-top: 45px;
    margin: 0 25px;
  }
`;

export const LetterHeading = styled.th`
  margin-bottom: 20px;
  h2 {
    font-family: ${typography.compressedBold};
    font-size: 60px;
    line-height: 65px;
    letter-spacing: 0.005em;
  }
  @media (max-width: 768px) {
    padding-top: 16px;
    h2 {
      font-size: 30px;
      line-height: 35px;
      padding-bottom: 13px;
    }
  }
`;

const StickyWhenScrolled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 55px;
  background-color: white;
  z-index: 2;
  &.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 0;
  }
  @media (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 41px;
    &.sticky {
      padding-bottom: 0;
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${IndexTitle} {
    margin-top: 26px;
    margin-bottom: 28px;
  }
`;

const EmployeeTable = styled.table`
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 24px;
  margin-top: 68px;
  border-collapse: collapse;
  text-align: left;

  @media (max-width: 1024px) {
    margin-top: 60px;
  }

  // Should not really render above 768 anyway.
  @media (max-width: 768px) {
    margin-top: 0;
    .mobile-hide {
      display: none;
    }
  }

  tr {
    th {
      font-family: ${typography.mono};
      letter-spacing: 0.024em;
    }

    &.head-row {
      border-bottom: solid 1px ${colors.grey};
      th {
        font-weight: normal;
        p {
          display: block;
          //margin-bottom: 44px;
        }
        &#th-name {
          width: 33%;
        }
        &#th-position {
          width: 25%;
        }
        &#th-phone {
          width: 25%;
        }
        &#th-email {
          width: 16.6667%;
        }
      }
    }

    &.letter-row th h2 {
      margin-top: 20px;
      display: block;
    }

    &.employee-listing-row {
      border-bottom: solid 1px ${colors.grey};
      padding-top: 16px;
      padding-bottom: 20px;

      td {
        p {
          display: block;
          margin-top: 16px;
          margin-bottom: 20px;

          &.name {
            font-weight: bold;
          }
          &.position {
            text-transform: capitalize;
          }
        }

        button {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`;

const MobileEmployeeList = styled(Grid)`
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 24px;
  margin-top: 0;
  padding-top: 0;
  position: relative;
  display: block;
`;
