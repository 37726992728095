import React, { useEffect, useRef } from "react";
import styled from "styled-components";

export const anchorModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_AnchorModule {
		fieldGroupName
        anchor
	}
`;

const AnchorModule = ({ data }) => {
  const anchorRef = useRef(null);
  useEffect(() => {
    if (anchorRef && window?.location?.hash) {
      if (window.location.hash.includes(encodeURI(data?.anchor))) {
        anchorRef.current.scrollIntoView({
          behaviour: "smooth",
          block: "start",
          inline: "center",
        });
      }
    }
  }, [anchorRef]);
  return <Anchor id={data?.anchor} ref={anchorRef} />;
};

export default AnchorModule;

const Anchor = styled.div`
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;
