import React, { useRef, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { dispatch } from "../../../redux";
import { gutterWidth } from "../../../components/Grid";
import { useSelector } from "react-redux";

import useFocusTrap from "../../../hooks/useFocusTrap";

import { typography, colors } from "../../../const";
import Cross from "../../../assets/icons/Cross";
import Paperclip from "../../../assets/icons/Paperclip";

import { SignupButton } from "../../../elements";
import AttachmentFileList from "./AttachmentFileList";

import { byteSize, byteSizeFromBase64size } from "../../../const/helpers";

const FILE_SIZE_LIMIT = 25000000; // 25 MB limit from mailchimp.
const ALLOWED_MIMES = [
  "application/pdf", // PDF
  "application/msword", // DOC
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "application/vnd.ms-excel", // XLS
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
  "application/vnd.ms-powerpoint", // PPT
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
  "image/jpeg", // JPG, JPEG
  "image/png", // PNG
  "image/tiff", // TIF, TIFF
  "text/plain", // TXT
  "application/zip", // ZIP
];

function handleSubmit(event, attachments = [], setAttachments) {
  event.preventDefault();
  dispatch("employeeForm/submit");

  const formData = new FormData(event.target);

  // Honeypot, give a false positive if it's filled.
  if (formData.get("email-hp")) {
    dispatch("employeeForm/submit-succeeded");
    setTimeout(() => {
      dispatch("employeeForm/close");
    }, 2500);
    return;
  }

  // Check attachments for disallowed mime-types.
  for (let i = 0; i < attachments.length; i++) {
    if (!ALLOWED_MIMES.includes(attachments[i].type)) {
      dispatch("employeeForm/submit-failed", {
        error: "Denne filtypen støttes ikke",
      });
      return;
    }
  }

  // Let's avoid formData as much as possible
  const unserializedObject = Object.fromEntries(formData);
  unserializedObject.attachments = attachments;
  const jsonObject = JSON.stringify(unserializedObject);

  // Mailchimp has a 25 MB limit, but sending files as base64 increases the size by about ~33.333%.
  if (byteSize(jsonObject) >= FILE_SIZE_LIMIT) {
    dispatch("employeeForm/submit-failed", {
      error:
        "Filstørrelsen er for stor. Maks " +
        Math.floor(byteSizeFromBase64size(FILE_SIZE_LIMIT) / 1000000) +
        "MB",
    });
    return;
  }

  // Send to backend, where the employee emails are stored.
  fetch(`${process.env.REACT_APP_API_REST_URL}/v2/employee-email`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: jsonObject,
  })
    .then((res) => {
      if (!res.ok) throw new Error(res?.error || "Noe gikk galt under sending");
      return res.json();
    })
    .then((data) => {
      dispatch("employeeForm/submit-succeeded");
      setTimeout(() => {
        setAttachments([]);
        dispatch("employeeForm/close");
      }, 2500);
    })
    .catch((error) => {
      console.error(error);
      dispatch("employeeForm/submit-failed", {
        error: error?.message || "Noe gikk galt under sending",
      });
    });
}

const Base64FileInput = React.forwardRef(
  ({ multiple, onDone, onError, ...props }, ref) => {
    // Convert 1 file to base64.
    const readFileContents = (file) =>
      new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        // Read file as base64.
        fileReader.readAsDataURL(file);

        fileReader.onload = () => resolve(fileReader.result.split(",")[1]);
        fileReader.onerror = (error) => reject(error);
      });

    // Convert all files to base64 one by one, and wait until all conversions are done.
    const readFiles = (files) =>
      Promise.all(
        files.map(async (file) => {
          return await readFileContents(file);
        })
      );

    const handleFilesPicked = (event) => {
      event.preventDefault();

      // Get files.
      let files = [];
      [...event.target.files].map((file) => files.push(file));

      // Read files.
      readFiles(files)
        .then((res) => {
          if (files.length == res.length) {
            files.map((file, i) => {
              file.content = res[i];
            });
            // Apply Callback function
            onDone(files);
          } else {
            throw new Error("Ukjent problem med et eller flere vedlegg.");
          }
        })
        .catch((err) => {
          onError(err);
        });
    };

    return (
      <input
        type="file"
        onChange={(event) => handleFilesPicked(event)}
        multiple={multiple}
        ref={ref}
        {...props}
      />
    );
  }
);

const EmployeeForm = ({ placeholder, ...props }) => {
  const modalRef = useRef();
  const firstInputRef = useRef();
  const attachmentInput = useRef();

  const employee = useSelector((state) => state.employeeForm.employee);
  const isSubmitting = useSelector((state) => state.employeeForm.isSubmitting);
  const submitSucceeded = useSelector((state) => state.employeeForm.success);
  const error = useSelector((state) => state.employeeForm.error);
  const isOpen = useSelector((state) => state.employeeForm.isOpen);

  // Teleport focus to the (first input of the) modal when it is opened.
  useEffect(() => {
    if (isOpen) firstInputRef.current.focus();
  }, [isOpen]);

  // Trap focus inside of modal (until it is closed).
  useFocusTrap(modalRef);

  const [attachments, setAttachments] = useState([]);

  const handleFilePickerDone = (files) => {
    // Separate array, in case of async, only set at end.
    const newAttachments = [...attachments];

    files.map((file) => {
      // Prevent duplicates (based on filename).
      if (
        newAttachments.findIndex(
          (otherFile) => otherFile.name === file.name
        ) === -1
      ) {
        newAttachments.push({
          type: file.type,
          name: file.name,
          content: file.content,
        });
      }
    });

    // Wait with updating state until all files are processed.
    setAttachments(newAttachments);
    dispatch("employeeForm/clear-errors");
  };

  const handleFilePickerError = (err) => {
    console.error(err);
    dispatch("employeeForm/file-attachment-failed", {
      error: error.message || "Noe gikk galt med filvelgeren",
    });
  };

  const removeFileAtIndex = (event, index) => {
    event.preventDefault();

    // create a copy of the array so react does not perf-optimize away our ability to update state.
    const newAttachments = [...attachments];

    // Separate a file out of the array.
    newAttachments.splice(index, 1);

    setAttachments(newAttachments);
    dispatch("employeeForm/clear-errors");
  };

  const onAttachmentButtonClick = (event) => {
    event.preventDefault();
    // Pass the button click from button element to file input element.
    attachmentInput.current.click();
  };

  return (
    <EmployeeFormModal ref={modalRef}>
      <CloseButton onClick={() => dispatch("employeeForm/close")}>
        LUKK
        <Cross ariaHidden="true" />
      </CloseButton>

      <div className="content-block">
        <h2>Kontaktskjema</h2>
        <p>{`Send e-post til ${employee.name}`}</p>
        <StyledForm
          role="employeeForm"
          action="."
          onSubmit={(event) => handleSubmit(event, attachments, setAttachments)}
        >
          <input
            id="email-key"
            name="email-key"
            type="hidden"
            value={employee.emailKey}
            aria-hidden="true"
            tabIndex="-1"
          />
          <div>
            <input
              id="email-email"
              name="email-email"
              type="email"
              placeholder={placeholder || "Din e-post adresse"}
              aria-label={placeholder || "Din e-post adresse"}
              autoComplete="email"
              required
              ref={firstInputRef}
            />
          </div>
          <div>
            {/* Note that the name/id pattern here is different from the rest, 
                            this is done like this to get around the way too aggressive autocomplete in Chrome. */}
            <input
              id="contact-subject"
              name="contact-subject"
              type="text"
              placeholder={placeholder || "Emne"}
              aria-label={placeholder || "Emne"}
              autoComplete="off"
              required
            />
          </div>
          {/* v Honeypot v */}
          <input
            id="email-hp"
            name="email-hp"
            type="hidden"
            aria-hidden="true"
            tabIndex="-1"
          />
          {/* ^ Honeypot ^ */}
          <div>
            <textarea
              id="email-body"
              name="email-body"
              placeholder={placeholder || "Skriv beskjed her..."}
              aria-label={placeholder || "Skriv beskjed her, maks 5000 tegn"}
              maxLength="5000"
              required
            />
            <small>maks 5000 tegn</small>
          </div>

          <BottomWrap>
            <AttachmentFileList
              files={attachments}
              removeFileAtIndexCallback={removeFileAtIndex}
            />
            <ButtonWrap>
              <input
                type="submit"
                tabIndex="-1"
                className="hidden-mobile-submit"
                aria-hidden="true"
              />
              <SubmitButton type="submit" disabled={isSubmitting}>
                {"Send e-post"}
              </SubmitButton>
              <Base64FileInput
                type="file"
                id="email-files"
                ref={attachmentInput}
                style={{ display: "none" }}
                accept={ALLOWED_MIMES.join(",")}
                multiple
                tabIndex="-1"
                aria-hidden="true"
                onDone={handleFilePickerDone}
                onError={handleFilePickerError}
              />
              <AttachmentButton
                onClick={onAttachmentButtonClick}
                aria-label="Legg til vedlegg"
                disabled={isSubmitting}
              >
                <Paperclip ariaHidden="true" />
              </AttachmentButton>
            </ButtonWrap>
            {submitSucceeded && (
              <Status aria-live="assertive">{`Din e-post er sendt til ${employee.name}`}</Status>
            )}
            {error && (
              <Status className="error" role="alert" aria-live="assertive">
                {error ? error : "Noe gikk galt"}
              </Status>
            )}
          </BottomWrap>
        </StyledForm>
      </div>
    </EmployeeFormModal>
  );
};

export default EmployeeForm;

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(0px);
	}
`;

const fadeInAnimationRule = css`
  ${fadeInAnimation} 0.3s ease-in-out;
`;

const EmployeeFormModal = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary};
  //cursor: pointer;
  animation: ${fadeInAnimationRule};
  width: calc(100% - ${gutterWidth}px);
  z-index: 7;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  @media (max-width: 1024px) {
    &.safari {
      max-height: unset;
    }
  }
  @media (max-width: 768px) {
    background-color: white;
    width: 100%;
    height: 635px;
    margin: 0 25px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: calc(100% - 117px);
    padding-left: 117px;
    padding-top: 65px;
    padding-bottom: 65px;

    h2 {
      font-size: 35px;
      line-height: 31px;
      font-family: ${typography.condensedBold};
      color: ${colors.primary};
      letter-spacing: 0.02em;
      margin-bottom: 14px;
    }
    p {
      font-family: ${typography.mono};
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      margin-bottom: 40px;
    }

    @media (max-width: 1024px) {
      padding-top: 30px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 25px;
    &.safari {
      max-height: 100%;
      height: 100vh;
    }
    .content-block {
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin: 0 25px;
    height: unset;
    background-color: ${colors.secondary};
    .content-block {
      padding: 45px 25px;

      h2 {
        font-size: 22px;
        margin-top: 48px;
      }
      p {
        margin-bottom: 21px;
      }
    }
  }
`;

const Status = styled.p`
  margin-top: 20px;
  display: inline-block;

  &.error {
    color: ${colors.error};
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  input,
  textarea {
    padding: 18px 18px 18px 11px;
    display: flex;
    width: 100%;
    background-color: inherit;
    font-family: ${typography.mono};
    font-weight: bold;
    color: ${colors.black};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    &:placeholder {
      color: rgba(0, 0, 0, 0.5); //half opaque black
    }
    //remove webkit cancel button from desktop
    &::-webkit-search-cancel-button {
      display: none;
    }
    &.hidden-mobile-submit {
      position: absolute;
      opacity: 0;
      left: -1200px;
    }
  }

  input {
    border-bottom: solid 1px ${colors.grey};
    max-width: calc(100% - 144px);

    &:focus-visible {
      //outline: none;
      border-bottom: solid 2px ${colors.primary};
      padding: 18px 18px 17px 11px;
    }
  }

  textarea {
    margin-top: 50px;
    max-width: calc(100% - 144px);
    resize: none;
    min-height: 10rem;
    max-height: 10rem;

    &:focus-visible {
      border: solid 2px ${colors.primary};
      padding: 17px 17px 17px 10px;
    }
  }

  button {
    color: ${colors.white};
    display: flex;
    width: 100%;
    max-width: auto;
    border: solid 1px ${colors.grey};
    display: inline-block;
    width: auto;

    &:focus-visible {
      outline-width: 12px;
      outline-style: double;
    }

    &:disabled,
    &:disabled:hover {
      background-color: ${colors.grey};
      border-color: ${colors.grey};
      cursor: not-allowed;
    }
  }

  @media (max-width: 1024px) {
    input,
    textarea {
      max-width: unset;
      width: 100%;
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    input,
    textarea {
      max-width: unset;
      font-size: 1em;
    }

    textarea {
      margin-top: 30px;
    }

    textarea {
      min-height: 6rem;
      max-height: 6rem;
    }
  }
`;

const SubmitButton = styled(SignupButton)`
  margin-left: 0px;
  padding: 18px 18px 18px 11px;
  max-height: 20px;

  &:hover,
  &:focus-visible {
    border: solid 2px ${colors.primary};
    padding: 17px 17px 17px 10px;
  }
`;

const AttachmentButton = styled(SubmitButton)`
  margin-left: 20px;
  padding: 18px;

  &:hover,
  &:focus-visible {
    padding: 17px 17px 17px 17px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const BottomWrap = styled.div`
  max-width: calc(100% - 113px);
  margin-top: 20px;

  @media (max-width: 1024px) {
    max-width: unset;
    margin-top: 0px;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 10px;
  margin-right: 20px;
  display: inline-flex;
  flex: none;
`;

const CloseButton = styled.button`
  display: inline-flex;
  font-family: ${typography.compressedBold};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.015em;
  align-self: flex-end;
  margin-top: 27px;
  margin-right: 22px;
  cursor: pointer;
  svg {
    margin-left: 13px;
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 25px;
  }
`;
