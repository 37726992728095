import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";

import { dispatch } from "../../redux";
import { typography, colors } from "../../const";
import { modulesQuery } from "../../const/modulesQuery";
import { seoQuery } from "../../hooks/useSeo";
import { checkIfIE, parseTitle } from "../../const/helpers";

import { ContentPadding } from "../../components/Wrapper";
import Image from "../../components/Image";
import Builder from "../../components/modules/Builder";
import RichTextComponent from "../../components/RichTextComponent";
import { Grid, Row, Column } from "../../components/Grid";
import AuthorList from "../../components/partials/AuthorList";
import ReadMore from "../partials/components/ReadMore";
import NewsletterModule from "../partials/components/Newsletter";
import Share from "../partials/components/Share";
import { NewsArticleTitle, ArticleIngress } from "../../elements";
import Seo from "../partials/Seo";
import ErrorPage from "./ErrorPage";
import { TitleImageWrapper } from "./FeatureArticle";
import Arrow from "../../assets/icons/Arrow";

/*
 * We split out the queryfields here to make them easier to conditionally include
 * in different queries.
 */
const queryFields = `
	title
	metaTags {
		edges {
			node {
				name
				newsArticles {
					nodes {
						__typename
						title
						slug
						link
						fields{
							image{
								mediaItemUrl
								altText
							}
							metaInfo{
								publicationDate
							}
						}
					}
				}
				articles {
					nodes {
						__typename
						title
						slug
						link
						fields{
							image{
								mediaItemUrl
								altText
							}
							metaInfo{
								publicationDate
							}
						}
					}
				}
			}
		}
	}
	fields{
		title
		bodyText
		ingress
		smallImage
		oldArticle
		metaInfo{
			publicationDate
			photosBy
		}
		newsletterModule {
          text
          title
        }
		image{
			mediaItemUrl
			altText
			description
		}
		authors{
			... on Author{
				title
				fields {
					email
					fieldGroupName
					jobtitle
					phoneNumber
					profilePicture {
						altText
						mediaItemUrl
					}
				}
			}
		}
	}
	${modulesQuery("NewsArticle")}
`;

const makeRequest = (slug) => `
	newsArticle(id: "${slug}", idType: URI) {
		${seoQuery}
		${queryFields}
	}
`;

const previewQuery = (slug, database_id) => `
	query PreviewQuery{
		newsArticle(id: "${slug === "preview" ? database_id : slug}", idType: ${
  /*
   *  check if the slug equals preview, at the time of writing this
   *  any post that is either unsaved or saved as a draft will have the
   *  slug: preview
   */
  slug === "preview" ? "DATABASE_ID" : "URI"
} ) { ${
  /*
   * If the slug does not equals preview we have to query for the "preview"
   * fields in graphql, this is because wp-grapqhl doesn't actually think of it
   * as a preview until it is saved as post and a revision is requested.
   */
  slug !== "preview"
    ? `
		preview{
			node{
				${queryFields}
			}
		}
		`
    : // If we are querying for a revision we query for the regular fields
      `
			${queryFields}
		`
}
		}
	}
`;

async function getPreviewData(searchParams, slug) {
  const preview = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${searchParams.jwtToken}`,
    },
    body: JSON.stringify({
      query: previewQuery(slug, searchParams.database_id),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error in fetching newsarticle preview data: ", error);
    });
  /*
   * Here we do an if check on existing object properties to determine
   * which data to set in the previewData state
   */
  if (preview?.newsArticle?.preview?.node) {
    return preview?.newsArticle?.preview?.node;
  } else {
    return preview?.newsArticle;
  }
}

const checkForNewsLetterModule = (modules) => {
  let key = "fieldGroupName";
  let value = "NewsArticle_Modules_Builder_NewsletterModule";

  return modules.some(function (o) {
    return o[key] === value;
  });
};

const NewsArticle = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const searchParams = {
    jwtToken: state?.jwtToken,
    database_id: state?.database_id
  };
  const data = useRequest(makeRequest(slug), `newsarticle-${slug}`);
  const [previewData, setPreviewData] = useState(null);
  useEffect(() => {
    dispatch("colorScheme/primary");
    //check if there is previewdata available for this post
    getPreviewData(searchParams, slug)
      .then((data) => setPreviewData(data))
      .catch((error) =>
        console.error("Error in getPreviewData useEffect call: ", error)
      );
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
  },[data])
  
  //check if data or previewdata doesn't exist before sending us to the errorpage
  if (data === null && !previewData) return <ErrorPage />;
  if (!data && !previewData) return null;
  if (data === undefined) return null;

  const {
    title,
    ingress,
    image,
    newsletterModule,
    bodyText,
    smallImage,
    authors,
    metaInfo,
    oldArticle,
  } = previewData?.fields || data?.fields;
  const publicationDate = moment(metaInfo?.publicationDate, "D/M/YYYY hh:mm a");
  const now = moment();
  const olderThanAYear = now.diff(publicationDate, "year") >= 1 || oldArticle;
  const isIE = checkIfIE();

  const hasNewsLetterModule = checkForNewsLetterModule(
    previewData?.modules?.builder || data?.modules?.builder
  );
  // Since the static newsletter content is represented in an array, then set the first array item as content
  let newsletter = [];
  if (newsletterModule && newsletterModule.length > 0)
    newsletter = newsletterModule[0];

  return (
    <NewsArticleWrapper>
      <Seo pageSeo={data?.seo} />
      <ContentPadding>
        <TitleBlock>
          <p className="type">Nyheter</p>
          <NewsArticleTitle className="title">
            {previewData?.title || data?.title}
          </NewsArticleTitle>
          <ArticleIngress
            className="NewsArticle"
            dangerouslySetInnerHTML={{ __html: ingress }}
          />
          {metaInfo && (
            <MetaRow>
              <div className="row">
                {metaInfo.publicationDate && (
                  <p>
                    Publisert{" "}
                    <span className="bold">
                      {publicationDate.format("D.M.YYYY")}
                    </span>
                  </p>
                )}
                {(metaInfo.photosBy || image?.description) && (
                  <p>
                    Foto{" "}
                    <span className="bold">
                      {metaInfo.photosBy ||
                        image?.description.replace(/<[^>]*>?/gm, "")}
                    </span>
                  </p>
                )}
              </div>
              {olderThanAYear && (
                <p className="old-article-warning">
                  <span className="circular-border bold">!</span>
                  Artikkelen er over ett år gammel.
                </p>
              )}
            </MetaRow>
          )}
        </TitleBlock>
      </ContentPadding>
      {!smallImage && image && (
        <ImageWrapper isIE={isIE}>
          <Image src={image?.mediaItemUrl} alt={image?.altText} />
          {title && (
            <div className="text-block">
              <Arrow className="arrow" ariaHidden="true" />
              <p
                className="image-title"
                dangerouslySetInnerHTML={{
                  __html: parseTitle(title),
                }}
              />
            </div>
          )}
        </ImageWrapper>
      )}
      <Grid>
        <ContentPadding>
          <Row>
            <Column noTabletPadding span={4}>
              {smallImage && image && (
                <TitleImageWrapper className="small-image" isIE={isIE}>
                  <Image src={image?.mediaItemUrl} alt={image?.altText} />
                </TitleImageWrapper>
              )}
              <AuthorList title data={authors} />
              <Share author={authors} data={data || previewData} />
            </Column>
            <Column noTabletPadding span={8}>
              <RichTextComponent pageType="NewsArticle" data={bodyText} />
            </Column>
          </Row>
        </ContentPadding>
        <Builder
          modules={previewData?.modules?.builder || data?.modules?.builder}
          pageType="NewsArticle"
        />
      </Grid>
      {!hasNewsLetterModule && <NewsletterModule data={newsletter} />}
      <ContentPadding>
        <ReadMore currentTitle={data?.title} data={data?.metaTags?.edges} />
      </ContentPadding>
    </NewsArticleWrapper>
  );
};

export default NewsArticle;

const NewsArticleWrapper = styled.div`
  display: block;
`;

const MetaRow = styled.div`
  font-family: ${typography.mono};
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 69px;
  div.row {
    display: flex;
    flex-direction: row;
  }
  p {
    margin-right: 42px;
    &:last-child {
      margin-right: 0;
    }
  }
  span.bold {
    font-family: ${typography.monoBold};
  }
  .old-article-warning {
    margin-top: 19px;
    display: flex;
    width: fit-content;
    color: ${colors.primary};
    border: solid 1.5px ${colors.primary};
    padding: 9px 15px;
    span.circular-border {
      line-height: 16px;
      margin-top: 2px;
      margin-right: 0.5em;
      border: solid 2px ${colors.primary};
      border-radius: 100%;
      padding: 0 5px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 39px;
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  margin-bottom: 160px;

  &.small-image {
    margin-bottom: 94px;
  }
  .text-block {
    padding-top: 14px;
    border-top: solid 1px ${colors.black};
    margin-top: 37px;
    .arrow {
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
    @media (max-width: 768px) {
      margin: 37px 25px 0;
    }
  }

  @media (max-width: 1440px) {
    margin-bottom: 89px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 67px;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
    img {
      &.centered {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }
`;

const TitleBlock = styled.div`
  margin-bottom: 65px;
  max-width: 66.66%;

  p,
  h1 {
    display: block;
  }
  p.type {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 22px;
    font-family: ${typography.mono};
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 38px;
  }
  @media (min-width: 1025px) {
    margin-left: 8.33333%;
  }
  @media (max-width: 1024px) {
    max-width: 720px;
    width: 100%;
    p.type {
      letter-spacing: 0.05em;
      font-size: 14px;
      line-height: 22px;
    }
    .title {
      margin-bottom: 23px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 39px;
    text-align: left;
  }
`;
