function xmlToJson(xml) {
  const parseElement = (elem) => {
    // Object to store the element's data
    let obj = {};

    // Process each child of the element
    elem.childNodes.forEach((child) => {
      if (child.nodeType === 1) {
        // Element
        const childObj = parseElement(child);
        const childName = child.nodeName;

        // Handle multiple children with the same name
        if (obj[childName]) {
          if (!Array.isArray(obj[childName])) {
            obj[childName] = [obj[childName]];
          }
          obj[childName].push(childObj);
        } else {
          obj[childName] = childObj;
        }
      } else if (child.nodeType === 3) {
        // Text
        const text = child.nodeValue.trim();
        if (text) obj["text"] = text; // Only add non-empty text nodes
      }
    });

    // Process each attribute of the element
    if (elem.attributes) {
      Array.from(elem.attributes).forEach((attr) => {
        const attrName = attr.nodeName;
        const attrValue = attr.nodeValue;
        obj[attrName] = attrValue;
      });
    }

    return obj;
  };

  // Initialize DOMParser and parse the XML
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const json = parseElement(xmlDoc.documentElement);

  return json;
}

export { xmlToJson };
