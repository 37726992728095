import React, { useRef, useEffect, useState } from "react";
import { checkIfIE } from "../const/helpers";

const IMGIX_URL = process.env.REACT_APP_IMGIX_URL;

const getUrl = (url) => {
  if (!IMGIX_URL) return url;
  return url.replace(process.env.REACT_APP_API_BASE_URL, IMGIX_URL);
};

const getSizeUrl = (url, size) => {
  let prefix = url.indexOf("?") >= 0 ? "&" : "?";
  return `${getUrl(url)}${prefix}w=${size} ${size}w`;
};

const getPreviewUrl = (url) => {
  let prefix = url.indexOf("?") >= 0 ? "&" : "?";
  return `${getUrl(url)}${prefix}w=150&blur=100`;
};

const Image = ({ src, sizeOverride, lazy, alt, ...props }) => {
  const imageRef = useRef(null);
  const [imageSize, setImageSize] = useState(null);
  useEffect(() => {
    if (imageRef.current && !imageSize) {
      setImageSize(imageRef.current.width);
    }
  }, [imageRef]);

  if (!src) return null;

  return (
    <img
      ref={imageRef}
      src={checkIfIE() ? getSizeUrl(src, 800) : getPreviewUrl(src)}
      loading={lazy || "lazy"}
      srcSet={[300, 500, 800, 1000, 1200, 1600]
        .map((i) => getSizeUrl(src, i))
        .join(", ")}
      sizes={`${sizeOverride || imageSize || 300}px`}
      alt={alt?.length > 0 ? alt : ""}
      {...props}
    />
  );
};

export default Image;
