import React from "react";
import styled from "styled-components";
import { Link as A } from "react-router-dom";
import moment from "moment";

import { typography } from "../../const";

import Image from "../Image";
import { Grid, Row, Column } from "../Grid";
import { ContentPadding } from "../Wrapper";
import { EntryTitle, EntryIngress } from "../../elements";

export const heroModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_HeroModulePosts {
		fieldGroupName
        image {
            mediaItemUrl
        }
		content{
			__typename
			... on Article{
				fields{
					description
					image{
						altText
						mediaItemUrl
					}
					metaInfo{
						publicationDate
					}
				}
				title
				link
			}
			... on NewsArticle{
				fields{
					description
					image{
						altText
						mediaItemUrl
					}
					metaInfo{
						publicationDate
					}
				}
				title
				link
			}
            ... on Page {
                pageFields {
                    excerpt
                }
                title
                link
            }
		}
	}
`;

const HeroModule = ({ data }) => {
  let { image, description, metaInfo } = data?.content?.fields || "";

  if (data?.content?.__typename === "Page") {
    image = data?.image;
    description = data?.content?.pageFields.excerpt;
  }

  const onHeroModuleType = (data) => {
    const { __typename } = data?.content;
    let type = "";
    switch (__typename) {
      case "NewsArticle":
        type = "Nyheter";
        break;
      case "Article":
        type = "Artikkel";
        break;
      default:
        type = "Nyheter";
    }
    return type;
  };

  return (
    <Wrapper>
      <Grid>
        <Row>
          <EntryModuleColumn noTabletPadding span={12} tabletSpan={12}>
            <WrappingLink to={data?.content?.link}>
              <div className="image-block">
                <Image alt={image?.altText} src={image?.mediaItemUrl} />
              </div>
              {data?.content?.title && (
                <ContentPadding>
                  <div className="text-block">
                    <p className="type">{onHeroModuleType(data)}</p>
                    <EntryTitle className="hero">
                      {data?.content?.title}
                    </EntryTitle>
                    <EntryIngress
                      className={`ingress ${data?.content?.__typename} hero`}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    {metaInfo && (
                      <p className="publication-date">
                        Publisert{" "}
                        <span className="bold">
                          {moment(
                            metaInfo.publicationDate,
                            "D/M/YYYY hh:mm a"
                          ).format("D/M/YYYY")}
                        </span>
                      </p>
                    )}
                  </div>
                </ContentPadding>
              )}
            </WrappingLink>
          </EntryModuleColumn>
        </Row>
      </Grid>
    </Wrapper>
  );
};

export default HeroModule;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const WrappingLink = styled(A)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  align-items: center;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const EntryModuleColumn = styled(Column)`
  .image-block {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .text-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 22px;
    align-items: center;
    padding-top: 14px;
    text-align: center;
    max-width: 790px;

    /* IE 11 */
    > * {
      display: inline-table;
      margin: 0;
    }
    .ingress {
      margin-top: 34px;
    }
    p.type {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 22px;
      font-family: ${typography.mono};
      margin-bottom: 20px;
    }
    p.publication-date {
      font-family: ${typography.mono};
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      margin-top: 39px;
      span.bold {
        font-family: ${typography.monoBold};
      }
    }
  }
  img {
    width: 100%;
    height: 100%; //safari
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    .text-block {
      p.type {
        font-size: 14px;
      }
      .ingress {
        display: none;
      }
    }
    margin-top: 67px;
  }
`;
