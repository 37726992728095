import React, { useEffect } from "react";
import useRequest from "kb-gql";
import styled from "styled-components";

import { dispatch } from "../../redux";
import { modulesQuery } from "../../const/modulesQuery";
import { colors } from "../../const";

import Builder from "../../components/modules/Builder";
import { ContentPadding } from "../../components/Wrapper";
import { PageTitle, PageIngress } from "../../elements";

const indexFieldsQuery = `
	socialDutiesPage {
		pageFields{
			excerpt
			title
		}
		${modulesQuery("SocialDutiesPage")}
	}
`;

const SocialDutiesIndex = (params) => {
  useEffect(() => {
    dispatch("colorScheme/primary");
  }, []);
  const data = useRequest(indexFieldsQuery, "socialDutiesIndex");
  if (!data) return null;
  return (
    <SocialDutiesWrapper>
      <ContentPadding>
        <PageHeader>
          <PageTitle>{data?.pageFields?.title}</PageTitle>
          {data?.pageFields?.excerpt && (
            <PageIngress>{data.pageFields.excerpt}</PageIngress>
          )}
        </PageHeader>
      </ContentPadding>
      <Builder modules={data.modules?.builder} pageType="SocialDutiesPage" />
    </SocialDutiesWrapper>
  );
};

export default SocialDutiesIndex;

const SocialDutiesWrapper = styled.div``;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-bottom: solid 1px ${colors.grey};
  ${PageTitle} {
    padding-bottom: 48px;
  }
  ${PageIngress} {
    padding-bottom: 43px;
    max-width: 855px;
    text-align: center;
  }
`;
