import React from "react";
import styled from "styled-components";
import { colors } from "../../../const";

const alphabet = [
  "Ingen filtrering",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "Æ",
  "Ø",
  "Å",
];

const LetterFiltering = ({ letterFilter, setLetterFilter }) => {
  return (
    <FilterWrapper>
      {alphabet.map((letter, i) => {
        const filterBy = letter === "Ingen filtrering" ? "" : letter;
        const handleClick = () => {
          setLetterFilter(filterBy);
        };
        return (
          <FilterButton
            key={i}
            className={letterFilter === filterBy ? "active" : ""}
            onClick={() => handleClick()}
          >
            {letter}
          </FilterButton>
        );
      })}
    </FilterWrapper>
  );
};

export default LetterFiltering;

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: solid 1px ${colors.grey};
  border-bottom: solid 1px ${colors.grey};
  padding: 12px 0 16px 0;
  @media (max-width: 768px) {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;

const FilterButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  margin: 0 0.2em;
  padding: 0 0.3em;
  &:first-child {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
  }
  &.active {
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
  }
  &:hover {
    text-decoration: underline;
  }
`;
