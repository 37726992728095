import React, { Component } from "react";
import styled, { css } from "styled-components";
import Image from "../Image";
import Swiper from "swiper";
import { dispatch } from "../../redux";
import OpenFullscreen from "../partials/OpenFullscreen";
import Arrow from "../../assets/icons/Arrow";
import { colors, typography } from "../../const";
import { parseTitle, checkIfIE } from "../../const/helpers";

export const imageCarouselQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_ImageCarouselModule {
		fieldGroupName
		imageArray{
            title
			image{
				mediaItemUrl
				altText
			}
		}
	}
`;

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.swiperRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", this.closeOverlay, false);
    const { imageArray } = this.props;
    const options = {
      direction: "horizontal",
      slidesPerView: "auto",
      slidesPerGroup: 1,
      spaceBetween: 150,
      freeModeMomentum: true,
      freeModeMomentumRatio: 0.4,
      freeMode: true,
      centeredSlides: true,
      preventClicksPropagation: true,
      preventClicks: true,
      loop: true,
      breakpoints: {
        1025: {
          allowTouchMove: false,
          freeModeMomentum: false,
          freeMode: false,
          preventClicksPropagation: false,
          preventClicks: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        type: "custom",
        renderCustom: function (swiper, current, total) {
          return `${current}/${total}`;
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    if (this.swiperRef && this.swiperRef.current) {
      this.Swiper = new Swiper(this.swiperRef.current, options);
    }

    if (this.Swiper && imageArray.length > 0) {
      this.Swiper.activeIndex = imageArray.length;
      this.Swiper.pagination.update();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.closeOverlay, false);
    if (this.Swiper) {
      this.Swiper.destroy();
    }
  }

  closeOverlay(event) {
    if (event.keyCode === 27) dispatch("fullscreenImageOverlay/close");
  }

  render() {
    const { imageArray } = this.props;
    return (
      <ImageSlider isIE={checkIfIE()}>
        <ProgressBar>
          <div className="swiper-pagination"></div>
        </ProgressBar>
        <div ref={this.swiperRef} className="swiper-container container">
          <div className="swiper-wrapper">
            {imageArray?.map(({ image, title }, i) => {
              //restrict height of every other element
              const restricted = i % 2 === 1 ? " restricted-height " : " ";
              return (
                <div key={i} className={`swiper-slide ${restricted}`}>
                  <Image
                    src={image.mediaItemUrl}
                    alt={image.altText}
                    lazy="eager"
                    sizeOverride={900}
                  />
                  {title && (
                    <div className="text-block">
                      <Arrow className="arrow" />
                      <p
                        className="image-title"
                        dangerouslySetInnerHTML={{
                          __html: parseTitle(title),
                        }}
                      />
                    </div>
                  )}
                  <OpenFullscreen
                    showcaseItems={imageArray}
                    initialSlide={i}
                    className={restricted}
                    tabIndex="-1"
                  />
                </div>
              );
            })}
          </div>
          <div className="swiper-button-next">
            <Arrow />
          </div>
          <div className="swiper-button-prev">
            <Arrow />
          </div>
        </div>
      </ImageSlider>
    );
  }
}

export default ImageCarousel;

const ProgressBar = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
  font-family: ${typography.mono};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.024em;
  margin-bottom: 49px;
  @media (max-width: 1440px) {
    width: 100%;
  }
`;

const ImageSlider = styled.div`
  width: 100%;
  .swiper-container {
    overflow: visible;
    position: relative;
    overflow-x: hidden;

    @media (max-width: 1440px) {
      width: 100%;
    }

    .swiper-wrapper {
      display: flex;
      align-items: center;

      .swiper-slide {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;

        .text-block {
          display: inline-flex;
          margin-top: 37px;
          align-items: flex-start;
          width: fit-content;
          margin-left: 35px;
          .arrow {
            padding-top: 5px;
            margin-right: 9px;
          }
          p.image-title {
            font-family: ${typography.mono};
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.015em;
            span.bold-text {
              font-family: ${typography.monoBold};
            }
          }
        }

        &.restricted-height {
          img {
            height: 480px;
          }
        }
        img {
          height: 600px;
          object-fit: cover;
        }
        @media (max-width: 768px) {
          height: unset;
          .text-block {
            max-width: 90vw;
            align-self: center;
          }
          &.restricted-height {
            height: unset;
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      background-color: ${colors.white};
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.5);
      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
    .swiper-button-prev {
      left: 100px;
      @media (max-width: 1440px) {
        left: 5px;
      }
      @media (max-width: 1024px) {
        left: -30px;
      }
      svg {
        transform: rotate(180deg);
      }
    }
    .swiper-button-next {
      right: 100px;
      @media (max-width: 1440px) {
        right: 5px;
      }
      @media (max-width: 1024px) {
        right: -30px;
      }
      z-index: 2;
    }
  }
  @media (max-width: 1024px) {
    .swiper-container {
      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        right: 0;
      }
    }
  }

  ${(p) =>
    p.isIE &&
    css`
      display: none;
    `}
`;
