import React from "react";
import styled, { css } from "styled-components";
import Link from "../../../assets/icons/Link";
import Facebook from "../../../assets/icons/Facebook_New";
import Linkedin from "../../../assets/icons/Linkedin";
import Mail from "../../../assets/icons/Mail";

const shareOnFacebook = (url) => {
  if (!url) return null;
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, "_blank");
};

const ShareOnLinkedIn = (url) => {
  if (!url) return null;
  window.open(
    `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    "_blank"
  );
};

const Share = ({ data }) => {
  const { seo, fields } = data;
  return (
    <StyledContainer>
      <SocialBlock
        color="#e6fafc"
        onClick={() => {
          navigator.clipboard.writeText(seo?.opengraphUrl);
        }}
      >
        <Link height="18" width="18" color="#0b6876" />
      </SocialBlock>
      <SocialBlock
        color="#e6fafc"
        onClick={() => {
          shareOnFacebook(seo?.opengraphUrl);
        }}
      >
        <Facebook ariaHidden="true" height="18" width="18" color="#0b6876" />
      </SocialBlock>
      <SocialBlock
        color="#e6fafc"
        onClick={() => {
          ShareOnLinkedIn(seo?.opengraphUrl);
        }}
      >
        <Linkedin ariaHidden="true" height="18" width="18" color="#0b6876" />
      </SocialBlock>
      <SocialBlock
        color="#e6fafc"
        onClick={() =>
          (window.location.href = `mailto:?body=${seo?.opengraphUrl}`)
        }
      >
        <Mail ariaHidden="true" height="18" width="18" color="#0b6876" />
      </SocialBlock>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 768px) {
    padding-bottom: 37px;
  }
`;

const SocialBlock = styled.div`
  padding: 8px;
  margin: 5px;
  background-color: ${(props) => props.color};

  @media (max-width: 768px) {
    svg {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export default Share;
