import React from "react";
import styled from "styled-components";

import { typography } from "../../const";

import { ContentPadding } from "../Wrapper";

export const blockquoteQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_BlockquoteModule {
		fieldGroupName
		quote
		author
	}
`;

const Blockquote = ({ data, pageType, ...rest }) => {
  const { author, quote } = data;
  return (
    <Block>
      <ContentPadding>
        <Quote
          className={pageType}
          dangerouslySetInnerHTML={{ __html: quote }}
          {...rest}
        />
        <Author>{author}</Author>
      </ContentPadding>
    </Block>
  );
};

export default Blockquote;

const Block = styled.blockquote`
  all: unset;
  width: 100%;
  text-align: center;
  align-items: center;
  > p {
    max-width: 780px;
  }
`;

const Quote = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 50px;
  line-height: 60px;
  font-family: ${typography.canelaRegular};
  padding-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

const Author = styled.p`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.024em;
  font-family: ${typography.mono};
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
