import React from "react";
import styled from "styled-components";
import { typography } from "../../const";
import { parseTitle } from "../../const/helpers";
import Arrow from "../../assets/icons/Arrow";
import { ContentPadding } from "../Wrapper";

export const getVideoUrl = (url) => {
  if (!url) return null;
  const youtubeRegExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

  const match = url.match(youtubeRegExp) || url.match(vimeoRegExp) || null;

  if (!match) return null;

  if (match.input.includes("vimeo")) {
    const vimeoID = match[1] || "";
    return {
      className: "vimeo",
      url: `https://player.vimeo.com/video/${vimeoID}?dnt=1`,
    };
  } else if (match.input.includes("youtu")) {
    let startTime = null;
    if (typeof match[6] !== "undefined") {
      const params = new URLSearchParams(match[6]);
      if (params.has("t")) startTime = params.get("t");
    }

    const youtubeID = match[5] || "";

    return {
      className: "youtube",
      url: startTime
        ? `https://www.youtube-nocookie.com/embed/${youtubeID}?start=${startTime}`
        : `https://www.youtube-nocookie.com/embed/${youtubeID}`,
    };
  }
};

export const videoModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_VideoModule {
		fieldGroupName
		videoLink
		videoWidth
		videoFormat
		title
	}
`;

const Video = ({ data }) => {
  const { videoLink, title, videoFormat, videoWidth } = data;
  const parsedText = parseTitle(title);
  const videoInfo = getVideoUrl(videoLink);
  if (!videoInfo) return null;
  return (
    <VideoBlock className={`${videoWidth ? "full-width" : "half-width"}`}>
      <div className={`video-container ${videoInfo?.className} ${videoFormat}`}>
        <iframe src={videoInfo?.url} title={title} />
      </div>
      {parsedText && videoWidth ? (
        <ContentPadding>
          <div className="title-block">
            <Arrow className="arrow" />
            <p
              className="video-title"
              dangerouslySetInnerHTML={{ __html: parsedText }}
            />
          </div>
        </ContentPadding>
      ) : (
        <div className="title-block">
          <Arrow className="arrow" />
          <p
            className="video-title"
            dangerouslySetInnerHTML={{ __html: parsedText }}
          />
        </div>
      )}
    </VideoBlock>
  );
};

export default Video;

const VideoBlock = styled.div`
  display: flex;
  flex-direction: column;
  &.half-width {
    align-items: center;
    justify-content: center;
    .video-container {
      width: 50%;
      &.youtube {
        padding-bottom: calc(56.25% / 2);
      }
      &.vimeo {
        padding-bottom: calc(50% / 2);
      }
      &.sixteen_nine {
        padding-bottom: calc(56.25% / 2);
      }
      &.four_three {
        padding-bottom: calc(75% / 2);
      }
      &.four_five {
        padding-bottom: calc(125% / 2);
      }
      &.one_one {
        padding-bottom: calc(100% / 2);
      }
      &.nine_sixteen {
        padding-bottom: calc(177.5% / 2);
      }
    }
    .title-block {
      width: 50%;
    }
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    &.youtube {
      padding-bottom: 56.25%;
    }
    &.vimeo {
      padding-bottom: 50%;
    }
    &.sixteen_nine {
      padding-bottom: 56.25%;
    }
    &.four_three {
      padding-bottom: 75%;
    }
    &.four_five {
      padding-bottom: 125%;
    }
    &.one_one {
      padding-bottom: 100%;
    }
    &.nine_sixteen {
      padding-bottom: 177.5%;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .title-block {
    display: flex;
    margin-top: 22px;
    align-items: flex-start;
    p.video-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
  }
`;
