import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link as A } from "react-router-dom";

import { typography, colors } from "../../const";
import { checkIfIE } from "../../const/helpers";

import Image from "../Image";
import { EntryTitle, EntryIngress, Type } from "../../elements";
import { Grid } from "../Grid";

export const entryModuleNewsQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_EntryModuleNews {
		fieldGroupName
        startDate
        endDate
          blocks {
			imageAlignment
			showImage
            content {
				__typename
				... on NewsArticle {
  				fields {
  				  description
  				  image {
  					altText
  					mediaItemUrl
  				  }
  				  metaInfo {
  					publicationDate
  				  }
  				}
  				title
  			  link
  			  }
            }
          }
	}
`;

const moduleType = (length) => {
  switch (length) {
    case 1:
      return { mode: "singleModule", span: 12 };
    case 2:
      return { mode: "doubleModule", span: 6 };
    case 3:
      return { mode: "tripleModule", span: 4 };
    default:
      return { mode: "singleModule", span: 12 };
  }
};

const moduleHasExpired = (endDate) => {
  let hasExpired = false;
  if (!endDate) return false;
  let momentTodaysDate = new moment().format("YYYY-MM-DD h:mm:ss");
  let momentEndDate = new moment(endDate, "DD/MM/YYYY h:mm:ss").format(
    "YYYY-MM-DD h:mm:ss"
  );
  let differncial = moment(momentEndDate).diff(momentTodaysDate, "seconds");
  if (differncial <= 0) hasExpired = true;
  return hasExpired;
};

const moduleHasStarted = (startDate) => {
  let hasStarted = false;
  if (!startDate) return true;
  let momentTodaysDate = new moment().format("YYYY-MM-DD h:mm:ss");
  let momentStartDate = new moment(startDate, "DD/MM/YYYY h:mm:ss").format(
    "YYYY-MM-DD h:mm:ss"
  );
  let differncial = moment(momentTodaysDate).diff(momentStartDate, "seconds");
  if (differncial >= 0) hasStarted = true;
  console.log(differncial);
  return hasStarted;
};

const EntryModuleNews = ({ data }) => {
  if (!data) return null;
  if (moduleHasExpired(data?.endDate) || !moduleHasStarted(data?.startDate))
    return null;
  const filteredBlocks = data?.blocks.filter(({ content }) => {
    const pubDate = moment(
      content?.fields?.metaInfo?.publicationDate,
      "D/M/YYYY h:mm a"
    );
    return moment().isSameOrAfter(pubDate, "minute");
  });
  const moduleSpan = moduleType(filteredBlocks.length);
  return (
    <Grid
      setLaptopGutterWidth={40}
      tabletColSpan={12}
      setGutterWidth={50}
      colSpan={moduleSpan?.span}
    >
      <StyledDiv>
        {filteredBlocks.map(({ content, imageAlignment, showImage }, i) => {
          const { image, description, metaInfo } = content?.fields;
          const pubDate = moment(
            metaInfo.publicationDate,
            "D/M/YYYY h:mm a"
          ).format("D/M/YYYY");
          return (
            <StyledA
              key={content?.link + "-" + i}
              imageAlignment={imageAlignment}
              to={content?.link}
              className={`${showImage ? "" : "no-image"} ${moduleSpan?.mode}`}
              isIE={checkIfIE()}
            >
              <div
                className={`image-block ${
                  data.blocks.length > 1 ? "side-by-side" : ""
                } ${moduleSpan?.mode}`}
              >
                <Image
                  sizeOverride={900}
                  alt={image?.altText}
                  src={image?.mediaItemUrl}
                />
              </div>
              {content?.title && (
                <div className="text-block">
                  <Type>Nyheter</Type>
                  <EntryTitle
                    className={`${moduleSpan?.mode} ${content?.__typename}`}
                  >
                    {content?.title}
                  </EntryTitle>
                  <EntryIngress
                    className={`ingress ${content?.__typename} ${moduleSpan?.mode}`}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                  {pubDate && (
                    <p className="publication-date">
                      Publisert
                      <span className="bold">{pubDate}</span>
                    </p>
                  )}
                </div>
              )}
            </StyledA>
          );
        })}
      </StyledDiv>
    </Grid>
  );
};

export default EntryModuleNews;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;

const StyledA = styled(A)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;

  p,
  h3 {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 0.78;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  .text-block {
    p.type {
      margin-bottom: 20px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 30px;
    .ingress {
      margin-top: 34px;
    }
    p.publication-date {
      font-family: ${typography.mono};
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      margin-top: 39px;
      span.bold {
        margin-left: 0.5em;
        font-family: ${typography.monoBold};
      }
    }
  }

  &.singleModule {
    display: flex;
    width: 100%;
    .image-block {
      display: flex;
      width: 50%;
      order: ${({ imageAlignment }) => (imageAlignment ? 1 : 0)};
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
      }
    }
    .text-block {
      order: ${({ imageAlignment }) => (imageAlignment ? 0 : 1)};
      margin-top: 0;
      padding-top: 0;
      background-color: ${colors.secondary};
      justify-content: center;
      width: 50%;
      padding: 22px 60px 90px;
    }

    @media (max-width: 768px) {
      display: block;
      flex-direction: column;
      .image-block {
        width: 100%;
      }
      .text-block {
        display: block;
        padding: 25px;
        width: calc(100% - 50px);
      }
    }
  }

  @media (max-width: 1024px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 119px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.tripleModule {
      .text-block {
        padding: 60px 32px 65px 39px;
        flex: 1;
        margin-top: 0;
        justify-content: space-between;
        @media (max-width: 768px) {
          padding: 25px;
        }
      }
    }
  }

  &.no-image {
    align-items: center;
    justify-content: center;
    background-color: ${colors.secondary};
    .image-block {
      display: none;
    }
    .text-block {
      padding: 60px 32px 65px 39px;
      flex: 1;
      margin-top: 0;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 60px 25px 65px;
      }
    }
  }

  @media (max-width: 768px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 59px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 1024px) {
    width: calc(33% - 31px);
  }
`;
