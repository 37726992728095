import { RequestProvider } from "kb-gql";
import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { StyleSheetManager } from "styled-components";
import Router from "./Router";
import { store } from "./redux";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  return (
    <Provider store={store}>
      <RequestProvider graphqlUrl={process.env.REACT_APP_API_URL}>
        <StyleSheetManager shouldForwardProp={false}>
          <Router />
        </StyleSheetManager>
      </RequestProvider>
    </Provider>
  );
}

export default App;
