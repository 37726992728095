import React from "react";
import styled from "styled-components";
import { typography } from "../../../const";
import { parseTitle } from "../../../const/helpers";
import Arrow from "../../../assets/icons/Arrow";
import { getVideoUrl } from "../VideoModule";

const Video = ({ video }) => {
  const { videoLink, title, videoFormat } = video;
  const parsedText = parseTitle(title);
  const videoInfo = getVideoUrl(videoLink);
  if (!video || !videoInfo) return null;
  return (
    <VideoBlock>
      <div className={`video-container ${videoInfo?.className} ${videoFormat}`}>
        <iframe sandbox="allow-scripts" src={videoInfo?.url} title={title} />
      </div>
      {parsedText && (
        <div className="title-block">
          <Arrow className="arrow" />
          <p
            className="video-title"
            dangerouslySetInnerHTML={{ __html: parsedText }}
          />
        </div>
      )}
    </VideoBlock>
  );
};

export default Video;

const VideoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    &.youtube {
      padding-bottom: 56.25%;
    }
    &.vimeo {
      padding-bottom: 50%;
    }
    &.sixteen_nine {
      padding-bottom: 56.25%;
    }
    &.four_three {
      padding-bottom: 75%;
    }
    &.four_five {
      padding-bottom: 125%;
    }
    &.one_one {
      padding-bottom: 100%;
    }
    &.nine_sixteen {
      padding-bottom: 177.5%;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .title-block {
    display: flex;
    margin-top: 22px;
    align-items: flex-end;
    p.video-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
    .arrow {
      padding-bottom: 7px;
      margin-right: 9px;
    }
  }
`;
