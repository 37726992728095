import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../const";
import {
  AccordionWrapper,
  TitleButton,
  ContentRow,
} from "../../../components/modules/accordionPartials/AccordionContent";

const FilteringDropdown = ({ terms, setSelectedTerms }) => {
  const [isOpen, setOpenState] = useState(false);
  return (
    <FilterWrapper>
      <TitleButton
        onClick={() => setOpenState(!isOpen)}
        className={!isOpen && "last-child"}
      >
        {isOpen ? "-" : "+"} Velg dokumenttype
      </TitleButton>
      <FilterContentRow open={isOpen} className="last-child">
        {terms.map(({ name }, i) => {
          const term = name;
          return (
            <CheckBox
              setSelectedTerms={setSelectedTerms}
              key={i}
              title={term}
            />
          );
        })}
      </FilterContentRow>
    </FilterWrapper>
  );
};

export default FilteringDropdown;

const CheckBox = ({ title, setSelectedTerms }) => {
  const [checked, setCheckedState] = useState(false);
  const handleChange = (target) => {
    setCheckedState(!checked);
    setSelectedTerms(title);
  };

  return (
    <CheckBoxWrap checked={checked} onClick={() => handleChange()}>
      <HiddenCheckbox checked={checked} onChange={() => handleChange()} />
      <FilterOption checked={checked}>
        <Checkmark viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Checkmark>
      </FilterOption>
      <p>{title}</p>
    </CheckBoxWrap>
  );
};

const FilterWrapper = styled(AccordionWrapper)`
  margin-bottom: 60px;
`;

const CheckBoxWrap = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 1em 0 1em 0.5em;
    font-size: 17px;
    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const FilterOption = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked }) => (checked ? colors?.primary : "white")};
  border: solid 1px ${colors.primary};
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3 ${colors.primary};
  }
`;

const Checkmark = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  pointer-events: none;
`;

const FilterContentRow = styled(ContentRow)`
  margin-top: 0;
  &.last-child {
    padding-bottom: 30px;
  }
  ${CheckBoxWrap} {
    ${({ open }) => (!open ? `display: none;` : "")}
  }
`;
