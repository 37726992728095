import { accordionQuery } from "../components/modules/AccordionModule";
import { richTextQuery } from "../components/modules/RichtextModule";
import { imageFullWidthQuery } from "../components/modules/ImageFullWidthModule";
import { imageLinkQuery } from "../components/modules/ImageLinkModule";
import { imageCarouselQuery } from "../components/modules/ImageCarousel";
import { entryModuleQuery } from "../components/modules/EntryModule";
import { entryModuleArticleQuery } from "../components/modules/EntryModuleArticle";
import { entryModuleNewsQuery } from "../components/modules/EntryModuleNews";
import { entryModuleSocialDutyQuery } from "../components/modules/EntryModuleSocialDuty";
import { vacanciesModuleQuery } from "../components/modules/VacanciesModule";
import { displacedImageQuery } from "../components/modules/DisplacedImageModule";
import { heroModuleQuery } from "../components/modules/HeroModule";
import { blockquoteQuery } from "../components/modules/BlockquoteModule";
import { richTextWithFactboxQuery } from "../components/modules/RichtextWithFactboxModule";
import { newsletterModuleQuery } from "../components/modules/NewsletterModule";
import { alternatingContentQuery } from "../components/modules/AlternatingContentModule";
import { videoModuleQuery } from "../components/modules/VideoModule";
import { contactModuleQuery } from "../components/modules/ContactModule";
import { anchorModuleQuery } from "../components/modules/AnchorModule";

export const modulesQuery = (pageType) => `
	modules{
		builder{
			${accordionQuery(pageType)}
			${richTextQuery(pageType)}
			${imageFullWidthQuery(pageType)}
			${imageLinkQuery(pageType)}
			${imageCarouselQuery(pageType)}
			${entryModuleQuery(pageType)}
			${vacanciesModuleQuery(pageType)}
			${displacedImageQuery(pageType)}
			${entryModuleArticleQuery(pageType)}
			${entryModuleNewsQuery(pageType)}
			${entryModuleSocialDutyQuery(pageType)}
			${heroModuleQuery(pageType)}
			${blockquoteQuery(pageType)}
			${richTextWithFactboxQuery(pageType)}
			${newsletterModuleQuery(pageType)}
			${alternatingContentQuery(pageType)}
			${videoModuleQuery(pageType)}
			${contactModuleQuery(pageType)}
			${anchorModuleQuery(pageType)}
		}
	}
`;
