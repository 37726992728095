import React from "react";

const Linkedin = ({ height, width, color, ariaHidden }) => {
  return (
    <svg
      data-name="Group 3937"
      width={width || "26.516"}
      height={height || "26.434"}
      viewBox="0 0 26.516 26.434"
      aria-hidden={ariaHidden}
    >
      <path
        data-name="Path 1598"
        d="M752.98-229.336h-5.963v-1.784c0-3.032.01-6.065,0-9.1-.009-1.947-1.586-2.913-3.215-1.856a17.731,17.731,0,0,0-2.807,2.543,1.641,1.641,0,0,0-.264,1.047c-.021,2.653-.011,5.306-.011,7.96v1.178h-5.9v-18.092H740.7v3.215c.7-.631,1.228-1.164,1.815-1.612,2.149-1.643,4.5-2.258,7.048-1.047a5.871,5.871,0,0,1,3.424,5.864C752.954-237.159,752.98-233.3,752.98-229.336Z"
        transform="translate(-726.474 255.77)"
        fill={color || "#fff"}
      />
      <path
        data-name="Path 1599"
        d="M694.783-228.972v-18.047h5.856v18.047Z"
        transform="translate(-694.737 255.376)"
        fill={color || "#fff"}
      />
      <path
        data-name="Path 1600"
        d="M697.427-287.334a2.924,2.924,0,0,1,2.931,2.837,2.921,2.921,0,0,1-2.916,2.955,2.922,2.922,0,0,1-2.88-2.889A2.923,2.923,0,0,1,697.427-287.334Z"
        transform="translate(-694.562 287.334)"
        fill={color || "#fff"}
      />
    </svg>
  );
};

export default Linkedin;
