import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../const";
import Image from "../Image";
import { parseTitle } from "../../const/helpers";
import Arrow from "../../assets/icons/Arrow";
import OpenFullscreen from "../partials/OpenFullscreen";

export const displacedImageQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_DisplacedImageModule {
		fieldGroupName
		image{
			mediaItemUrl
			altText
		}
		imageText
	}
`;

const DisplacedImageModule = ({ data }) => {
  const { image, imageText } = data;
  return (
    <ImageFullWidthContainer>
      <div className="image-block">
        <Image alt={image?.altText} src={image?.mediaItemUrl} />
        <OpenFullscreen showcaseItems={data} />
      </div>
      {imageText && (
        <div className="text-block">
          <Arrow className="arrow" />
          <p
            className="image-title"
            dangerouslySetInnerHTML={{
              __html: parseTitle(imageText),
            }}
          />
        </div>
      )}
    </ImageFullWidthContainer>
  );
};

export default DisplacedImageModule;

const ImageFullWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 0.005em;
  font-family: ${typography.regular};
  width: 100%;
  height: 100%;
  .image-block {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
  }
  .text-block {
    display: inline-flex;
    margin-top: 37px;
    align-items: flex-start;
    padding-top: 14px;
    padding-right: 20px;
    border-top: solid 1px ${colors.black};
    width: fit-content;
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }

    @media (max-width: 768px) {
      margin: 37px 25px 0;
    }
  }
  img {
    width: 100%;
  }
`;
