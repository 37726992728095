import React from "react";

const Facebook = ({ height, width, color, ariaHidden }) => {
  return (
    <svg
      width={width || "26.515"}
      height={height || "26.5"}
      viewBox="0 0 94.12 74.81"
      aria-hidden={ariaHidden}
    >
      <path
        className="cls-1"
        d="M60.2.22c1.81.12,3.62.22,5.42.36.21,0,.58.29.58.44,0,3.74,0,7.48,0,11.36-.37,0-.68,0-1,0-2.57,0-5.14,0-7.7.16-3,.2-4.77,2.15-4.81,5.18s0,6.34-.1,9.51c0,.8.27,1,1,1,3.46,0,6.93,0,10.39,0H65a2,2,0,0,1,.06.34c-.55,4.24-1.1,8.47-1.65,12.77H52.71c0,.49-.07.91-.07,1.32v31c0,1.5,0,1.2-1.24,1.21-3.76,0-7.52,0-11.28,0h-1c0-.43-.06-.79-.06-1.16q0-15.57,0-31.15c0-1.19,0-1.19-1.18-1.19H28c0-.38-.05-.67-.05-1,0-3.74,0-7.48,0-11.22,0-.73.17-1,.94-1,3.08,0,6.17,0,9.25,0,.77,0,1-.21,1-1,0-3.85-.15-7.71.08-11.54.36-6,2.75-10.82,8.29-13.68A16.87,16.87,0,0,1,53.92.22C54.24.2,59.5.17,60.2.22Z"
        fill={color || "#fff"}
      />
    </svg>
  );
};

export default Facebook;
