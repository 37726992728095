import React from "react";
import styled, { css } from "styled-components";
import { Link as A } from "react-router-dom";

import { colors } from "../../const";
import { checkIfIE } from "../../const/helpers";

import Image from "../Image";
import { EntryTitle, EntryIngress, Type } from "../../elements";
import { Grid } from "../Grid";
import { ContentPadding } from "../Wrapper";

export const entryModuleSocialDutyQuery = (pageType) => `
... on ${pageType}_Modules_Builder_EntryModuleSocialDuties {
          fieldGroupName
		  showTag
          blocks {
            content {
              ... on SocialDuty {
                fields {
                  image {
                    altText
                    mediaItemUrl
                  }
				  description
                }
                title
                link
              }
            }
			customImage{
				altText
				mediaItemUrl
			}
            imageAlignment
            showImage
          }
        }
`;

const moduleType = (length) => {
  switch (length) {
    case 1:
      return { mode: "singleModule", span: 12 };
    case 2:
      return { mode: "doubleModule", span: 6 };
    case 3:
      return { mode: "tripleModule", span: 4 };
    default:
      return { mode: "singleModule", span: 12 };
  }
};

const EntryModuleSocialDuty = ({ data }) => {
  if (!data) return null;
  const moduleSpan = moduleType(data.blocks.length);
  return (
    <ContentPadding>
      <Grid
        setLaptopGutterWidth={40}
        tabletColSpan={12}
        setGutterWidth={50}
        colSpan={moduleSpan?.span}
      >
        <StyledDiv>
          {data?.blocks.map(
            ({ content, imageAlignment, showImage, customImage }, i) => {
              const { image, description, metaInfo } = content?.fields;
              return (
                <StyledA
                  key={content?.link + "-" + i}
                  imageAlignment={imageAlignment}
                  to={content?.link}
                  className={`SocialDuty ${showImage ? "" : "no-image"} ${
                    moduleSpan?.mode
                  }`}
                  isIE={checkIfIE()}
                >
                  {data?.showTag && (
                    <Type className="type">Samfunnsansvar</Type>
                  )}
                  <div
                    className={`image-block ${
                      data.blocks.length > 1 ? "side-by-side" : ""
                    }`}
                  >
                    <Image
                      sizeOverride={900}
                      alt=""
                      src={
                        customImage
                          ? customImage?.mediaItemUrl
                          : image?.mediaItemUrl
                      }
                    />
                  </div>
                  {content?.title && (
                    <div className="text-block">
                      <EntryTitle
                        className={`${moduleSpan?.mode} ${content?.__typename}`}
                      >
                        {content?.title}
                      </EntryTitle>
                      {description && (
                        <EntryIngress
                          className={`ingress ${content?.__typename} ${moduleSpan?.mode}`}
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      )}
                      {metaInfo?.publicationDate && (
                        <p className="publication-date">
                          Publisert
                          <span className="bold">
                            {metaInfo.publicationDate}
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                </StyledA>
              );
            }
          )}
        </StyledDiv>
      </Grid>
    </ContentPadding>
  );
};

export default EntryModuleSocialDuty;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  p,
  h3 {
    display: block;
    text-align: center;
  }

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;

const StyledA = styled(A)`
  display: block;
  text-decoration: none;
  width: 100%;

  &.singleModule {
    .type {
      display: none;
    }
    .image-block {
      width: 50%;
      order: ${({ imageAlignment }) => (imageAlignment ? 1 : 0)};
    }
    .text-block {
      order: ${({ imageAlignment }) => (imageAlignment ? 0 : 1)};
      margin-top: 0;
      padding-top: 0;
      background-color: ${colors.secondary};
      justify-content: center;
      width: 50%;
      padding: 137px 63px 135px;
    }
  }
  &.doubleModule {
    @media (min-width: 1024px) {
      width: calc(50% - 31px);
    }
  }

  padding: 55px 36px 61px 36px;
  background-color: ${colors.secondary};
  .image-block {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      object-fit: contain !important;
      height: 100%;

      @media (min-width: 1024px) {
        margin: 72px 0 64px;
      }
    }
    @media (min-width: 1024px) {
      &.side-by-side {
        width: 100%;
        height: 180px;
        img {
          margin: 0;
        }
      }
    }
  }
  &.no-image {
    align-items: center;
    justify-content: center;
    background-color: ${colors.secondary};
    .image-block {
      display: none;
    }
  }

  ${(p) =>
    p.isIE &&
    css`
      display: inline-block;
      > * {
        margin: 0 auto;
      }
    `}

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1024px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 119px;
    }
  }
  @media (max-width: 768px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 59px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    width: calc(33% - 31px);
  }
`;
