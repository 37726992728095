import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import useRequest from "kb-gql";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { dispatch } from "../../redux";
import { typography, colors } from "../../const";
import { modulesQuery } from "../../const/modulesQuery";
import { seoQuery } from "../../hooks/useSeo";
import { checkIfIE, parseTitle } from "../../const/helpers";
import Image from "../../components/Image";
import { FeaturedArticleTitle, ArticleIngress } from "../../elements";
import RichTextComponent from "../../components/RichTextComponent";
import { Grid, Row, Column, gutterWidth } from "../../components/Grid";
import NewsletterModule from "../partials/components/Newsletter";
import AuthorList from "../../components/partials/AuthorList";
import ReadMore from "../partials/components/ReadMore";
import Builder from "../../components/modules/Builder";
import Seo from "../partials/Seo";
import ErrorPage from "./ErrorPage";
import Share from "../partials/components/Share";
import Arrow from "../../assets/icons/Arrow";
import { ContentPadding } from "../../components/Wrapper";

const queryFields = `
    title
    metaTags {
        edges {
            node {
                name
                newsArticles {
                    nodes {
                        __typename
                        title
                        slug
                        link
                        fields{
                            image{
                                mediaItemUrl
                                altText
                            }
                            metaInfo{
                                publicationDate
                            }
                        }
                    }
                }
            }
        }
    }
    fields{
        title
        bodyText
        ingress
        metaInfo{
            publicationDate
            photosBy
            writtenBy
        }
        image{
            mediaItemUrl
            altText
            description
        }
        newsletterModule {
          text
          title
        }
        authors{
            ... on Author{
                title
                fields {
                    email
                    fieldGroupName
                    jobtitle
                    phoneNumber
                    profilePicture {
                        altText
                        mediaItemUrl
                    }
                }
            }
        }
    }
    ${modulesQuery("Article")}
`;

const makeRequest = (slug) => `
    article(id: "${slug}", idType: URI) {
        ${seoQuery}
        ${queryFields}
    }
`;

const previewQuery = (slug, database_id) => `
    query PreviewQuery{
        article(id: "${slug === "preview" ? database_id : slug}", idType: ${
  /*
   *  check if the slug equals preview, at the time of writing this
   *  any post that is either unsaved or saved as a draft will have the
   *  slug: preview
   */
  slug === "preview" ? "DATABASE_ID" : "URI"
} ) { ${
  /*
   * If the slug does not equals preview we have to query for the "preview"
   * fields in graphql, this is because wp-grapqhl doesn't actually think of it
   * as a preview until it is saved as post and a revision is requested.
   */
  slug !== "preview"
    ? `
        preview{
            node{
                ${queryFields}
            }
        }
        `
    : // If we are querying for a revision we query for the regular fields
      `
            ${queryFields}
        `
}
        }
    }
`;

async function getPreviewData(searchParams, slug) {
  const preview = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${searchParams.jwtToken}`,
    },
    body: JSON.stringify({
      query: previewQuery(slug, searchParams.database_id),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data)
    .catch((error) =>
      console.error("Error fetching previewData for FeatureArticle: ", error)
    );
  /*
   * Here we do an if check on existing object properties to determine
   * which data to set in the previewData state
   */
  if (preview?.article?.preview?.node) {
    return preview?.article?.preview?.node;
  } else {
    return preview?.article;
  }
}
/*
 * The page can have a newsletter from the module builder
 * so if it already has that, then don't render the static newsletter
 */
const checkForNewsLetterModule = (modules) => {
  let key = "fieldGroupName";
  let value = "Article_Modules_Builder_NewsletterModule";

  return modules.some(function (o) {
    return o[key] === value;
  });
};

const FeatureArticle = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const searchParams = {
    jwtToken: state?.jwtToken,
    database_id: state?.database_id
  };
  const data = useRequest(makeRequest(slug), `article-${slug}`);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    dispatch("colorScheme/primary");
    getPreviewData(searchParams, slug).then((data) => setPreviewData(data));
  }, []);

  //check if data or previewdata doesn't exist before sending us to the errorpage
  if (data === null && !previewData) return <ErrorPage />;
  if (!data && !previewData) return null;

  const isIE = checkIfIE();
  const {
    title,
    ingress,
    image,
    newsletterModule,
    bodyText,
    smallImage,
    authors,
    metaInfo,
  } = previewData?.fields || data?.fields;

  const hasNewsLetterModule = checkForNewsLetterModule(
    previewData?.modules?.builder || data?.modules?.builder
  );
  // Since the static newsletter content is represented in an array, then set the first array item as content
  let newsletter = [];
  if (newsletterModule && newsletterModule.length > 0)
    newsletter = newsletterModule[0];

  return (
    <FeatureArticleWrapper isIE={isIE}>
      <Seo pageSeo={data?.seo} />
      <TitleBlock isIE={isIE}>
        <p className="type">Artikkel</p>
        <FeaturedArticleTitle className="title">
          {previewData?.title || data?.title}
        </FeaturedArticleTitle>
        <ArticleIngress
          className="ingress"
          dangerouslySetInnerHTML={{ __html: ingress }}
        />

        {metaInfo && (
          <MetaRow>
            {metaInfo.publicationDate && (
              <p>
                Publisert{" "}
                <span className="bold">
                  {moment(metaInfo.publicationDate, "D/M/YYYY hh:mm a").format(
                    "D.M.YYYY"
                  )}
                </span>
              </p>
            )}
            {metaInfo.writtenBy && !metaInfo.photoAndTextBy && (
              <p>
                Tekst <span className="bold">{metaInfo.writtenBy}</span>
              </p>
            )}
            {metaInfo.photosBy && !metaInfo.photoAndTextBy && (
              <p>
                Foto <span className="bold">{metaInfo.photosBy}</span>
              </p>
            )}
            {metaInfo.photoAndTextBy && (
              <p>
                Foto og tekst <span className="bold">{metaInfo.writtenBy}</span>
              </p>
            )}
          </MetaRow>
        )}
      </TitleBlock>
      {!smallImage && image && (
        <ImageWrapper>
          <Image src={image?.mediaItemUrl} alt={image?.altText} />
          {title && (
            <ContentPadding>
              <div className="text-block">
                <Arrow className="arrow" />
                <p
                  className="image-title"
                  dangerouslySetInnerHTML={{
                    __html: parseTitle(title),
                  }}
                />
              </div>
            </ContentPadding>
          )}
        </ImageWrapper>
      )}
      <Grid>
        <ContentPadding>
          <Row>
            <Column span={4}>
              {smallImage && image && (
                <ImageWrapper className="small-image">
                  <Image src={image?.mediaItemUrl} alt={image?.altText} />
                </ImageWrapper>
              )}
              <AuthorList title data={authors} />
              <Share author={authors} data={data || previewData} />
            </Column>
            <Column noTabletPadding span={8}>
              <RichTextComponent
                className="capitalize"
                pageType="NewsArticle"
                data={bodyText}
              />
            </Column>
          </Row>
        </ContentPadding>
        <Builder
          modules={previewData?.modules?.builder || data?.modules?.builder}
          pageType="Article"
        />
      </Grid>
      {!hasNewsLetterModule && <NewsletterModule data={newsletter} />}
      <ContentPadding>
        <ReadMore currentTitle={data?.title} data={data?.metaTags?.edges} />
      </ContentPadding>
    </FeatureArticleWrapper>
  );
};

export default FeatureArticle;

const FeatureArticleWrapper = styled.div`
  .capitalize p:first-child:first-letter {
    font-size: 105px;
    line-height: 93px;
    letter-spacing: 0.005em;
    font-family: ${typography.mono};
    font-weight: 500;
    float: left;
    padding-right: 7px;
  }
  ${(p) =>
    p.isIE &&
    css`
      display: block;
    `}
`;

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  margin-bottom: 160px;

  &.small-image {
    margin-bottom: 94px;
  }

  .text-block {
    display: inline-flex;
    margin-top: 37px;
    align-items: flex-start;
    padding-top: 14px;
    padding-right: 20px;
    border-top: solid 1px ${colors.black};
    width: fit-content;
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
  }

  @media (max-width: 1440px) {
    margin-bottom: 89px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 67px;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
    img {
      &.centered {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }
`;

export const TitleImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.isIE &&
    css`
      display: block;
    `}
  img {
    width: 100%;
    height: auto;
  }
  margin-bottom: 160px;

  &.small-image {
    margin-bottom: 94px;
  }
  &.ProjectOrProperty {
    margin-bottom: 94px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 89px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 67px;
    &.tablet-flow-to-edge {
      width: calc(100% + ${gutterWidth}px);
      left: -${gutterWidth / 2}px;
      right: -${gutterWidth / 2}px;

      ${(p) =>
        p.isIE &&
        css`
          height: 100%;
          > * {
            border: 1px solid red;
            margin: 0;
          }
        `}
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
    &.tablet-flow-to-edge {
      width: calc(100% + 50px);
      left: -25px;
      right: -25px;
    }
    img {
      &.centered {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }

  .text-block {
    display: inline-flex;
    margin-top: 37px;
    align-items: flex-start;
    padding-top: 14px;
    padding-right: 20px;
    border-top: solid 1px ${colors.black};
    width: fit-content;
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
  }
`;

const MetaRow = styled.div`
  font-family: ${typography.mono};
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 69px;
  display: inline-flex;
  flex-wrap: wrap;
  p {
    margin-right: 42px;
    &:last-child {
      margin-right: 0;
    }
  }
  span.bold {
    margin-left: 0.5em;
    font-family: ${typography.monoBold};
  }
  @media (max-width: 768px) {
    margin-top: 39px;
    justify-content: center;
    flex-direction: column;
    p {
      margin-right: 0;
    }
  }
`;

const TitleBlock = styled.div`
  margin-bottom: 65px;
  max-width: 66.66%;
  text-align: center;
  ${(p) =>
    p.isIE &&
    css`
      display: block;
      margin: 0 auto;
    `}
  p.type {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 22px;
    font-family: ${typography.mono};
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 38px;
  }
  h1,
  p {
    display: block;
    padding: 0 25px;
  }
  @media (max-width: 1024px) {
    max-width: 720px;
    width: 100%;
    align-self: center;
    text-align: center;
    p.type {
      letter-spacing: 0.05em;
      font-size: 14px;
      line-height: 22px;
    }
    .title {
      margin-bottom: 23px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 39px;
  }
`;
