const defaultState = { isOpen: false, imageArray: [], initialSlide: false };

export default function fullScreenImageOverlayReducer(
  state = defaultState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case "fullscreenImageOverlay/close":
      return { ...state, isOpen: false };
    case "fullscreenImageOverlay/open":
      return {
        ...state,
        isOpen: true,
        imageArray: payload.imageArray,
        initialSlide: payload.initialSlide,
      };
    case "fullscreenImageOverlay/clear":
    case "REDUX/CLEAR":
      return { ...defaultState };
    default:
      return state;
  }
}
