import React from "react";
import styled from "styled-components";
import Facebook from "../../../assets/icons/Facebook";
import Youtube from "../../../assets/icons/Youtube";
import Twitter from "../../../assets/icons/Twitter";
import Linkedin from "../../../assets/icons/Linkedin";
import { Column } from "../../../components/Grid";

const SocialMediaLinks = ({ links }) => {
  const {
    facebook,
    facebookIcon,
    linkedin,
    linkedinIcon,
    twitter,
    twitterIcon,
    youtube,
    youtubeIcon,
    instagram,
    instagramIcon,
  } = links;
  return (
    <Wrapper noTabletPadding tabletSpan={12} span={4}>
      <h5 className="row-title">SOSIALE MEDIER</h5>
      <Icons>
        {linkedin && (
          <a
            href={linkedin}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={"linkedin"}
          >
            {linkedinIcon ? (
              <img alt="" src={linkedinIcon.mediaItemUrl} aria-hidden />
            ) : (
              <Linkedin ariaHidden="true" />
            )}
          </a>
        )}
        {twitter && (
          <a
            href={twitter}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={"twitter"}
          >
            {twitterIcon ? (
              <img alt="" src={twitterIcon.mediaItemUrl} aria-hidden />
            ) : (
              <Twitter ariaHidden="true" />
            )}
          </a>
        )}
        {facebook && (
          <a
            href={facebook}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={"facebook"}
          >
            {facebookIcon ? (
              <img alt="" src={facebookIcon.mediaItemUrl} aria-hidden />
            ) : (
              <Facebook ariaHidden="true" />
            )}
          </a>
        )}
        {youtube && (
          <a
            href={youtube}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={"youtube"}
          >
            {youtubeIcon ? (
              <img alt="" src={youtubeIcon.mediaItemUrl} aria-hidden />
            ) : (
              <Youtube ariaHidden="true" />
            )}
          </a>
        )}
        {instagram && (
          <a
            href={instagram}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={"instagram"}
          >
            {instagramIcon && (
              <img alt="" src={instagramIcon.mediaItemUrl} aria-hidden />
            )}
          </a>
        )}
      </Icons>
    </Wrapper>
  );
};

export default SocialMediaLinks;

const Icons = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled(Column)`
  display: flex;
  a {
    margin-right: 32px;
    display: inline-flex;
    align-items: center;
    img,
    svg {
      width: 30px;
      height: 30px;
    }
    /* &:focus {
            outline: solid 3px rgb(255, 0, 0);
        } */
  }
`;
