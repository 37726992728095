import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const TemplateWrapper = styled.section`
  display: flex;
  flex-direction: column;
  /* @media (max-width: 768px) {
        padding: 0 25px;
    } */
`;

/* export const BuilderWrapper = styled.div`
    padding: 0 25px;

    @media (min-width: 768px) {
        padding: 0;
    }
`;

export const ModuleWrapper = styled.div`
    padding: 0 25px;

    @media (min-width: 768px) {
        padding: 0;
    }
`; */

export const ContentPadding = styled.div`
  padding: 0 25px;

  @media (min-width: 768px) {
    padding: 0;
  }
`;
