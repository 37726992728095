import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../../const";
import { Column } from "../../../components/Grid";

const InfoBlock = ({ info }) => {
  return info.map(({ title, text }, i) => {
    return (
      <Info noTabletPadding tabletSpan={6} span={4} key={i}>
        <h6 className="info-title">{title}</h6>
        <div
          className="info-wrapper"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Info>
    );
  });
};

export default InfoBlock;

const Info = styled(Column)`
  color: ${colors.white};
  .info-title {
    font-family: ${typography.bold};
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
  }
  .text {
    font-family: ${typography.regular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.024em;
  }
  @media (max-width: 1024px) {
    margin-bottom: 37px;
  }
`;
