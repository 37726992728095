import React from "react";

const Link = ({ height, width, color, ariaHidden }) => {
  return (
    <svg
      width={width || "26.515"}
      height={height || "26.5"}
      viewBox="0 0 94.12 74.81"
      aria-hidden={ariaHidden}
    >
      <path
        className="cls-1"
        d="M30.29,61.68a7.88,7.88,0,0,1-5,2.37A7.49,7.49,0,0,1,19,61.39,7.71,7.71,0,0,1,19.62,51L38.5,32.14a7.31,7.31,0,0,1,10.59.15l3.78,3.78,7.88-7.88-3.87-3.86a18.49,18.49,0,0,0-26.18,0L11.63,43.4a18.6,18.6,0,0,0,0,26.24c7.23,7.26,19.41,6.93,26.66-.32L42.63,65l-7.81-7.8Z"
        fill={color || "#fff"}
      />

      <path
        className="cls-1"
        d="M82.52,5.4c-7.23-7.26-19.41-6.93-26.66.32l-4.37,4.37,7.81,7.8,4.53-4.53a7.88,7.88,0,0,1,5-2.37,7.49,7.49,0,0,1,6.27,2.66A7.71,7.71,0,0,1,74.5,24L55.62,42.9A7.31,7.31,0,0,1,45,42.75L41.25,39l-7.88,7.88,3.87,3.86a18.49,18.49,0,0,0,26.18,0L82.49,31.64a18.6,18.6,0,0,0,0-26.24Z"
        fill={color || "#fff"}
      />
    </svg>
  );
};

export default Link;
