const defaultState = { primaryScheme: true };

export default function colorSchemeReducer(state = defaultState, action) {
  const { type } = action;

  switch (type) {
    case "colorScheme/toggle":
      return { ...state, primaryScheme: !state.primaryScheme };
    case "colorScheme/project":
      return { ...state, primaryScheme: false };
    case "colorScheme/primary":
      return { ...state, primaryScheme: true };
    case "colorScheme/clear":
    case "REDUX/CLEAR":
      return { ...defaultState };
    default:
      return state;
  }
}
