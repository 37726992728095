import React from "react";
import styled from "styled-components";
import { colors } from "../../../const";

const Hamburger = ({ open, onClick, className, ...rest }) => (
  <HamburgerButton
    className={className}
    onClick={onClick}
    open={open}
    {...rest}
    aria-label="hidden"
  >
    <span />
  </HamburgerButton>
);

export default Hamburger;

const HamburgerButton = styled.button`
  display: none;
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  span,
  &:before,
  &:after,
  span:after {
    content: "";
    width: 24px;
    height: 2px;
    background: ${colors.black};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-1px) translateX(-50%);
    display: block;
    transition: all 0.1s ease-in-out;
    transform-origin: 50% 50%;
  }
  span:after {
    opacity: 0;
  }
  &:before {
    transform: translateY(-11px) translateX(-50%);
  }
  &:after {
    transform: translateY(9px) translateX(-50%);
  }
  ${(props) =>
    props.open
      ? `
		margin-left: 0;
		&:before { opacity: 0; transform: translateY(-16px) translateX(-50%); }
		&:after { opacity: 0; transform: translateY(14px) translateX(-50%); }
		span {
			transform: translateY(-1px) translateX(-50%) rotate(45deg);
			&:after {
				transform: translateY(-1px) translateX(-50%) rotate(-90deg);
				opacity:1;
			}
		}
		`
      : ``}
`;
