import React from "react";
import styled, { css } from "styled-components";
import { typography, colors } from "../../../const";
import Image from "../../../components/Image";
import { Column } from "../../../components/Grid";
import { EntryTitle } from "../../../elements";
import { Link as A } from "react-router-dom";
import moment from "moment";
import { checkIfIE } from "../../../const/helpers";

const NewsAndArticleArchiveBlock = ({ data }) => {
  const { title, link, __typename } = data;
  const { image, metaInfo } = data?.fields;
  const type = __typename === "NewsArticle" ? "Nyheter" : "Artikkel";
  const isIE = checkIfIE();
  return (
    <StyledA to={link} isIE={isIE}>
      {isIE ? (
        <div className="image-wrap">
          <Image alt={image?.altText} src={image?.mediaItemUrl} />
        </div>
      ) : (
        <Image alt={image?.altText} src={image?.mediaItemUrl} />
      )}
      <div className="text-block">
        <p className="type">{type}</p>
        <EntryTitle className={`archive-title ${__typename}`}>
          {title}
        </EntryTitle>
        {metaInfo?.publicationDate && (
          <p className="publication-date">
            Publisert
            <span className="bold">
              {moment(metaInfo.publicationDate, "D/M/YYYY hh:mm a").format(
                "D/M/YYYY"
              )}
            </span>
          </p>
        )}
      </div>
    </StyledA>
  );
};

export default NewsAndArticleArchiveBlock;

const StyledA = styled(A)`
  margin-bottom: 78px;
  text-decoration: none;
  width: 100%;
  &:hover {
    color: ${colors.primary};
    transition: 0.2s color linear;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1 / 0.78;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  .text-block {
    width: 100%;
    margin-top: 22px;
    p,
    h3 {
      display: block;
    }
    p {
      &.type {
        font-family: ${typography.mono};
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      &.publication-date {
        font-family: ${typography.mono};
        font-size: 13px;
        line-height: 24px;
        text-transform: uppercase;
        margin-top: 29px;
        span.bold {
          margin-left: 0.5em;
          font-family: ${typography.monoBold};
        }
      }
    }
    h3 {
      font-family: ${typography.compressedBold};
      font-size: 30px;
      line-height: 38px;
      text-transform: uppercase;
    }
  }

  ${(p) =>
    p.isIE &&
    css`
      display: block;
      > * {
        display: block;
      }
      .image-wrap {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 254px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: auto;
        }
      }
      .text-block {
        > * {
          margin: 0;
        }
      }
    `}
  @media (min-width: 1024px) {
    width: calc(33% - 31px);
  }
`;
