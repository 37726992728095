export const parseTitle = (title, activeSlide, slideShowLength) => {
  if (!title) return "";
  const changingVal = /(\*.*?\*)/.exec(title);
  const strippedVal = changingVal && changingVal[0].replace(/\*/g, "");
  return title.replace(
    /(\*.*?\*)/,
    `<span class="bold-text">${maybeRenderActiveSlide(
      activeSlide,
      slideShowLength
    )}${strippedVal}</span>`
  );
};

const maybeRenderActiveSlide = (activeSlide, slideShowLength) => {
  if (!activeSlide || !slideShowLength) return "";
  return `(${activeSlide}/${slideShowLength})`;
};

export const checkIfIE = () => {
  return false || !!document.documentMode;
};

export const checkIfSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const byteSize = (str) => new Blob([str]).size;

export const byteSizeFromBase64size = (size) => (size * 3) / 4;
