import React from "react";
import styled from "styled-components";

import { colors } from "../../../const";
import Cross from "../../../assets/icons/Cross";

const AttachmentFileList = ({
  files = [],
  removeFileAtIndexCallback,
  ...props
}) => {
  return (
    <StyledAttachmentFileList>
      {files.map((file, index) => (
        <File key={index}>
          <span>{file.name}</span>
          <button
            onClick={(event) => removeFileAtIndexCallback(event, index)}
            aria-label={"Fjern vedlegg med navn " + file.name}
          >
            <Cross ariaHidden="true" />
          </button>
        </File>
      ))}
    </StyledAttachmentFileList>
  );
};

const StyledAttachmentFileList = styled.div`
  display: block;
  margin-right: -12px;
`;

const File = styled.div`
  display: inline-flex;
  margin-right: 11px;
  margin-bottom: 10px;
  padding: 8px 8px 4px 8px;
  border: solid 1px ${colors.grey};
  position: relative;

  * {
    border: none;
  }

  span {
    white-space: nowrap;
    max-width: 164px;
    text-overflow: ellipsis;
    line-height: 20px;
    overflow-x: hidden;
  }

  button {
    display: inline-flex;
    cursor: pointer;
    border: none !important;
    margin: 0;
    padding: 0;
    margin-left: 5px;

    &:hover {
      text-decoration: underline;

      &:after {
        content: "";
        position: absolute;
        left: 11px;
        right: 32px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        height: 2px;
        background: ${colors.black};
      }
    }

    @media (max-width: 1024px) {
      margin-top: 0;
    }

    svg {
      width: 16px;
      height: 20px;
    }
  }
`;

export default AttachmentFileList;
