import React from "react";

const Facebook = ({ ariaHidden }) => {
  return (
    <svg
      width="26.515"
      height="26.5"
      viewBox="0 0 26.515 26.5"
      aria-hidden={ariaHidden}
    >
      <path
        data-name="Path 1595"
        d="M1247.124-245.25v-9.031a1.5,1.5,0,0,1,.49-.069c1.6.343,2.381-.421,2.732-1.911a18.36,18.36,0,0,1,.836-2.222h-4.1c0-1.146-.01-2.2,0-3.259.009-.659.423-.893,1.023-.893h2.218v-4.124c-2.379.058-4.8-.474-6.757,1.467-1.922,1.911-1.351,4.349-1.485,6.754h-3.221v4.154h3.172v9.074c-.224.02-.453.057-.684.058-3,0-6,.013-9,0a3.305,3.305,0,0,1-3.492-3.507q-.015-9.721,0-19.442a3.316,3.316,0,0,1,3.566-3.537q9.675-.012,19.351,0a3.328,3.328,0,0,1,3.587,3.621q.009,9.618,0,19.235a3.345,3.345,0,0,1-3.681,3.633C1250.2-245.246,1248.722-245.25,1247.124-245.25Z"
        transform="translate(-1228.854 271.746)"
        fill="#fff"
      />
    </svg>
  );
};

export default Facebook;
