import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../const";
import Image from "../Image";
import { parseTitle } from "../../const/helpers";
import Arrow from "../../assets/icons/Arrow";
import { ContentPadding } from "../Wrapper";

export const imageLinkQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_ImageLinkModule {
		fieldGroupName
		image{
			mediaItemUrl
			altText
		}
		title
		link{
			url
		}
	}
`;

const ImageLinkModule = ({ data }) => {
  const { image, title, link } = data;

  return (
    <StyledA href={link?.url} target="_blank" rel="noreferrer nofollow">
      <div className="image-block">
        <Image alt={image?.altText} src={image?.mediaItemUrl} />
      </div>
      {title && (
        <ContentPadding>
          <div className="text-block">
            <Arrow className="arrow" />
            <p
              className="image-title"
              dangerouslySetInnerHTML={{
                __html: parseTitle(title),
              }}
            />
          </div>
        </ContentPadding>
      )}
    </StyledA>
  );
};

export default ImageLinkModule;

const StyledA = styled.a`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 0.005em;
  font-family: ${typography.regular};
  text-decoration: none;
  width: 100%;
  .image-block {
    display: flex;
    width: 100%;
    position: relative;
  }
  .text-block {
    display: inline-flex;
    margin-top: 37px;
    align-items: flex-start;
    padding-top: 14px;
    padding-right: 20px;
    border-top: solid 1px ${colors.black};
    width: fit-content;
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
  }
  img {
    width: 100%;
  }
`;
