import React from "react";

const Cross = ({ color, ariaHidden }) => {
  return (
    <svg width="19.414" height="19.414" viewBox="0 0 19.414 19.414">
      <g
        data-name="Group 3367"
        transform="translate(-146.787 -491.787)"
        aria-hidden={ariaHidden}
      >
        <line
          data-name="Line 787"
          x2="18"
          y2="18"
          transform="translate(147.494 492.494)"
          fill="none"
          stroke={color ? color : "#000"}
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          data-name="Line 788"
          x1="18"
          y2="18"
          transform="translate(147.494 492.494)"
          fill="none"
          stroke={color ? color : "#000"}
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default Cross;
