import { combineReducers } from "redux";
import request from "./reducers/requestReducer";
import response from "./reducers/responseReducer";
import searchOverlay from "./reducers/searchOverlayReducer";
import employeeForm from "./reducers/employeeFormReducer";
import colorScheme from "./reducers/colorSchemeReducer";
import fullscreenImageOverlay from "./reducers/fullscreenImageOverlayReducer";
import searchResult from "./reducers/searchResultReducer";
//REDUCER_IMPORTS

const reducers = combineReducers({
  request,
  response,
  searchOverlay,
  employeeForm,
  colorScheme,
  fullscreenImageOverlay,
  searchResult,
  //REDUCER_COMBINES
});

export default reducers;
