import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import useRequest from "kb-gql";
import { useLocation, useParams } from "react-router-dom";
import { dispatch } from "../../redux";
import { typography } from "../../const";
import { modulesQuery } from "../../const/modulesQuery";
import { checkIfIE } from "../../const/helpers";
import { seoQuery } from "../../hooks/useSeo";
import { Wrapper, ContentPadding } from "../../components/Wrapper";
import Image from "../../components/Image";
import Builder from "../../components/modules/Builder";
import RichTextComponent from "../../components/RichTextComponent";
import { Grid, Row, Column } from "../../components/Grid";
import AuthorList from "../../components/partials/AuthorList";
import { FeaturedArticleTitle, PageIngress } from "../../elements";
import Seo from "../partials/Seo";
import ErrorPage from "./ErrorPage";
import { TitleImageWrapper } from "./FeatureArticle";

const queryFields = `
	title
	fields{
		bodyText
		ingress
		image{
			mediaItemUrl
			altText
		}
		authors{
			... on Author{
				title
				fields {
					email
					fieldGroupName
					jobtitle
					phoneNumber
					profilePicture {
						altText
						mediaItemUrl
					}
				}
			}
		}
	}
	${seoQuery}
	${modulesQuery("SocialDuty")}

`;

const makeRequest = (slug) => `
    socialDuty(id: "${slug}", idType: URI) {
        ${seoQuery}
        ${queryFields}
    }
`;

const previewQuery = (slug, database_id) => `
    query PreviewQuery{
        socialDuty(id: "${slug === "preview" ? database_id : slug}", idType: ${
  /*
   *  check if the slug equals preview, at the time of writing this
   *  any post that is either unsaved or saved as a draft will have the
   *  slug: preview
   */
  slug === "preview" ? "DATABASE_ID" : "URI"
} ) { ${
  /*
   * If the slug does not equals preview we have to query for the "preview"
   * fields in graphql, this is because wp-grapqhl doesn't actually think of it
   * as a preview until it is saved as post and a revision is requested.
   */
  slug !== "preview"
    ? `
        preview{
            node{
                ${queryFields}
            }
        }
        `
    : // If we are querying for a revision we query for the regular fields
      `
            ${queryFields}
        `
}
        }
    }
`;

async function getPreviewData(searchParams, slug) {
  const preview = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${searchParams.jwtToken}`,
    },
    body: JSON.stringify({
      query: previewQuery(slug, searchParams.database_id),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  /*
   * Here we do an if check on existing object properties to determine
   * which data to set in the previewData state
   */
  if (preview?.socialDuty?.preview?.node) {
    return preview?.socialDuty?.preview?.node;
  } else {
    return preview?.socialDuty;
  }
}

const SocialDuty = () => {
  const { slug } = useParams();
  const data = useRequest(makeRequest(slug), `social-${slug}`);
  const { state } = useLocation();
  const searchParams = {
    jwtToken: state?.jwtToken,
    database_id: state?.database_id
  };
  const [previewData, setPreviewData] = useState(null);
  useEffect(() => {
    dispatch("colorScheme/primary");
    getPreviewData(searchParams, slug).then((data) => setPreviewData(data));
  }, []);
  if (data === null && !previewData) return <ErrorPage />;
  if (!data && !previewData) return null;
  if (data === undefined) return null;
  let isIE = checkIfIE();
  const { ingress, image, bodyText, smallImage, authors } =
    previewData?.fields || data.fields;
  return (
    <SocialDutyWrapper>
      <Seo pageSeo={data?.seo} />
      <StyledWrapper isIE={isIE}>
        <ContentPadding>
          <TitleBlock isIE={isIE}>
            <FeaturedArticleTitle className="title">
              {previewData?.title || data.title}
            </FeaturedArticleTitle>
            <PageIngress
              className="ingress"
              dangerouslySetInnerHTML={{ __html: ingress }}
            />
          </TitleBlock>
          {!smallImage && image && (
            <TitleImageWrapper className="centered" isIE={isIE}>
              <Image src={image?.mediaItemUrl} alt={image?.altText} />
            </TitleImageWrapper>
          )}
        </ContentPadding>
        <Grid>
          <Row>
            <Column span={4}>
              {smallImage && image && (
                <TitleImageWrapper className="small-image centered" isIE={isIE}>
                  <Image src={image?.mediaItemUrl} alt={image?.altText} />
                </TitleImageWrapper>
              )}
              <ContentPadding>
                <AuthorList title data={authors} />
              </ContentPadding>
            </Column>
            <Column span={8}>
              <ContentPadding>
                <RichTextComponent
                  className="capitalize"
                  pageType="NewsArticle"
                  data={bodyText}
                />
              </ContentPadding>
            </Column>
          </Row>
          <Builder
            modules={previewData?.modules?.builder || data?.modules?.builder}
            pageType="SocialDuty"
          />
        </Grid>
      </StyledWrapper>
    </SocialDutyWrapper>
  );
};

export default SocialDuty;

const StyledWrapper = styled(Wrapper)`
  .capitalize p:first-child:first-letter {
    font-size: 105px;
    line-height: 93px;
    letter-spacing: 0.005em;
    font-family: ${typography.mono};
    font-weight: 500;
    float: left;
    padding-right: 7px;
  }

  ${(p) =>
    p.isIE &&
    css`
      display: block;
    `}
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 65px;
  max-width: 100%;
  width: 100%;
  align-self: center;
  ${(p) =>
    p.isIE &&
    css`
      display: block;
      margin: 0 auto;
    `}
  p.type {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 22px;
    font-family: ${typography.mono};
    margin-bottom: 20px;
    text-align: center;
  }
  .title {
    margin-bottom: 38px;
    text-align: center;
    max-width: 66.66%;
  }
  .ingress {
    text-align: center;
    max-width: 66.66%;
  }
  @media (max-width: 1024px) {
    align-self: center;
    text-align: center;
    p.type {
      letter-spacing: 0.05em;
      font-size: 14px;
      line-height: 22px;
    }
    .title {
      margin-bottom: 23px;
      max-width: 720px;
    }
    .ingress {
      max-width: 720px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 39px;
  }
`;

const SocialDutyWrapper = styled.div`
  ${(p) =>
    p.isIE &&
    css`
      > section {
        display: block;
        > * {
          display: block;
        }
      }
    `}
`;
