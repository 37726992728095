import React, { useEffect } from "react";
import { dispatch } from "../../redux";
import styled from "styled-components";
import { colors, typography } from "../../const";
import { Wrapper } from "../../components/Wrapper";
import { Helmet } from "react-helmet";

const ErrorPage = (params) => {
  useEffect(() => {
    dispatch("colorScheme/primary");
  }, []);
  return (
    <Wrapper>
      <PageHeader>
        <Error>404 FIL MANGLER</Error>
        <Helmet>
          <meta name="robots" content="follow, noarchive, noindex" />
        </Helmet>
      </PageHeader>
    </Wrapper>
  );
};

export default ErrorPage;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Error = styled.h1`
  font-family: ${typography.compressedBold};
  color: ${colors.primary};
  font-size: 50px;
`;
