import React from "react";

const Download = ({ width, height, className, ariaHidden }) => {
  return (
    <svg
      className={className || ""}
      width={width || "13"}
      height={height || "7.73"}
      viewBox="0 0 13 7.73"
      aria-hidden={ariaHidden}
    >
      <g data-name="Group 4087" transform="translate(-677 -1061.27)">
        <path
          data-name="Path 1829"
          d="M225.585,294.393l2.691,2.691,2.691-2.691"
          transform="translate(455.533 767.23)"
          fill="#fff"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          data-name="Line 938"
          x2={width || "13"}
          transform="translate(677 1068.5)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Download;
