import React from "react";

const BigSearch = ({ color, ariaHidden }) => {
  let iconColor = color || "#186775";
  return (
    <svg
      width="27.598"
      height="24.16"
      viewBox="0 0 27.598 24.16"
      aria-hidden={ariaHidden}
    >
      <g
        data-name="Group 3947"
        transform="translate(25.253 5.138) rotate(101)"
        aria-hidden={ariaHidden}
      >
        <path
          data-name="Path 809"
          d="M14.636,7.318a7.318,7.318,0,1,0-7.318,7.318A7.34,7.34,0,0,0,14.636,7.318Z"
          transform="translate(0 0)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <line
          data-name="Line 386"
          x2="7.593"
          y2="7.593"
          transform="translate(12.532 12.532)"
          fill="none"
          stroke={iconColor}
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};

export default BigSearch;
