import styled, { css } from "styled-components";

const calculateGutterWidth = () => {
  let defaultGutter = 10;
  if (window.innerWidth > 767) {
    defaultGutter = 32;
  }
  if (window.innerWidth > 767 && window.innerWidth < 1025) {
    defaultGutter = 152;
  }

  return defaultGutter;
};

export const gutterWidth = calculateGutterWidth();

const colWidth = 100 / 12;

export const Grid = styled.div`
  width: 100%;
  position: relative;
  ${({
    setGutterWidth,
    colSpan,
    setLaptopGutterWidth = 40,
    setTabletGutterWidth = 36,
    tabletColSpan,
    tabletSpaceBetween,
  }) =>
    setGutterWidth
      ? `
	${Row}{
		width: calc(100% + ${setGutterWidth}px);
		left: -${setGutterWidth / 2}px;
	}
	${Column}{
		padding-left: ${setGutterWidth / 2}px;
	    padding-right: ${setGutterWidth / 2}px;
		width: calc(${colWidth * colSpan}% - ${setGutterWidth}px);
	}
	@media (max-width: 1440px) {
	${Row}{
		width: 100%;
		left: 0;
		right: 0;
		${
      tabletSpaceBetween
        ? `justify-content: space-between;`
        : `left: -${setLaptopGutterWidth / 2}px;`
    }
		}
		${Column}{
			${
        tabletSpaceBetween
          ? `
					padding-left: 0;
			    	padding-right: 0;
				`
          : `
					padding-left: ${setLaptopGutterWidth / 2}px;
			    	padding-right: ${setLaptopGutterWidth / 2}px;
				`
      }
			width: calc(${colWidth * colSpan}% - ${setLaptopGutterWidth}px);
		}
	}
	@media (max-width: 1024px) {
		${Row}{
			width: calc(100% + ${setTabletGutterWidth}px);
			left: 0;
			right: 0;
			${
        tabletSpaceBetween
          ? `justify-content: space-between;`
          : `left: -${setTabletGutterWidth / 2}px; right: -${
              setTabletGutterWidth / 2
            }px;`
      }
		}
		${Column}{
			${
        tabletSpaceBetween
          ? `
					padding-left: 0;
			    	padding-right: 0;
				`
          : `
					padding-left: ${setTabletGutterWidth / 2}px;
			    	padding-right: ${setTabletGutterWidth / 2}px;
				`
      }
			width: calc(${colWidth * tabletColSpan}% - ${setTabletGutterWidth}px);
		}
	}
	@media (max-width: 768px) {
		${Row}{
			width: 100%;
			left: 0;
			right: 0;
		}
		${Column}{
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
		}
	}
	`
      : ``}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% + ${gutterWidth}px);
  flex-wrap: wrap;
  position: relative;
  left: -${gutterWidth / 2}px;
  @media (max-width: 1024px) {
    left: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(${(props) => colWidth * props.span || 1}% - ${gutterWidth}px);
  padding-left: ${gutterWidth / 2}px;
  padding-right: ${gutterWidth / 2}px;
  ${(props) =>
    props.offset &&
    css`
      margin-left: ${props.offset * colWidth}%;
    `};
  @media (max-width: 1440px) {
    ${(props) =>
      props.laptopSpan ? `width: ${colWidth * props.laptopSpan}%` : ""};
    ${(props) =>
      props.laptopOffset
        ? `margin-left: ${props.laptopOffset * colWidth}%`
        : ""};
  }
  @media (max-width: 1024px) {
    width: ${(props) =>
      props.tabletSpan
        ? colWidth * props.tabletSpan + "%"
        : `calc(100% + ${gutterWidth}px)`};
    margin-left: ${(props) =>
      props.tabletOffset ? props.tabletOffset * colWidth + "%" : 0};
    ${(props) =>
      props.noTabletPadding
        ? `
				padding-left: 0;
				padding-right: 0;
				`
        : ``}
    ${(props) =>
      props.templateWrapper
        ? `
				width: calc(${colWidth * props.tabletSpan + "%"} - ${gutterWidth}px);
			`
        : ""}
  }
  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }

  ${(p) =>
    p.isIE &&
    css`
      display: block;
    `}
`;
