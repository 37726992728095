import React from "react";
import styled from "styled-components";
import { typography } from "../../../const";

const RichText = ({ richtext }) => (
  <RichtextBlock dangerouslySetInnerHTML={{ __html: richtext }} />
);

export default RichText;

const RichtextBlock = styled.div`
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 0.005em;
  font-family: ${typography.regular};
  max-width: 740px;
  h4 {
    font-family: ${typography.condensedBold};
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }
  h2 {
    font-family: ${typography.condensedBold};
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }
  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 21px;
    li {
      display: list-item;
    }
  }

  a {
    font-family: ${typography.mono};
    letter-spacing: -0.015em;
    text-decoration: underline;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    h4 {
      font-size: 22px;
      line-height: 28px;
    }
    h2 {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;
