import React, { useState } from "react";
import styled from "styled-components";
import { typography, colors } from "../../../const";
import Image from "../../Image";
import Timeline from "./Timeline";
import RichText from "./RichText";
import Downloadable from "./Downloadable";
import Video from "./Video";
import Newsletter from "../../../pages/partials/components/Newsletter";

const AccordionContent = ({
  title,
  pdfForceThumbnail,
  data,
  lastChild,
  pageType,
}) => {
  const [isOpen, setOpenState] = useState(false);
  const isLastChild = lastChild ? " last-child " : "";
  return (
    <AccordionWrapper>
      <TitleButton
        className={!isOpen && isLastChild}
        onClick={() => setOpenState(!isOpen)}
        aria-expanded={isOpen ? true : false}
      >
        {isOpen ? "-" : "+"} {title}
      </TitleButton>
      <ContentRow className={isLastChild} open={isOpen}>
        {data?.map((content, i) => {
          switch (content?.contentType) {
            case "richtext":
              return <RichText key={i} richtext={content?.richtext} />;
            case "downloadable":
              return (
                <Downloadable
                  key={i}
                  pdfForceThumbnail={pdfForceThumbnail}
                  downloadable={content?.downloadable}
                  pageType={pageType}
                />
              );
            case "timeline":
              return <Timeline key={i} timeline={content?.timeline} />;
            case "video":
              return content?.video?.map((video, i) => (
                <Video key={i} video={video} />
              ));
            case "image":
              return (
                <Image
                  key={i}
                  alt={content?.image?.altText}
                  src={content?.image?.mediaItemUrl}
                />
              );
            case "newsletter":
              return (
                <Newsletter
                  key={i}
                  data={content?.newsletter}
                  accordionStyles
                />
              );
            default:
              return null;
          }
        })}
      </ContentRow>
    </AccordionWrapper>
  );
};

export default AccordionContent;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleButton = styled.button`
  border: 0;
  border-top: solid 1px ${colors.grey};
  padding: 18px 0;
  display: flex;
  width: 100%;
  font-family: ${typography.mono};
  font-size: 16px;
  letter-spacing: 0.024em;
  line-height: 24px;
  background: none;
  &.last-child {
    border-bottom: solid 1px ${colors.grey};
  }
  &:hover {
    color: rgba(0, 0, 0, 0.6);
    border-top: solid 1px rgba(112, 112, 112, 0.6);
    &.last-child {
      border-bottom: solid 1px rgba(112, 112, 112, 0.6);
    }
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ open }) =>
    open
      ? `
		max-height: 100%;
		overflow: hidden;
		margin-top: 55px;
		margin-bottom: 75px;
		&.last-child{
			margin-bottom: 10px;
			border-bottom: solid 1px ${colors.grey};
			padding-bottom: 75px;
		}
	`
      : `
		&.last-child{
			margin-bottom: 10px;
			.accordion{
				display: none;
			}
		}
		max-height: 0;
		overflow: hidden;
	`}
`;
