import styled from "styled-components";
import { typography, colors } from "../const";

export const ListTitle = styled.h2`
  font-family: ${typography.compressedBold};
  text-transform: uppercase;
  font-size: 60px;
  line-height: 65px;
  color: ${colors.black};
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const EntryTitle = styled.h3`
  font-family: ${typography.canelaRegular};
  letter-spacing: 0.005em;
  font-size: 60px;
  line-height: 78px;
  &.NewsArticle {
    font-family: ${typography.condensedBold};
    line-height: 75px;
  }
  &.singleModule {
    font-size: 50px;
    line-height: 62px;
  }
  &.tripleModule,
  &.doubleModule {
    font-size: 30px;
    line-height: 38px;
  }
  &.archive-title {
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 0.005em;
  }
  @media (max-width: 1024px) {
    &.archive-title {
      font-size: 30px;
      line-height: 38px;
    }
  }
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.005em;
    &.NewsArticle {
      line-height: 38px;
    }
    &.archive-title {
      font-size: 30px;
      line-height: 30px;
    }
    &.singleModule {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0.005em;
    }
    &.doubleModule {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0.005em;
    }
  }
  /* @media (min-width: 1680px) {
        &.doubleModule,
        &.tripleModule {
            font-size: 50px;
            line-height: 62px;
        }
    } */
`;

export const EntryIngress = styled.p`
  font-family: ${typography.regular};
  font-size: 20px;
  line-height: 30px;
  &.Article {
    font-family: ${typography.canelaTextRegular};
  }
  &.doubleModule,
  &.Article {
    font-size: 18px;
    line-height: 28px;
  }
  &.tripleModule {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    &.Article {
      font-size: 17px;
      line-height: 24px;
    }
    &.NewsArticle {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const ArticleIngress = styled.p`
  font-family: ${typography.canelaTextRegular};
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0;
  &.NewsArticle {
    font-family: ${typography.regular};
  }
  h4 {
    font-family: ${typography.condensedBold};
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.02em;
  }
  @media (max-width: 1440px) {
    font-size: 23px;
    line-height: 33px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    h4 {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;

export const NewsArticleTitle = styled.h1`
  font-family: ${typography.condensedBold};
  font-size: 80px;
  line-height: 95px;
  letter-spacing: 0.005em;
  @media (max-width: 1440px) {
    font-size: 60px;
    line-height: 75px;
    letter-spacing: 0.005em;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 43px;
  }
`;

export const ProjectTitle = styled.h1`
  font-family: ${typography.compressedBold};
  font-size: 100px;
  line-height: 100px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 28px;
  @media (max-width: 1440px) {
    font-size: 60px;
  }
  @media (max-width: 1024px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: 0;
  }
`;

export const FeaturedArticleTitle = styled.h1`
  font-family: ${typography.canelaRegular};
  font-size: 80px;
  line-height: 95px;
  letter-spacing: 0.005em;
  @media (max-width: 1440px) {
    font-size: 60px;
    line-height: 78px;
    letter-spacing: 0.005em;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 43px;
  }
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-family: ${typography.canelaRegular};
  font-size: 80px;
  line-height: 95px;
  letter-spacing: 0.005em;
  &.small {
    font-family: ${typography.monoBold};
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  @media (max-width: 1024px) {
    font-size: 60px;
    line-height: 78px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 43px;
  }
`;

export const PageIngress = styled.p`
  font-family: ${typography.regular};
  font-size: 26px;
  line-height: 36px;
  @media (max-width: 1024px) {
    font-size: 23px;
    line-height: 33px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SearchResultTitle = styled.h4`
  font-family: ${typography.condensedBold};
  font-size: 25px;
  line-height: 33px;
  letter-spacing: 0.005em;
`;

export const Type = styled.p`
  font-family: ${typography.mono};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PublishedDate = styled.p`
  font-family: ${typography.mono};
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.024em;
  span.bold {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: bold;
  }
`;

export const IndexTitle = styled.h1`
  font-family: ${typography.compressedBold};
  color: ${colors.projectsIndexTitle};
  font-size: 60px;
  line-height: 120px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    line-height: 65px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 32px;
  }
`;

export const SignupButton = styled.button`
  text-transform: uppercase;
  font-family: ${typography.compressedBold};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.015em;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 15px 17px 14px 18px;
  margin-left: 24px;
`;
