const defaultState = {
  string: "",
  isSearching: false,
  isTyping: false,
  data: null,
};

export default function searchResultReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case "searchString/create":
      return { ...state, ...payload };
    case "isSearching/update":
      return { ...state, ...payload };
    case "isTyping/update":
      return { ...state, ...payload };
    case "searchResult/create":
      return { ...state, ...payload };
    case "searchResult/remove":
      return { ...state, data: null };
    case "searchResult/clear":
      return { ...defaultState };
    default:
      return state;
  }
}
