import React from "react";
import RichtextComponent from "../RichTextComponent";
import { ContentPadding } from "../Wrapper";

export const richTextQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_RichtextModule {
		fieldGroupName
		richtext
	}
`;

const RichtextModule = ({ data, pageType, ...rest }) => (
  <ContentPadding>
    <RichtextComponent
      className={pageType}
      dangerouslySetInnerHTML={{ __html: data }}
      {...rest}
    />
  </ContentPadding>
);

export default RichtextModule;
