import React from "react";
import styled, { css } from "styled-components";

import { typography } from "../const";
import { checkIfIE } from "../const/helpers";

const RichtextComponent = ({ data, pageType, ...rest }) => (
  <RichtextBlock
    className={pageType}
    dangerouslySetInnerHTML={{ __html: data }}
    {...rest}
    isIE={checkIfIE()}
  />
);

export default RichtextComponent;

export const RichtextBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 31px;
  font-family: ${typography.regular};
  max-width: 740px;
  width: 100%;
  ${(p) =>
    p.isIE &&
    css`
      display: block;
    `}

  > * {
    margin: 0;
  }
  ul,
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 21px;
    li {
      display: list-item;
    }
  }
  &.AlternatingContent {
    flex: 1;
    justify-content: flex-start;
    h4 {
      font-family: ${typography.bold};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
    }
    h2 {
      font-family: ${typography.bold};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }
  &.ProjectOrProperty,
  &.NewsArticle,
  &.Article,
  &.SocialDuty,
  &.capitalize,
  &.page {
    h4 {
      font-family: ${typography.condensedBold};
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0.02em;
    }
    h2 {
      font-family: ${typography.condensedBold};
      font-size: 25px;
      line-height: 31px;
      letter-spacing: 0.02em;
    }
    a {
      font-family: ${typography.mono};
      letter-spacing: -0.015em;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    &.AlternatingContent,
    &.ProjectOrProperty,
    &.NewsArticle,
    &.Article,
    &.SocialDuty,
    &.page {
      h4 {
        font-size: 22px;
        line-height: 28px;
      }
      h2 {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
`;
