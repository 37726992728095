import React from "react";
import styled, { css } from "styled-components";
import { Link as A } from "react-router-dom";

import { EntryTitle, EntryIngress, Type } from "../../elements";
import { typography, colors } from "../../const";
import { checkIfIE } from "../../const/helpers";

import Image from "../Image";
//import { StyledA } from "./EntryModule";

export const entryModuleArticleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_EntryModuleArticle {
		fieldGroupName
          blocks {
			imageAlignment
			showImage
            content {
				__typename
              ... on Article {
                fields {
                  description
                  image {
                    altText
                    mediaItemUrl
                  }
                  metaInfo {
                    publicationDate
                  }
                }
                title
				link
              }
            }
          }
	}
`;

const moduleType = (length) => {
  switch (length) {
    case 1:
      return { mode: "singleModule", span: 12 };
    case 2:
      return { mode: "doubleModule", span: 6 };
    case 3:
      return { mode: "tripleModule", span: 4 };
    default:
      return { mode: "singleModule", span: 12 };
  }
};

const EntryModuleArticle = ({ data }) => {
  if (!data) return null;
  const moduleSpan = moduleType(data.blocks.length);
  return (
    <StyledDiv>
      {data?.blocks.map(({ content, imageAlignment, showImage }, i) => {
        const { image, description, metaInfo } = content?.fields;
        return (
          <StyledA
            key={i}
            imageAlignment={imageAlignment}
            tabIndex="0"
            to={content?.link}
            className={`${showImage ? "" : "no-image"} ${moduleSpan?.mode}`}
            isIE={checkIfIE()}
          >
            <div className={`image-block ${moduleSpan?.mode}`}>
              <Image
                alt={image?.altText}
                src={image?.mediaItemUrl}
                sizeOverride={900}
              />
            </div>
            {content?.title && (
              <div className="entry-text-block">
                <Type className="type">Artikkel</Type>
                <EntryTitle
                  className={`${moduleSpan?.mode} ${content?.__typename}`}
                >
                  {content?.title}
                </EntryTitle>
                <EntryIngress
                  className={`ingress ${content?.__typename} ${moduleSpan?.mode}`}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
                {metaInfo?.publicationDate && (
                  <p className="publication-date">
                    Publisert
                    <span className="bold">{metaInfo.publicationDate}</span>
                  </p>
                )}
              </div>
            )}
          </StyledA>
        );
      })}
    </StyledDiv>
  );
};

export default EntryModuleArticle;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;

const StyledA = styled(A)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 0.78;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  .entry-text-block {
    padding: 22px 25px 25px;
    h3,
    p {
      display: block;
      text-align: center;
    }
    .ingress {
      margin-top: 34px;
    }
    p.publication-date {
      font-family: ${typography.mono};
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      margin-top: 39px;
      span.bold {
        margin-left: 0.5em;
        font-family: ${typography.monoBold};
      }
    }
  }

  .tripleModule.ingress {
    display: none;
  }

  &.singleModule {
    display: flex;
    width: 100%;
    .image-block {
    }
    .entry-text-block {
      order: ${({ imageAlignment }) => (imageAlignment ? 1 : 0)};
      background-color: ${colors.secondary};
    }
    @media (max-width: 1024px) {
      display: block;
      .image-block {
        width: 100%;
      }
      .entry-text-block {
        display: block;
      }
    }
    @media (min-width: 1024px) {
      .entry-text-block {
        padding: 90px 60px;
        width: 50%;
      }
      .image-block {
        display: flex;
        width: 50%;
        order: ${({ imageAlignment }) => (imageAlignment ? 0 : 1)};
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &.no-image {
    align-items: center;
    justify-content: center;
    background-color: ${colors.secondary};
    .image-block {
      display: none;
    }
    .entry-text-block {
      padding: 60px 32px 65px 39px;
      flex: 1;
      margin-top: 0;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 60px 25px 65px;
      }
    }
    .tripleModule.ingress {
      display: flex;
    }
  }

  ${(p) =>
    p.isIE &&
    css`
      display: block;

      .entry-text-block h3 {
        display: inline-table;
        margin: 0;
      }
      &.no-image .tripleModule.ingress {
        display: inline-table;
      }
    `}

  p.type {
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    &.singleModule {
      width: 100%;
    }
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 119px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 768px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 59px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: 1024px) {
    width: calc(33% - 31px);
  }
`;
