import React from "react";

const Youtube = ({ ariaHidden }) => {
  return (
    <svg width="26.584" height="17.864" viewBox="0 0 26.584 17.864">
      <path
        data-name="Path 1596"
        d="M1509.162-250.9c3,0,6-.04,9,.013,2.131.038,3.047.787,3.6,2.858a26.131,26.131,0,0,1,.486,9.037,18.808,18.808,0,0,1-.869,4,2.7,2.7,0,0,1-2.773,1.922c-6.313.013-12.627.031-18.94-.01-1.715-.011-2.677-.926-3.182-2.841a26.388,26.388,0,0,1-.484-9.037,18.652,18.652,0,0,1,.818-3.914,2.748,2.748,0,0,1,2.823-2c3.173-.012,6.347,0,9.52,0Zm-3.275,4.023v9.819l8.181-4.908Z"
        transform="translate(-1495.834 250.917)"
        fill="#fff"
        aria-hidden={ariaHidden}
      />
    </svg>
  );
};

export default Youtube;
