import React from "react";
import styled from "styled-components";
import Image from "../Image";
import { Wrapper } from "../Wrapper";
import { typography, colors } from "../../const";

const AuthorList = ({ data, title }) => {
  if (!data) return null;
  return (
    <AuthorWrapper>
      {title && <Title>Pressekontakt</Title>}
      {data.map(({ title, fields }, i) => (
        <AuthorBlock key={i}>
          <div className={`wrap ${i === 0 ? " first " : " "}`}>
            {fields?.profilePicture?.mediaItemUrl && (
              <div className="image-wrap">
                <AuthorImage
                  src={fields?.profilePicture?.mediaItemUrl}
                  alt={fields?.profilePicture?.altText}
                />
              </div>
            )}
            <div className="details">
              {title && <p className="name">{title}</p>}
              {fields?.jobtitle && (
                <p className="jobtitle">{fields.jobtitle}</p>
              )}
              {fields?.phoneNumber && (
                <a className="phone-number" href={`tel:${fields.phoneNumber}`}>
                  Mob. {fields.phoneNumber}
                </a>
              )}
              {fields?.email && (
                <a className="email" href={`mailto:${fields.email}`}>
                  Send E-post
                </a>
              )}
            </div>
          </div>
        </AuthorBlock>
      ))}
    </AuthorWrapper>
  );
};

export default AuthorList;

const AuthorWrapper = styled(Wrapper)`
  padding-top: 18px;
  border-top: solid 1px ${colors.black};
  width: fit-content;
`;

const Title = styled.h4`
  font-family: ${typography.condensedBold};
  font-size: 25px;
  line-height: 31px;
  letter-spacing: 0.02em;
  padding-bottom: 18px;
`;

const AuthorBlock = styled.div`
  display: flex;
  margin-bottom: 18px;

  .image-wrap {
    position: relative;
    width: 90px;
    height: 120px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      width: 45px;
      height: 60px;
    }
  }
  .wrap {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: ${typography.mono};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.024em;
    min-width: 226px;
    .name {
      font-family: ${typography.bold};
      letter-spacing: unset;
      text-transform: uppercase;
    }
  }
`;

const AuthorImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
`;
