import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { typography, colors } from "../../../../const";
import Cross from "../../../../assets/icons/Cross";
import BigSearch from "../../../../assets/icons/BigSearch";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../redux";

const handleSubmit = (e, searchField) => {
  //prevent the form from submitting to the url
  e.preventDefault();
  //unfocus the searchfield
  searchField.current.blur();
};

const SearchBlock = ({
  slimSearch,
  showTitle,
  overlayClose,
  placeholder,
  searchHandler,
  mainSearch,
  isSafari,
  ...props
}) => {
  const isSearchOpen = useSelector((state) => state.searchOverlay.isOpen);
  const searchField = useRef(null);
  useEffect(() => {
    if (isSearchOpen) searchField.current.focus();
  }, [isSearchOpen]);

  let timer; // Timer identifier
  const waitTime = 2000; // Wait time in milliseconds

  const onHandleChange = (event) => {
    dispatch("searchString/create", { string: event.target.value });
    dispatch("isTyping/update", { isTyping: true });
    dispatch("searchResult/remove");

    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatch("isTyping/update", { isTyping: false });
    }, waitTime);
  };

  return (
    <SearchContainer
      className={`${slimSearch ? "slim-search" : ""} ${
        mainSearch ? "main-search" : ""
      } ${isSafari}`}
      onClick={() => document.getElementById("search-input").focus()}
    >
      {overlayClose && (
        <CloseButton onClick={() => overlayClose()}>
          LUKK
          <Cross ariaHidden="true" />
        </CloseButton>
      )}
      <div className="search-block">
        {showTitle && <h2 className="search-title">Hva leter du etter?</h2>}
        <SearchWrapper
          role="search"
          action="."
          onSubmit={(e) => handleSubmit(e, searchField)}
        >
          <input
            id="search-input"
            ref={searchField}
            type="search"
            placeholder={placeholder || "Søk i hele nettstedet"}
            onChange={
              searchHandler
                ? (e) => searchHandler(e.target.value)
                : onHandleChange
            }
            aria-label={placeholder || "Søk i hele nettstedet"}
            autoComplete="off"
          />
          <input type="submit" className="hidden-mobile-submit" />
          <button onClick={(e) => e.preventDefault()} aria-hidden="true">
            <BigSearch ariaHidden="true" />
          </button>
        </SearchWrapper>
      </div>
    </SearchContainer>
  );
};

export default SearchBlock;

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(0px);
	}
`;

const SearchContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary};
  cursor: pointer;
  &.main-search {
    animation: ${fadeInAnimation} 0.3s ease-in-out;
  }
  .search-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: calc(100% - 117px);
    padding-left: 117px;
    padding-top: 65px;
    padding-bottom: 65px;
    .search-title {
      font-size: 35px;
      line-height: 31px;
      font-family: ${typography.condensedBold};
      color: ${colors.primary};
      letter-spacing: 0.02em;
      margin-bottom: 64px;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 25px;
    &.safari {
      max-height: 100%;
      height: 100vh;
    }
    .search-block {
      padding-left: 0;
      form {
        max-width: unset;
      }
    }
  }
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin: 0 25px;
    height: unset;
    /* &.slim-search {
            height: 121px;
        } */
    .search-block {
      width: calc(100% - 50px);
      padding: 45px 25px;
      .search-title {
        font-size: 22px;
        margin-top: 48px;
        margin-bottom: 41px;
      }
      form {
        padding: 11px 0;
        input {
          font-size: 1em;
        }
      }
    }
  }
`;

const CloseButton = styled.button`
  display: inline-flex;
  font-family: ${typography.compressedBold};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.015em;
  align-self: flex-end;
  margin-top: 27px;
  margin-right: 22px;
  cursor: pointer;
  svg {
    margin-left: 13px;
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 25px;
  }
`;

const SearchWrapper = styled.form`
  display: flex;
  border-bottom: solid 1px ${colors.grey};
  padding: 18px 18px 18px 11px;
  max-width: calc(58.333333333333336% - 144px);
  input {
    display: flex;
    width: 100%;
    background-color: inherit;
    font-family: ${typography.mono};
    font-weight: bold;
    color: ${colors.black};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    &:placeholder {
      color: ${colors.black};
    }
    //remove webkit cancel button from desktop
    &::-webkit-search-cancel-button {
      display: none;
    }
    &.hidden-mobile-submit {
      position: absolute;
      opacity: 0;
      left: -1200px;
    }
  }
`;
