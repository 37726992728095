import React from "react";
import styled, { css } from "styled-components";
import { Link as A } from "react-router-dom";

import { colors } from "../../const";
import { checkIfIE } from "../../const/helpers";

import Image from "../Image";
import { EntryTitle, EntryIngress, Type } from "../../elements";
import { Grid } from "../Grid";

export const entryModuleQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_EntryModule {
		fieldGroupName
		blocks{
			image{
				mediaItemUrl
				altText
				mimeType
			}
			link{
				url
				title
			}
			ingress
			title
            titleType
			blueBackground
		}
	}
`;

const moduleType = (length) => {
  switch (length) {
    case 1:
      return { mode: "singleModule", span: 12 };
    case 2:
      return { mode: "doubleModule", span: 6 };
    case 3:
      return { mode: "tripleModule", span: 4 };
    default:
      return { mode: "singleModule", span: 12 };
  }
};

const EntryModule = ({ data, pageType }) => {
  if (!data) return null;
  const moduleSpan = moduleType(data.blocks.length);
  const isIE = checkIfIE();
  return (
    <StyledGrid
      setLaptopGutterWidth={40}
      tabletColSpan={12}
      setGutterWidth={50}
      colSpan={moduleSpan?.span}
    >
      <StyledDiv>
        {data?.blocks.map((block, i) => {
          const {
            image,
            title,
            ingress,
            link,
            titleType,
            blueBackground,
          } = block;
          const subPageType = link?.url.split("/")[3].split("-").join(" ");
          return (
            <StyledA
              key={link?.url + "-" + i}
              to={link?.url ? link.url : ""}
              className={`${moduleSpan?.mode} ${
                blueBackground ? "blue-background" : ""
              } ${
                image?.mimeType === "image/png" && "apply-padding"
              } ${pageType}`}
              isIE={isIE}
            >
              <div className={`image-block ${moduleSpan?.mode}`}>
                <Image
                  sizeOverride={900}
                  alt={image?.altText}
                  src={image?.mediaItemUrl}
                />
              </div>
              {title && (
                <div className={`text-block ${moduleSpan?.mode}`}>
                  {titleType ? (
                    titleType
                  ) : (
                    <Type className="type">
                      {subPageType !== window?.location.pathname.substr(1) &&
                        subPageType}
                    </Type>
                  )}
                  <EntryTitle className={moduleSpan?.mode}>{title}</EntryTitle>
                  <EntryIngress
                    className={`ingress ${moduleSpan?.mode}`}
                    dangerouslySetInnerHTML={{
                      __html: ingress,
                    }}
                  />
                </div>
              )}
            </StyledA>
          );
        })}
      </StyledDiv>
    </StyledGrid>
  );
};

export default EntryModule;

const StyledGrid = styled(Grid)`
  display: flex;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    column-gap: 50px;
  }
`;

export const StyledA = styled(A)`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  p,
  h3 {
    display: block;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 0.78;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  .text-block {
    p.type {
      text-align: center;
      margin-bottom: 20px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .ingress {
      margin-top: 34px;
    }

    ${(p) =>
      p.isIE &&
      css`
        > * {
          display: inline-table;
        }
      `}
  }

  &.singleModule {
    display: flex;
    width: 100%;
    .image-block {
      display: flex;
      width: 50%;
      order: ${({ imageAlignment }) => (imageAlignment ? 1 : 0)};
      position: relative;
      overflow: hidden;
      img {
        aspect-ratio: 1 / 1;
      }
    }
    .text-block {
      order: ${({ imageAlignment }) => (imageAlignment ? 0 : 1)};
      margin-top: 0;
      padding-top: 0;
      background-color: ${colors.secondary};
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 60px 30px;
    }

    @media (max-width: 768px) {
      display: block;
      .image-block {
        width: 100%;
        aspect-ratio: 16 / 9;
      }
      .text-block {
        display: block;
        width: unset;
        padding: 25px;
        p,
        h3 {
          display: block;
        }
      }
    }
  }

  &.blue-background {
    background-color: ${colors.secondary};
    &.apply-padding {
      padding: 55px 36px 61px 36px;
    }
    .text-block {
      p {
        padding-bottom: 25px;
      }
    }
    @media (max-width: 768px) {
      padding-top: 33px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &:hover {
    color: ${colors.primary};
    transition: 0.2s color linear;
  }

  &.doubleModule {
    .text-block {
      padding-top: 22px;
      ${(p) =>
        p.isIE &&
        css`
          > * {
            margin: 0;
            display: inline-table;
          }
        `}
      p {
        max-width: 66.66%;
      }
      .ingress {
        padding-bottom: 32px;
        &.Article,
        &.NewsArticle {
          padding-bottom: 0;
        }
      }
    }
  }
  &.tripleModule {
    ${(p) =>
      p.isIE &&
      css`
        > * {
          margin: 0;
          display: inline-table;
        }
      `}
  }

  &.doubleModule,
  &.tripleModule {
    .image-block {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-left: 25px;
      padding-right: 25px;
      &.SocialDuty {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 119px;
      .text-block {
        padding-top: 22px;
      }
    }
  }
  @media (max-width: 768px) {
    &.doubleModule,
    &.tripleModule {
      margin-bottom: 59px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .image-block {
    }
  }

  @media (min-width: 1024px) {
    width: calc(33% - 31px);

    &.doubleModule {
      width: calc(50% - 31px);
    }
    &.tripleModule {
      .text-block {
        margin-top: 30px;
      }
    }
  }
`;
