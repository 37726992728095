import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { typography, colors } from "../../../../const";
import { Grid, Row, Column } from "../../../../components/Grid";
import { SearchResultTitle, Type, PublishedDate } from "../../../../elements";
import moment from "moment";
import { Link as A } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSearchBox, useInfiniteHits } from "react-instantsearch";

const typeCheck = (type) => {
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
  const match = type?.match(pathNameRegex);
  if (match) {
    if (match[1]) {
      return `${match[1]}`;
    } else {
      return "";
    }
  }
};

const AlgoliaSearchResults = ({ overlayClose, ...props }) => {
  const { query /* , refine, clear, isSearchStalled */ } = useSearchBox(props);
  const {
    hits,
    //currentPageHits,
    results, //Contains hits + search meta.
    //isFirstPage,
    isLastPage,
    //showPrevious,
    showMore,
    sendEvent,
  } = useInfiniteHits(props);

  const sentinelRef = useRef(null);

  const [displayError, setDisplayError] = useState(false);

  const isTyping = useSelector((state) => state.searchResult.isTyping);
  const isSearching = useSelector((state) => state.searchResult.isSearching);

  useEffect(() => {
    setDisplayError(false);
    let timer = setTimeout(() => setDisplayError(true), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <SearchResultsContainer>
      <Row>
        {isTyping ||
          (isSearching && (
            <LoadingBlockWrapper span={12}>
              <h3>Søker...</h3>
            </LoadingBlockWrapper>
          ))}
        {!isTyping && query && results && results.nbHits && (
          <LoadingBlockWrapper span={12}>
            <h3>
              Søketreff på "{query}" ({results.nbHits})
            </h3>
          </LoadingBlockWrapper>
        )}
        {query && !isTyping && hits && !hits.length && (
          <LoadingBlockWrapper span={12}>
            <h3>{`Fant ingen treff på "${query}"`}</h3>
          </LoadingBlockWrapper>
        )}
      </Row>

      <div className="ais-InfiniteHits">
        <ol className="ais-InfiniteHits-list result-list">
          {hits.map((hit) => {
            const publicationDate = hit?.publication_date
              ? moment(hit?.publication_date, "D/M/YYYY hh:mm a").format(
                  "D.M.YYYY"
                )
              : moment(hit?.date).format("D.M.YYYY");

            return (
              <li className="ais-Hits-item result-item">
                <SearchResult key={hit.objectID} span={7}>
                  <A
                    to={hit?.url || hit?.downloadable_file_url || ""}
                    onClick={() => {
                      sendEvent("click", hit, "clicked search result");
                      overlayClose();
                    }}
                    rel="nofollow noreferrer"
                    className="link"
                  >
                    <Type>{typeCheck(hit.url)}</Type>
                    <SearchResultTitle>{hit.title}</SearchResultTitle>
                    <PublishedDate>
                      Publisert <span className="bold">{publicationDate}</span>
                    </PublishedDate>
                  </A>
                </SearchResult>
              </li>
            );
          })}
          <li ref={sentinelRef} aria-hidden="true" />
        </ol>
      </div>
    </SearchResultsContainer>
  );
};

export default AlgoliaSearchResults;

const SearchResultsContainer = styled.section`
  margin-top: 95px;
  padding-bottom: 160px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    h3 {
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.005em;
    }
  }
  .result-list {
    padding-left: 0;
  }
`;

export const SearchResult = styled(Column)`
  padding-left: 0;
  .link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  ${Type} {
    margin-top: 21px;
    margin-bottom: 14px;
  }
  ${SearchResultTitle} {
    margin-bottom: 21px;
  }
  ${PublishedDate} {
    padding-bottom: 21px;
  }
  border-bottom: solid 1px ${colors.grey};
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const LoadingBlockWrapper = styled(Column)`
  h3 {
    color: ${colors.black};
    font-family: ${typography.compressedBold};
    font-size: 60px;
    line-height: 120px;
    letter-spacing: 0.015em;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.005em;
    }
  }
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
