import React from "react";

const Twitter = ({ ariaHidden }) => {
  return (
    <svg width="26.163" height="21.542" viewBox="0 0 26.163 21.542">
      <path
        data-name="Path 1597"
        d="M963.374-252.1l2.114.515c-2.274-2.956-2.583-4.719-1.413-6.862,1.7,1.222,3.336,2.571,5.136,3.652a12.679,12.679,0,0,0,6.113,1.642,16.272,16.272,0,0,1,.01-1.859,5.307,5.307,0,0,1,8.332-3.8,2.343,2.343,0,0,0,2.521.283c.563-.223,1.119-.466,1.679-.7l.219.2-1.928,2.576,2.565-.673c-.339.439-.5.817-.791,1-1.745,1.09-1.745,2.853-2.021,4.617a15.28,15.28,0,0,1-22.761,11.271,6.239,6.239,0,0,1-.59-.444,10.974,10.974,0,0,0,7.444-2.06,22.277,22.277,0,0,1-2.824-1.356,4.051,4.051,0,0,1-1.8-2.557l2.156-.1C964.949-247.8,963.546-249.433,963.374-252.1Z"
        transform="translate(-962.558 259.787)"
        fill="#fff"
        aria-hidden={ariaHidden}
      />
    </svg>
  );
};

export default Twitter;
