import React from "react";
import styled, { css } from "styled-components";
import { typography, colors } from "../../const";
import Image from "../Image";
import { parseTitle, checkIfIE } from "../../const/helpers";
import Arrow from "../../assets/icons/Arrow";
import OpenFullscreen from "../partials/OpenFullscreen";
import RichTextComponent from "../RichTextComponent";
import { getVideoUrl } from "./VideoModule";
import { ContentPadding } from "../Wrapper";

export const alternatingContentQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_AlternatingContentModule {
		fieldGroupName
		image{
			mediaItemUrl
			altText
		}
		videoOrImage
		videoLink
		videoFormat
		title
		richtext
		imagePosition
	}
`;

const AlternatingContentModule = ({ data, pageType }) => {
  const {
    image,
    title,
    richtext,
    imagePosition,
    videoOrImage,
    videoLink,
    videoFormat,
  } = data;
  const videoInfo = getVideoUrl(videoLink);
  return (
    <ContentPadding>
      <AlternatingContent
        className={`${imagePosition ? "right" : "left"}`}
        isIE={checkIfIE()}
      >
        <Wrap className="image-wrapper">
          {videoOrImage ? (
            <div className="image-block">
              <Image alt={image?.altText} src={image?.mediaItemUrl} />
              <OpenFullscreen showcaseItems={data} />
            </div>
          ) : (
            <VideoBlock>
              <div
                className={`video-container ${videoInfo?.className} ${videoFormat}`}
              >
                <iframe src={videoInfo?.url} title={title} />
              </div>
            </VideoBlock>
          )}
          {title && (
            <div className="text-block">
              <Arrow className="arrow" />
              <p
                className="image-title"
                dangerouslySetInnerHTML={{
                  __html: parseTitle(title),
                }}
              />
            </div>
          )}
        </Wrap>
        <Wrap className="richtext-wrapper">
          <RichTextComponent
            className={`AlternatingContent ${pageType}`}
            data={richtext}
          />
        </Wrap>
      </AlternatingContent>
    </ContentPadding>
  );
};

export default AlternatingContentModule;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const AlternatingContent = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: 0.005em;
  font-family: ${typography.regular};
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .image-wrapper {
    position: relative;
    .image-block {
      display: flex;
      width: 100%;
      ${(p) =>
        p.isIE &&
        css`
          position: relative;
          height: 100%;
          display: block;
        `}
      img {
        width: 100%;
        height: auto;
      }
    }
    .text-block {
      display: inline-flex;
      margin-top: 37px;
      align-items: flex-start;
      padding-top: 14px;
      padding-right: 20px;
      border-top: solid 1px ${colors.black};
      width: fit-content;
      .arrow {
        padding-top: 5px;
        margin-right: 9px;
      }
      p.image-title {
        font-family: ${typography.mono};
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.015em;
        span.bold-text {
          font-family: ${typography.monoBold};
        }
      }
    }
  }
  ${(p) =>
    p.isIE &&
    css`
      .richtext-wrapper {
        display: block;
        width: 100%;
        height: 100%;
      }
    `}
  @media (max-width: 1024px) {
    flex-direction: column;
    .richtext-wrapper {
      width: 100%;
    }
    .image-wrapper {
      width: 100%;
      margin-bottom: 1em;
    }
  }

  @media (min-width: 768px) {
    &.right {
      .image-wrapper {
        order: 1;
      }
    }
  }
`;

const VideoBlock = styled.div`
  display: flex;
  flex-direction: column;
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    &.youtube {
      padding-bottom: 56.25%;
    }
    &.vimeo {
      padding-bottom: 50%;
    }
    &.four_three {
      padding-bottom: 75%;
    }
    &.four_five {
      padding-bottom: 125%;
    }
    &.one_one {
      padding-bottom: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .text-block {
    display: inline-flex;
    margin-top: 37px;
    align-items: flex-start;
    padding-top: 14px;
    padding-right: 20px;
    border-top: solid 1px ${colors.black};
    width: fit-content;
    .arrow {
      padding-top: 5px;
      margin-right: 9px;
    }
    p.image-title {
      font-family: ${typography.mono};
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.015em;
      span.bold-text {
        font-family: ${typography.monoBold};
      }
    }
  }
`;
