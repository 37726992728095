import useRequest from "kb-gql";

export const seoQuery = `
	seo {
	  metaDesc
	  opengraphDescription
	  opengraphImage {
		mediaItemUrl
		altText
		mediaDetails{
			height
			width
		}
	  }
	  twitterDescription
	  twitterTitle
	  twitterImage {
	   mediaItemUrl
	   altText
	   mediaDetails{
		   height
		   width
	   }
	 }
	  metaKeywords
	  opengraphModifiedTime
	  opengraphPublishedTime
	  opengraphTitle
	  opengraphUrl
	  title
	}
`;

const globalSeoQuery = `
	seo {
		schema {
			siteName
			wordpressSiteName
			siteUrl
			companyName
			companyLogo {
				mediaItemUrl
			}
			logo {
				mediaItemUrl
			}
		}
		social {
			facebook {
				url
				defaultImage {
					mediaItemUrl
				}
			}
			instagram {
				url
			}
			linkedIn {
				url
			}
			twitter {
			cardType
				username
			}
			wikipedia {
				url
			}
			youTube {
				url
			}
		}
	}
`;

const projectsAndPropertiesArchiveSeoQuery = `
	projectsAndPropertiesPage {
		projectsArchiveSeo {
			metaDesc
			metaKeywords
			opengraphDescription
			opengraphImage {
				mediaItemUrl
		        altText
		        mediaDetails {
		          height
		          width
		        }
			}
			opengraphTitle
			title
			twitterDescription
			twitterImage {
				mediaItemUrl
		        altText
		        mediaDetails {
		          height
		          width
		        }
			}
			twitterTitle
		}
	}
`;

export const useProjectsArchiveSeo = () => {
  return useRequest(
    projectsAndPropertiesArchiveSeoQuery,
    "_global_projectsarchive_seo",
    "projectsArchiveSeo"
  );
};

export const useGlobalSeo = () => {
  return useRequest(globalSeoQuery, "_global_seo");
};
