import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../../const";
import { Column } from "../../../components/Grid";
import { Link as A } from "react-router-dom";

const FooterLinks = React.memo(({ footerMenu }) => {
  return (
    <FooterLinkWrapper noTabletPadding tabletSpan={12} span={8}>
      <h5 className="row-title">LENKER</h5>
      <div className="spacing-container">
        {footerMenu?.map(({ linkGroup }, i) => (
          <FooterLinkList key={i}>
            {linkGroup?.map(({ link }, i) => {
              if (!link) return null;
              const extractHostname = link.url
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "")
                .split(/[/?#]/)[0];
              const externalLink =
                link?.url?.indexOf("www") !== -1 ||
                window.location.hostname !== extractHostname;
              return (
                <li key={i}>
                  {externalLink ? (
                    <a
                      href={link?.url}
                      rel="nofollow noreferrer"
                      target={link?.target}
                    >
                      {link?.title}
                    </a>
                  ) : (
                    <A to={link?.url} rel="nofollow noreferrer">
                      {link?.title}
                    </A>
                  )}
                </li>
              );
            })}
          </FooterLinkList>
        ))}
      </div>
    </FooterLinkWrapper>
  );
});

export default FooterLinks;

const FooterLinkWrapper = styled(Column)`
  .spacing-container {
    position: relative;
    display: flex;
    left: -16px;
    width: calc(100% + 32px);
    padding-bottom: 71px;
    color: ${colors.white};
    ul {
      padding: 0 16px;
      max-width: 50%;
      width: 100%;
      list-style-type: none;
    }
  }
  @media (max-width: 768px) {
    .spacing-container {
      flex-direction: column;
      padding-bottom: 61px;
    }
  }
`;

const FooterLinkList = styled.ul`
  font-family: ${typography.condensedMedium};
  font-size: 22px;
  letter-spacing: 0.025em;
  line-height: 33px;
  display: flex;
  flex-direction: column;
  a {
    color: ${colors.white};
    text-decoration: none;
    &:hover {
      -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
      color: rgba(255, 255, 255, 0.6);
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    line-height: 28px;
    li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
