import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Wrapper } from "../../../components/Wrapper";
import { colors, typography } from "../../../const";
import useRequest from "kb-gql";
import { SignupButton } from "../../../elements";
import ErrorCircle from "../../../assets/icons/ErrorCircle";

const newsletterGlobalsQuery = `
	siteSettings {
		fields {
			success
			error
		}
	}
`;

const Newsletter = ({ data, accordionStyles }) => {
  const newsletterGlobals = useRequest(newsletterGlobalsQuery);
  const [email, setEmail] = useState(null);
  const [successState, setSuccessState] = useState(null);
  let { title, text, actionurl } = data;
  const form = useRef(null);
  if (data.length === 0) title = "Meld deg på vårt nyhetsbrev";

  const validateOnSubmit = (e) => {
    e.preventDefault();
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const checkEmail = re.test(String(email).toLowerCase());
    setSuccessState(checkEmail);
    if (checkEmail) {
      form.current.submit();
    }
  };
  if (!newsletterGlobals) return null;
  const { success, error } = newsletterGlobals;
  return (
    <NewsletterSignupWrapper className={accordionStyles && "accordion"}>
      <TitleBlock>
        {title && <h4 className="title">{title}</h4>}
        {text && <p className="text">{text}</p>}
      </TitleBlock>
      <SignupForm
        ref={form}
        className="input-row"
        action={
          actionurl ||
          "https://statsbygg.us10.list-manage.com/subscribe/post?u=1634d928f6c13abc60bac32eb&amp;id=f548d71301"
        }
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
        onSubmit={(e) => validateOnSubmit(e)}
      >
        {successState !== null && (
          <label
            id="mce-EMAIL-label"
            className={successState ? "success" : "error"}
            aria-live="assertive"
            id="newsletter-label-msg"
          >
            {!successState && <ErrorCircle />}
            <span>{successState ? success : error}</span>
          </label>
        )}
        <div className="wrapper">
          <input
            aria-describedby="mce-EMAIL-label"
            name="EMAIL"
            type="email"
            id="mce-EMAIL"
            aria-label="email-field"
            placeholder="Din E-post"
            className="email-field"
            aria-invalid={successState ? "false" : "true"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="safari">
            <SignupButton
              type="submit"
              name="subscribe"
              className="signup-button"
              id="mc-embedded-subscribe"
              value="Subscribe"
            >
              Send
            </SignupButton>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_1634d928f6c13abc60bac32eb_f548d71301"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>
        </div>
      </SignupForm>
    </NewsletterSignupWrapper>
  );
};

export default Newsletter;

const NewsletterSignupWrapper = styled(Wrapper)`
  column-gap: 25px;
  position: relative;
  flex-direction: row;
  background-color: ${colors.secondary};
  padding: 53px 133px 56px 63px;
  justify-content: space-between;
  margin-top: 78px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &.accordion {
    margin-bottom: 78px;
    background-color: ${colors.primary};
    .title,
    p.text {
      color: ${colors.white};
    }
    .wrapper {
      button {
        color: ${colors.primary};
        background-color: ${colors.white};
        height: 100%;
        padding-bottom: 0;
        padding-top: 0;
      }
      input.email-field {
        border-bottom: solid 1px ${colors.white};
        color: ${colors.white};
        ::placeholder {
          color: ${colors.white};
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px;
    margin-top: 34px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex: 3;
  column-gap: 25px;
  .title {
    font-family: ${typography.condensedBold};
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    max-width: 300px;
  }
  p.text {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    max-width: 300px;
  }
  @media (max-width: 768px) {
    .title {
      font-size: 22px;
      line-height: 28px;
    }
    p.text {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.02em;
    }
  }
`;

const SignupForm = styled.form`
  display: flex;
  flex: 2;
  margin-top: 20px;
  flex-direction: column;
  .wrapper {
    display: flex;
    button {
      color: ${colors.white};
    }
    input.email-field {
      width: 100%;
      max-width: 355px;
      padding-bottom: 18px;
      margin-top: 17px;
      border-bottom: solid 1px ${colors.primary};
      ::placeholder {
        color: ${colors.black};
        font-family: ${typography.mono};
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.024em;
      }
    }
  }
  .safari {
    position: relative;
    color: ${colors.white};
  }
  label {
    position: absolute;
    top: 30px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.024em;
    font-family: ${typography.mono};
    &.success {
      color: ${colors.primary};
    }
    &.error {
      color: ${colors.error};
    }
    svg {
      width: 30px;
      height: 30px;
      margin-right: 2px;
      * {
        stroke: ${colors.error};
      }
    }
    @media (max-width: 768px) {
      position: relative;
      padding-bottom: 39px;
      top: 0;
    }
  }
`;
