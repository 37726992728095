import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../const";
import { Grid, Row, Column } from "../Grid";
import { RichtextBlock } from "../RichTextComponent";
import { ContentPadding } from "../Wrapper";

export const richTextWithFactboxQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_RichtextWithFactboxModule {
		fieldGroupName
		richtext
		factBox{
			title
			factText
			position
		}
	}
`;

const RichtextWithFactboxModule = ({ data, pageType, ...rest }) => {
  if (!data) return null;
  const { factBox, richtext } = data;
  return (
    <ContentPadding>
      <Grid>
        <Row>
          <FactBoxColumn span={3} tabletSpan={12} className={factBox?.position}>
            <Factbox>
              {factBox?.title && <h4>{factBox?.title}</h4>}
              {factBox?.factText && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: factBox?.factText,
                  }}
                />
              )}
            </Factbox>
          </FactBoxColumn>
          <RichtextColumn offset={1} span={8} tabletSpan={12}>
            <RichtextBlock
              className={pageType}
              dangerouslySetInnerHTML={{ __html: richtext }}
              {...rest}
            />
          </RichtextColumn>
        </Row>
      </Grid>
    </ContentPadding>
  );
};

export default RichtextWithFactboxModule;

const RichtextColumn = styled(Column)`
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const FactBoxColumn = styled(Column)`
  &.top {
    justify-content: flex-start;
  }
  &.middle {
    justify-content: center;
  }
  &.bottom {
    justify-content: flex-end;
  }
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    order: 1;
    margin-top: 2em;
    max-width: 50%;
  }
`;

const Factbox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  border-top: solid 1px ${colors.black};
  h4 {
    font-family: ${typography.condensedBold};
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.02em;
    margin-bottom: 25px;
  }
  p {
    font-family: ${typography.mono};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
  }
`;
