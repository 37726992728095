import React from "react";
import { Helmet } from "react-helmet";
import { useGlobalSeo } from "../../hooks/useSeo";

const Seo = React.memo(({ pageSeo }) => {
  const seoData = useGlobalSeo();
  if (!seoData) return null;
  const { schema } = seoData;
  return (
    <Helmet>
      <title>{pageSeo?.title || schema?.siteName || "Statsbygg"}</title>
      {pageSeo?.metaDesc ? (
        <meta name="description" content={pageSeo?.metaDesc} />
      ) : (
        <meta
          name="description"
          content="Statsbygg leder en rekke av landets største og viktigste byggeprosjekter. Vi forvalter kulturarv, drifter toppmoderne eiendommer og gir staten råd i viktige byggesaker med store nedslagsfelt."
        />
      )}
      {pageSeo?.metaKeywords && (
        <meta name="keywords" content={pageSeo?.metaKeywords} />
      )}

      {pageSeo?.opengraphTitle && (
        <meta property="og:title" content={pageSeo?.opengraphTitle} />
      )}
      {pageSeo?.opengraphDescription && (
        <meta
          property="og:description"
          content={pageSeo?.opengraphDescription}
        />
      )}
      {pageSeo?.opengraphImage && (
        <meta
          property="og:image"
          content={pageSeo?.opengraphImage?.mediaItemUrl}
        />
      )}
      {pageSeo?.opengraphImage?.altText && (
        <meta
          property="og:image:alt"
          content={pageSeo?.opengraphImage?.altText}
        />
      )}
      {pageSeo?.opengraphImage?.mediaDetails?.width && (
        <meta
          property="og:image:width"
          content={pageSeo?.opengraphImage?.mediaDetails?.width}
        />
      )}
      {pageSeo?.opengraphImage?.mediaDetails?.height && (
        <meta
          property="og:image:height"
          content={pageSeo?.opengraphImage?.mediaDetails?.height}
        />
      )}

      {pageSeo?.opengraphPublishedTime && (
        <meta
          property="article:published_time"
          content={pageSeo?.opengraphPublishedTime}
        />
      )}
      {pageSeo?.opengraphModifiedTime && (
        <meta
          property="article:modified_time"
          content={pageSeo?.opengraphModifiedTime}
        />
      )}

      {pageSeo?.twitterTitle && (
        <meta name="twitter:title" content={pageSeo?.twitterTitle} />
      )}

      {pageSeo?.twitterDescription && (
        <meta
          name="twitter:description"
          content={pageSeo?.twitterDescription}
        />
      )}

      {(pageSeo?.opengraphImage || pageSeo?.twitterImage) && (
        <meta
          name="twitter:image"
          content={
            pageSeo?.twitterImage?.mediaItemUrl ||
            pageSeo?.opengraphImage?.mediaItemUrl
          }
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
});

export default Seo;
