import React from "react";
import styled from "styled-components";
import { Grid, Row, Column, gutterWidth } from "../Grid";
import AccordionModule from "./AccordionModule";
import RichtextModule from "./RichtextModule";
import ImageFullWidthModule from "./ImageFullWidthModule";
import ImageLinkModule from "./ImageLinkModule";
import ImageCarousel from "./ImageCarousel";
import EntryModule from "./EntryModule";
import EntryModuleArticle from "./EntryModuleArticle";
import EntryModuleNews from "./EntryModuleNews";
import EntryModuleSocialDuty from "./EntryModuleSocialDuty";
import VacanciesModule from "./VacanciesModule";
import DisplacedImageModule from "./DisplacedImageModule";
import HeroModule from "./HeroModule";
import Blockquote from "./BlockquoteModule";
import RichtextWithFactboxModule from "./RichtextWithFactboxModule";
import Newsletter from "./NewsletterModule";
import AlternatingContentModule from "./AlternatingContentModule";
import VideoModule from "./VideoModule";
import ContactModule from "./ContactModule";
import AnchorModule from "./AnchorModule";

const moduleSwitch = (module, pageType, debug) => {
  if (process.env.NODE_ENV === "development" && debug)
    console.log("passing through the builder: ", module, pageType);
  switch (module.fieldGroupName) {
    case pageType + "_Modules_Builder_AccordionModule":
      return <AccordionModule pageType={pageType} data={module} />;

    case pageType + "_Modules_Builder_RichtextModule":
      return <RichtextModule pageType={pageType} data={module.richtext} />;

    case pageType + "_Modules_Builder_ImageFullWidthModule":
      return <ImageFullWidthModule data={module} />;

    case pageType + "_Modules_Builder_ImageLinkModule":
      return <ImageLinkModule data={module} />;

    case pageType + "_Modules_Builder_ImageCarouselModule":
      return <ImageCarousel imageArray={module.imageArray} />;

    case pageType + "_Modules_Builder_EntryModule":
      return <EntryModule data={module} pageType={pageType} />;

    case pageType + "_Modules_Builder_EntryModuleArticle":
      return <EntryModuleArticle data={module} />;

    case pageType + "_Modules_Builder_EntryModuleNews":
      return <EntryModuleNews data={module} />;

    case pageType + "_Modules_Builder_EntryModuleSocialDuties":
      return <EntryModuleSocialDuty data={module} />;

    case pageType + "_Modules_Builder_VacanciesModule":
      return <VacanciesModule />;

    case pageType + "_Modules_Builder_DisplacedImageModule":
      return <DisplacedImageModule data={module} />;

    case pageType + "_Modules_Builder_HeroModulePosts":
      return <HeroModule data={module} />;

    case pageType + "_Modules_Builder_BlockquoteModule":
      return <Blockquote data={module} />;

    case pageType + "_Modules_Builder_RichtextWithFactboxModule":
      return <RichtextWithFactboxModule pageType={pageType} data={module} />;

    case pageType + "_Modules_Builder_NewsletterModule":
      return <Newsletter data={module} />;

    case pageType + "_Modules_Builder_AlternatingContentModule":
      return <AlternatingContentModule pageType={pageType} data={module} />;

    case pageType + "_Modules_Builder_VideoModule":
      return <VideoModule data={module} />;

    case pageType + "_Modules_Builder_PressContactModule":
      return <ContactModule data={module} />;

    case pageType + "_Modules_Builder_AnchorModule":
      return <AnchorModule data={module} />;

    default:
      if (process.env.NODE_ENV === "development" && debug)
        console.log(
          "Didn't hit any builder modules, case should be: '" +
            module?.fieldGroupName +
            "' but was: '" +
            pageType +
            module?.fieldGroupName?.slice(
              module?.fieldGroupName?.indexOf("_")
            ) +
            "'"
        );
  }
};

const sizeCalc = (moduleName, pageType) => {
  switch (pageType) {
    //Project specific rules
    case "ProjectOrProperty":
      switch (moduleName) {
        case `${pageType}_Modules_Builder_RichtextModule`:
          return { offset: 1, span: 7 };
        case `${pageType}_Modules_Builder_DisplacedImageModule`:
          return { offset: 4, span: 8 };
        default:
          return { offset: 0, span: 12 };
      }
    //NewsArticle, Article, SocialResponsibility specific rules
    case "NewsArticle":
    case "Article":
    case "SocialDuty":
      switch (moduleName) {
        case `${pageType}_Modules_Builder_NewsletterModule`:
          return { offset: 0, span: 12 };
        case `${pageType}_Modules_Builder_ImageCarouselModule`:
          return { offset: 0, span: 12 };
        case `${pageType}_Modules_Builder_DisplacedImageModule`:
          return { offset: 0, span: 8 };
        case `${pageType}_Modules_Builder_RichtextWithFactboxModule`:
          return { offset: 0, span: 12 };
        case `${pageType}_Modules_Builder_AlternatingContentModule`:
          return { offset: 0, span: 12 };
        case `${pageType}_Modules_Builder_BlockquoteModule`:
          return { offset: 3, span: 9 };
        case `${pageType}_Modules_Builder_ImageFullWidthModule`:
          return { offset: 0, span: 12 };
        default:
          return { offset: 4, span: 8 };
      }
    case "page":
      switch (moduleName) {
        case `${pageType}_Modules_Builder_DisplacedImageModule`:
          return { offset: 0, span: 8 };
        case `${pageType}_Modules_Builder_ImageFullWidthModule`:
          return { offset: 0, span: 12 };
        default:
          return { offset: 0, span: 12 };
      }

    //Fallback sizing
    default:
      return { offset: 0, span: 12 };
  }
};

const Builder = ({ modules, pageType, debug, ...rest }) => {
  if (!modules || !modules.length) return null;
  return (
    <Grid>
      <BuilderContainer className={pageType} as="section" {...rest}>
        {modules.map((module, key) => {
          const size = sizeCalc(module.fieldGroupName, pageType);
          return (
            <ModuleWrapper
              as="section"
              key={key}
              offset={size?.offset}
              span={size?.span}
              className={`${
                modules.length === key + 1 ? "last-module" : ""
              } ${module.fieldGroupName.replace(
                `${pageType}_Modules_Builder_`,
                ""
              )}`}
              mobileSpan={12}
            >
              {moduleSwitch(module, pageType, debug)}
            </ModuleWrapper>
          );
        })}
      </BuilderContainer>
    </Grid>
  );
};

export default Builder;

const BuilderContainer = styled(Row)`
  display: flex;
  margin-top: 78px;
  align-items: center;
  &.main-content,
  &.ProjectOrProperty {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    margin-top: 34px;
  }
`;

const ModuleWrapper = styled(Column)`
  display: flex;
  justify-content: center;
  margin-bottom: 95px;
  &.last-module,
  &.AnchorModule {
    margin-bottom: 0;
  }
  &.VideoModule,
  &.DisplacedImageModule,
  &.ImageFullWidthModule,
  &.ImageLinkModule {
    margin-bottom: 80px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 95px;
    &.VideoModule,
    &.DisplacedImageModule,
    &.ImageFullWidthModule,
    &.ImageLinkModule {
      margin-bottom: 80px;
    }
  }
  @media (max-width: 1024px) {
    padding: 0;
    &.ImageCarouselModule {
      left: -${gutterWidth / 2}px;
      right: -${gutterWidth / 2}px;
      position: relative;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 59px;
    padding: 0 25px;
    &.VideoModule,
    &.DisplacedImageModule,
    &.ImageFullWidthModule,
    &.ImageLinkModule {
      margin-bottom: 44px;
    }
    &.ImageFullWidthModule {
      padding: 0;
    }
  }
`;
