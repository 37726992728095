import React from "react";
import styled from "styled-components";
import { typography, colors } from "../../../const";
import { dispatch } from "../../../redux";
import SmallSearch from "../../../assets/icons/SmallSearch";
import EmployeeIcon from "../../../assets/icons/ansattsok.svg";
import { Link as A, useLocation } from "react-router-dom";

const checkCurrentRoute = (url, currentPath) => {
  if (!url) return;
  const check = url?.split("/");
  return `/${check[check.length - 1]}` === currentPath;
};

const MenuLinks = ({
  mainMenu,
  subMenu,
  className,
  isSearchOpen,
  closeMenu,
  shouldBeFocusable,
}) => {
  const { pathname: currentPath } = useLocation();

  return (
    <nav aria-labelledby="hovedmeny">
      <List role="navigation" className={className}>
        {mainMenu?.map(({ link }, i) => {
          if (!link) return null;
          const currentlyActive = checkCurrentRoute(link?.url, currentPath);
          return (
            <ListItem key={i} endOfList={mainMenu.length === i + 1}>
              <A
                className={currentlyActive ? "active" : ""}
                to={link?.url}
                rel="nofollow noreferrer"
                onClick={() => closeMenu(false)}
                tabIndex={shouldBeFocusable ? "1" : "-1"}
              >
                {link?.title}
              </A>
            </ListItem>
          );
        })}
        <ListItem className="small search-button" custom={1}>
          <button
            aria-label="Åpne søk"
            tabIndex={shouldBeFocusable ? "1" : "-1"}
            onClick={() => {
              isSearchOpen
                ? dispatch("searchOverlay/close")
                : dispatch("searchOverlay/open");
              closeMenu(false);
            }}
          >
            <SmallSearch ariaHidden="true" />
            Søk
          </button>
        </ListItem>
        <ListItem className="small search-button">
          <A
            to={"/kontakt/ansattsok"}
            rel="nofollow noreferrer"
            onClick={() => closeMenu(false)}
            tabIndex={shouldBeFocusable ? "1" : "-1"}
          >
            <EmployeeSearch>
              <img src={EmployeeIcon} />
              {"Ansattsøk"}
            </EmployeeSearch>
          </A>
        </ListItem>
        {subMenu?.map(({ link }, i) => {
          i = 3 + i;
          if (!link) return null;
          const currentlyActive = checkCurrentRoute(link?.url, currentPath);
          return (
            <ListItem key={i} className="small">
              <A
                className={currentlyActive ? "active" : ""}
                to={link?.url}
                rel="nofollow noreferrer"
                onClick={() => closeMenu(false)}
                tabIndex={shouldBeFocusable ? "1" : "-1"}
              >
                {link?.title}
              </A>
            </ListItem>
          );
        })}
      </List>
    </nav>
  );
};

export default React.memo(MenuLinks);

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
`;

const ListItem = styled.li`
  font-family: ${typography.condensedMedium};
  font-size: 22px;
  line-height: 28px;
  margin-bottom: ${({ endOfList }) => (endOfList ? 37 : 15)}px;
  &.small {
    font-family: ${typography.mono};
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  &.search-button {
    svg {
      margin-right: 11px;
    }
  }
  a,
  button {
    color: ${colors.black};
    text-decoration: none;
    transition: color 0.2s linear;
    &.active {
      border-bottom: solid 1px rgba(0, 0, 0, 0.6);
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      color: rgba(0, 0, 0, 0.6);
      text-decoration: underline;
    }
    &:focus {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  // TODO: :not(:last-child) for img i ansattsøk  ?? -> |small.search-button:focus-visible:not(:last-child)
`;

const EmployeeSearch = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 10px;
    margin-right: 12px;
  }
`;
