import React from "react";
import AccordionContent from "./accordionPartials/AccordionContent";
import styled from "styled-components";
import { ContentPadding } from "../Wrapper";

export const accordionQuery = (pageType) => `
	... on ${pageType}_Modules_Builder_AccordionModule{
		fieldGroupName
		contentRow{
			pdfForceThumbnail
			title
			content{
				contentType
				richtext
				downloadable{
					title
					thumbnail{
						mediaItemUrl
						altText
						caption
					}
					downloadableFile{
						mimeType
						title
						mediaItemUrl
					}
				}
				video{
					videoLink
					videoFormat
					title
				}
				timeline{
					text
					year
				}
				image{
					altText
					mediaItemUrl
				}
				newsletter {
					text
					title
					actionurl
				}
			}
		}
	}
`;

const AccordionModule = ({ data, pageType }) => {
  return (
    <AccordionContainer>
      <ContentPadding>
        {data.contentRow?.map((row, i) => {
          return (
            <AccordionContent
              key={i}
              title={row.title}
              pdfForceThumbnail={row.pdfForceThumbnail}
              data={row.content}
              lastChild={i + 1 === data.contentRow.length}
              pageType={pageType}
            />
          );
        })}
      </ContentPadding>
    </AccordionContainer>
  );
};

export default AccordionModule;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
