const defaultState = {
  isOpen: false,
  employee: null,
  isSubmitting: false,
  success: false,
  error: false,
};

export default function employeeFormReducer(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case "employeeForm/close":
      return {
        ...state,
        isOpen: false,
        isSubmitting: false,
        success: false,
        error: false,
        employee: null,
      };
    case "employeeForm/open":
      return {
        ...state,
        isOpen: true,
        isSubmitting: false,
        success: false,
        error: false,
        employee: payload.employee,
      };
    case "employeeForm/submit":
      return {
        ...state,
        isSubmitting: true,
        success: false,
        error: false,
      };
    case "employeeForm/submit-succeeded":
      return {
        ...state,
        success: true,
        error: false,
      };
    case "employeeForm/submit-failed":
      return {
        ...state,
        isSubmitting: false,
        success: false,
        error: payload?.error || true,
      };
    case "employeeForm/file-attachment-failed":
      return {
        ...state,
        success: false,
        error: payload?.error || true,
      };
    case "employeeForm/clear-errors":
      return {
        ...state,
        error: false,
      };
    case "REDUX/CLEAR":
      return { ...defaultState };
    default:
      return state;
  }
}
