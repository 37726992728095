import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

let instance = null;
if (process.env.REACT_APP_MATOMO_URL_BASE) {
  instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_URL_BASE,
    siteId: process.env?.REACT_APP_MATOMO_SITE_ID ?? 1,
    userId: process.env?.REACT_APP_MATOMO_USER_ID ?? undefined,
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: "POST",
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {instance && (
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    )}
    {!instance && <App />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
