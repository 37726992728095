import React from "react";

const Arrow = ({ width, height, className, color, ariaHidden }) => {
  let arrowWidth = width || 15.664;
  let arrowHeight = height || 8.438;
  return (
    <svg
      className={className || ""}
      width={arrowWidth}
      height={arrowHeight}
      style={{ minWidth: arrowWidth }}
      viewBox={"0 0 " + arrowWidth + " " + arrowHeight}
      aria-hidden={ariaHidden}
    >
      <path
        style={color ? { fill: color } : null}
        data-name="Path 2121"
        d="M17.949-7.227,13.73-11.445v3.5H2.285V-6.5H13.73v3.5Z"
        transform="translate(-2.285 11.445)"
      />
    </svg>
  );
};

export default Arrow;
