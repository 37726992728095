import React from "react";
import styled, { keyframes } from "styled-components";
import { typography, colors } from "../../../../const";
import Cross from "../../../../assets/icons/Cross";
import BigSearch from "../../../../assets/icons/BigSearch";
import { dispatch } from "../../../../redux";
import { SearchBox } from "react-instantsearch";

const AlgoliaSearchBlock = ({
  slimSearch,
  showTitle,
  overlayClose,
  placeholder,
  mainSearch,
  isSafari,
  ...props
}) => {
  let timer; // Timer identifier
  const waitTime = 1000; // Wait time in milliseconds

  const queryHook = (query, search) => {
    dispatch("searchString/create", { string: query });
    dispatch("isTyping/update", { isTyping: true });

    clearTimeout(timer);

    timer = setTimeout(() => {
      dispatch("isTyping/update", { isTyping: false });
      search(query);
    }, waitTime);
  };

  return (
    <SearchContainer
      className={`${slimSearch ? "slim-search" : ""} ${
        mainSearch ? "main-search" : ""
      } ${isSafari}`}
    >
      {overlayClose && (
        <CloseButton onClick={() => overlayClose()}>
          LUKK
          <Cross ariaHidden="true" />
        </CloseButton>
      )}
      <div className="search-block">
        {showTitle && <h2 className="search-title">Hva leter du etter?</h2>}
        <SearchWrapper>
          <SearchBox
            placeholder={placeholder || "Søk i hele nettstedet"}
            aria-label={placeholder || "Søk i hele nettstedet"}
            autoFocus
            queryHook={queryHook} // "onChange".
            onSubmit={(event) => {
              event.preventDefault();
            }}
            resetIconComponent={() => null}
            loadingIconComponent={() => null}
            submitIconComponent={({ classNames }) => (
              <>
                <BigSearch
                  className={classNames.submitIcon}
                  ariaHidden="true"
                />
              </>
            )}
            classNames={{
              root: "algolia-root",
              form: "algolia-form search-input-button-wrapper",
              input: "algolia-search-input search-input",
              submit: "algolia-submit-button hidden-mobile-submit",
              submitIcon: "algolia-submit-button-icon",
              reset: "algolia-reset-button",
              resetIcon: "algolia-reset-button-icon",
              loadingIndicator: "algolia-loading-indicator",
              loadingIcon: "algolia-loading-indicator-icon",
            }}
          />
        </SearchWrapper>
      </div>
    </SearchContainer>
  );
};

export default AlgoliaSearchBlock;

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(0px);
	}
`;

const SearchContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.secondary};
  cursor: pointer;
  &.main-search {
    animation: ${fadeInAnimation} 0.3s ease-in-out;
  }
  .search-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: calc(100% - 117px);
    padding-left: 117px;
    padding-top: 65px;
    padding-bottom: 65px;
    .search-title {
      font-size: 35px;
      line-height: 31px;
      font-family: ${typography.condensedBold};
      color: ${colors.primary};
      letter-spacing: 0.02em;
      margin-bottom: 64px;
    }
    .algolia-reset-button,
    .algolia-loading-indicator,
    .algolia-submit-button {
      display: none;
    }
  }
  .search-input-button-wrapper {
    display: flex;
  }
  @media (max-width: 1024px) {
    padding: 0 25px;
    &.safari {
      max-height: 100%;
      height: 100vh;
    }
    .search-block {
      padding-left: 0;
      form {
        max-width: unset;
      }
    }
  }
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin: 0 25px;
    height: unset;
    /* &.slim-search {
            height: 121px;
        } */
    .search-block {
      width: calc(100% - 50px);
      padding: 45px 25px;
      .search-title {
        font-size: 22px;
        margin-top: 48px;
        margin-bottom: 41px;
      }
      form {
        padding: 11px 0;
        input {
          font-size: 1em;
        }
      }
    }
  }
`;

const CloseButton = styled.button`
  display: inline-flex;
  font-family: ${typography.compressedBold};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.015em;
  align-self: flex-end;
  margin-top: 27px;
  margin-right: 22px;
  cursor: pointer;
  svg {
    margin-left: 13px;
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 25px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  border-bottom: solid 1px ${colors.grey};
  padding: 18px 18px 18px 11px;
  max-width: calc(58.333333333333336% - 144px);
  position: relative;

  @media (max-width: 768px) {
    max-width: unset;
  }

  input,
  .search-input {
    padding-top: 4px;
    padding-bottom: 4px;
    background: red;
    display: flex;
    width: 100%;
    background-color: inherit;
    font-family: ${typography.mono};
    font-weight: bold;
    color: ${colors.black};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    &:placeholder {
      color: ${colors.black};
    }
    //remove webkit cancel button from desktop
    &::-webkit-search-cancel-button {
      display: none;
    }
    &.hidden-mobile-submit {
      position: absolute;
      opacity: 0;
      left: -1200px;
    }
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  button {
    position: absolute;
    right: 9px;

    @media (max-width: 768px) {
      right: 0;
    }
  }
`;
