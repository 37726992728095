import React, { useEffect, useState } from "react";
import { dispatch } from "../../redux";
import { useLocation, useParams } from "react-router-dom";
import { modulesQuery } from "../../const/modulesQuery";
import useRequest from "kb-gql";
import styled from "styled-components";
import Builder from "../../components/modules/Builder";
import { PageTitle, PageIngress } from "../../elements";
import { colors } from "../../const";
import Seo from "../partials/Seo";
import HtmlAttributes from "../partials/HtmlAttributes";
import { seoQuery } from "../../hooks/useSeo";
import ErrorPage from "./ErrorPage";
import { ContentPadding } from "../../components/Wrapper";

const queryFields = `
    title
    pageFields{
        smallHeader
        excerpt
        language
    }
    ${seoQuery}
    ${modulesQuery("Page")}
`;

const makeRequest = (slug) => `
    page(id: "${slug}", idType: URI) {
        ${seoQuery}
        ${queryFields}
    }
`;

const previewQuery = (slug, database_id) => `
    query PreviewQuery{
        page(id: "${slug === "preview" ? database_id : slug}", idType: ${
  /*
   *  check if the slug equals preview, at the time of writing this
   *  any post that is either unsaved or saved as a draft will have the
   *  slug: preview
   */
  slug === "preview" ? "DATABASE_ID" : "URI"
} ) { ${
  /*
   * If the slug does not equals preview we have to query for the "preview"
   * fields in graphql, this is because wp-grapqhl doesn't actually think of it
   * as a preview until it is saved as post and a revision is requested.
   */
  slug !== "preview"
    ? `
        preview{
            node{
                ${queryFields}
            }
        }
        `
    : // If we are querying for a revision we query for the regular fields
      `
            ${queryFields}
        `
}
        }
    }
`;

const assembleRequestSlug = (params) => {
  const { grandparent, parent, slug } = params;
  //check if grandparent or parent is present and assemble the request slug based on it
  return `${grandparent ? grandparent + "/" : ""}${
    parent ? parent + "/" : ""
  }${slug}`;
};

async function getPreviewData(searchParams, slug) {
  const preview = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${searchParams.jwtToken}`,
    },
    body: JSON.stringify({
      query: previewQuery(slug, searchParams.database_id),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  /*
   * Here we do an if check on existing object properties to determine
   * which data to set in the previewData state
   */
  if (preview?.page?.preview?.node) {
    return preview?.page?.preview?.node;
  } else {
    return preview?.page;
  }
}

const Page = () => {
  const params = useParams();
  const { state } = useLocation();
  const searchParams = {
    jwtToken: state?.jwtToken,
    database_id: state?.database_id
  };
  const slug = assembleRequestSlug(params);
  const data = useRequest(makeRequest(slug), `page-${slug}`);
  const [previewData, setPreviewData] = useState(null);
  useEffect(() => {
    dispatch("colorScheme/primary");
    getPreviewData(searchParams, slug).then((data) => setPreviewData(data));
  }, []);
  if (data === null && !previewData) return <ErrorPage />;
  if (!data && !previewData) return null;
  if (data === undefined) return null;

  if (data?.modules?.builder.length === 0 && data?.title === null)
    return <ErrorPage />;
  return (
    <PageWrapper>
      <Seo pageSeo={data?.seo} />
      <HtmlAttributes
        iso639_1LanguageCode={data?.pageFields?.language || "no"}
      />
      <ContentPadding>
        <PageHeader
          className={
            previewData?.pageFields?.smallHeader ||
            data?.pageFields?.smallHeader
              ? "small"
              : ""
          }
        >
          <PageTitle className={data?.pageFields?.smallHeader ? "small" : ""}>
            {previewData?.title || data?.title}
          </PageTitle>
          {(previewData?.pageFields?.excerpt || data?.pageFields?.excerpt) &&
            !data?.pageFields?.smallHeader && (
              <PageIngress>
                {previewData?.pageFields?.excerpt || data?.pageFields?.excerpt}
              </PageIngress>
            )}
        </PageHeader>
      </ContentPadding>
      <Builder
        modules={previewData?.modules?.builder || data.modules?.builder}
        pageType="page"
      />
    </PageWrapper>
  );
};

export default Page;

const PageWrapper = styled.div``;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  &.small {
    border-top: solid 1px ${colors.grey};
    border-bottom: solid 1px ${colors.grey};
  }
  ${PageTitle} {
    margin-bottom: 48px;
    &.small {
      margin-bottom: 0;
      padding: 12px 0;
    }
  }
  ${PageIngress} {
    margin-bottom: 43px;
    max-width: 855px;
    text-align: center;
  }
`;
